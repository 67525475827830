export function fetchLedger() {
	return {
		type: 'FETCH_LEDGER',
		payload: {
			request: {
				url: '/ledger',
			},
		},
	};
}
