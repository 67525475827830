import crudActions from './crudActions';


export const { fetchInvoices, createInvoice, updateInvoice, deleteInvoice } = crudActions('invoice');

export function payInvoice(invoice) {
	return {
		type: 'UPDATE_INVOICE',
		payload: {
			request: {
				url: `/invoices/${invoice.id}`,
				method: 'put',
				data: {
					...invoice,
					paid: true,
				},
			},
		},
	};
}
