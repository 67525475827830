import React from 'react';
import { inject, observer } from 'mobx-react';
import Barcode from 'react-barcode';

import styles from './Price.module.less';
import { PrintPrice } from '../../../../stores/PrintPrices';

type Props = {
	record: typeof PrintPrice.Type,
	full?: boolean,
};

const Price = ({
	record: {
		product: {
			name,
			sku,
			imported: { barcodes },
		},
		price,
		oldPrice,
		discount,
		discountEnd,
		discountReason,
		discountCount,
		type,
	},
	full = false,
}: Props) => {
	const ean = barcodes[0] ? barcodes[0].barcode : null;
	const priceArray = price.toFixed(2).split('.');
	const priceHalfArray = (price / 2).toFixed(2).split('.');
	const priceQuarterArray = (price / 4).toFixed(2).split('.');
	const oldPriceArray = (oldPrice || 0).toFixed(2).split('.');

	const count = Array.from(full ? Array(discountCount < 0 ? 0 : discountCount) : Array(1));

	return (
		<>
			<div className={`${styles.price} ${styles[type]}`}>
				{type === 'discount' && (
					<div>
						<h1>Akcija</h1>
						{discountReason && <div className="discountReason">{discountReason}</div>}
						<h2>{name}</h2>
						{oldPrice && discount && (
							<>
								<div className="left">
									<span
										className="oldAmount"
									>
										{oldPriceArray[0]}
										<sup>{oldPriceArray[1]}</sup>
									</span>
								</div>
								<div className="right">
									<span className="amount">
										{priceArray[0]}
										<sup>{priceArray[1]}</sup>
									</span>
								</div>
							</>
						)}

						{discount && <h3 className="discountText">{discount}</h3>}
						{discountEnd && <div className="discountEnd">Akcija traje do {discountEnd}</div>}
						<Barcode
							value={`DISCOUNT-${sku}-${price||oldPrice}`}
							format="CODE128"
							width={2}
							height={40}
							margin={0}
							marginLeft={10}
							background="transparent"
							fontSize="12"
							displayValue={false}
						/>
					</div>
				)}
				{type !== 'discount' && (
					<>
						<div className="left">
							<div className="name">
								<div>{discount && <strong>{discount}</strong>}</div>
								<div>{name}</div>
							</div>
							<div className={`mask ${!ean ? 'noEan' : ''}`} />
							{type !== 'big' && (
								<div
									className="ean"
									style={
										ean
											? { visibility: 'visible' }
											: { visibility: 'hidden' }
									}
								>
									<span className="label">EAN </span>
									{ean}
								</div>
							)}
							{type !== 'big' && (
								<div className="sku">
									<span className="label">#</span>
									{sku}
								</div>
							)}
						</div>
						<div className="right">
							{type !== 'big' && (
								<span
									className="oldAmount"
									style={
										oldPrice && discount
											? { visibility: 'visible' }
											: {}
									}
								>
									{oldPriceArray[0]}
									<sup>{oldPriceArray[1]}</sup>
								</span>
							)}

							{type === 'big' && (
								<span
									className="oldAmount"
									style={
										oldPrice && discount
											? { display: 'inline-block' }
											: {}
									}
								>
									{oldPriceArray[0]}
									<sup>{oldPriceArray[1]}</sup>
									<span>/kg</span>
								</span>
							)}

							<div className="amount">
								{priceArray[0]}
								<sup>{priceArray[1]}</sup>
								<span>{type === 'big' && '/kg'}</span>
							</div>
							{type === 'big' && (
								<div className="amountBulk">
									<div>
										<span>½ kg:</span> {priceHalfArray[0]}
										<sup>{priceHalfArray[1]}</sup>
									</div>
									<div>
										<span>¼ kg:</span> {priceQuarterArray[0]}
										<sup>{priceQuarterArray[1]}</sup>
									</div>
								</div>
							)}
							{/* <div className="perUnit">
				560.95/kg
			</div> */}
						</div>
						{type === 'big' && (
							<div
								className="ean"
								style={
									ean
										? { visibility: 'visible' }
										: { visibility: 'hidden' }
								}
							>
								<span className="label">EAN </span>
								{ean}
							</div>
						)}
						{type === 'big' && (
							<div className="sku">
								<span className="label">#</span>
								{sku}
							</div>
						)}
					</>
				)}
			
			</div>
			{type === 'discount' && (
				count.map(item => (
					<div className={`${styles.price} ${styles.discountSmall}`}>
						<div>
							<h1>Akcija</h1>
							{discountReason && <div className="discountReason">{discountReason}</div>}
							<h2>{name}</h2>
							{oldPrice && discount && (
								<>
									<div className="left">
										<span
											className="oldAmount"
										>
											{oldPriceArray[0]}
											<sup>{oldPriceArray[1]}</sup>
										</span>
									</div>
									<div className="right">
										<span className="amount">
											{priceArray[0]}
											<sup>{priceArray[1]}</sup>
										</span>
									</div>
								</>
							)}

							{discount && <h3 className="discountText">{discount}</h3>}
							{discountEnd && <div className="discountEnd">Akcija traje do {discountEnd}</div>}
							<Barcode
								value={`DISCOUNT-${sku}-${price||oldPrice}`}
								format="CODE128"
								width={1}
								height={20}
								margin={0}
								marginLeft={10}
								background="transparent"
								fontSize="12"
								displayValue={false}
							/>
						</div>
					</div>
				))
			)}
		</>
	);
};
Price.defaultProps = {
	oldPrice: null,
	discount: null,
};

export default inject((stores, props: Props) => ({
	...props.record,
}))(observer(Price));
