import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SocketIo from 'socket.io-client';
import trim from 'lodash/trim';
import { PrinterOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import print from 'print-html-element';
import { findDOMNode } from 'react-dom';

import {
	Button,
	Space,
	Table,
	message,
	notification,
} from 'antd';
import shortid from 'shortid';
import { array } from 'mobx-state-tree/dist/internal';
import PrintModal from './PrintModal';
import config from '../../../../config.json';
import styles from './Price.module.less';

import SearchDrawer from '../../POS/Search';
import QuantityInput from './QuantityInput';
import Input from './Input';
import Select from './Select';
import Price from './Price';
import Prices from './Prices';
import { ProductStore } from '../../../../stores/Product';

type Props = {
	printPrices: [any],
	addProduct: Function,
	removeAllProducts: Function,
	productStore: typeof ProductStore.Type
};

type State = {
	searchVisible: boolean,
	socketError: any,
	printModalVisible: boolean,
	skipped: number, 
	size: string,
};

const margins = {
	regular: '4.86mm 0 0 0',
	small:'13.3mm 0 0 8mm',
	big: '8.5mm 0 0 0',
	discount: '8.5mm 0 0 8.5mm',
};

const quantityPerType = {
	regular: 51,
	small: 64,
	big: 8,
};

// eslint-disable-next-line react/prefer-stateless-function
@inject(({ store: { printPricesStore, productStore } }) => ({
	products: productStore.products,
	productStore,
	addProduct: printPricesStore.addProduct,
	removeAllProducts: printPricesStore.removeAllProducts,
	printPrices: printPricesStore.items.toJS(),
}))
export default class PrintPrices extends Component<Props, State> {
	state = {
		searchVisible: false,
		socketError: null,
		printModalVisible: false,
		skipped: 0,
		size: 'regular',
	};

	printRef = null;

	socketError: Function | null = null;

	socket: SocketIo;

	componentWillUnmount() {
		this.socket.off('ean', this.onEan);
		this.socket.close();
	}

	UNSAFE_componentWillMount = () => {
		message.config({
			top: 10,
		});
		this.socket = new SocketIo(config.ecrservice);

		this.socket.on('connect', socket => {
			if (this.state.socketError) {
				this.state.socketError();
				this.setState({
					socketError: null,
				});
				message.success('Pozadinski servis je ponovo dostupan ', 5);
			}
		});

		this.socket.on('error', () => {
			if (!this.state.socketError) {
				this.setState({
					socketError: message.warning('Upozorenje: Pozadinski servis nije dostupan, pozovite podršku! ', 0),
				});
			}
		});
		this.socket.on('connect_error', () => {
			if (!this.state.socketError) {
				this.setState({
					socketError: message.warning('Upozorenje: Pozadinski servis nije dostupan, pozovite podršku! ', 0),
				});
			}
		});

		this.socket.on('ean', this.onEan);
	};

	onEan = (emittedEan: string) => {
		const ean: string = trim(`${emittedEan}`);

		const product = this.props.productStore.productsByEan[ean] ? this.props.productStore.productsByEan[ean][0] : null;
		if (product) {
			return this.props.addProduct(product, 1);
		}

		return notification.error({
			key: 'eanError',
			message: 'Proizvod nije pronađen',
			description: `Proizvod sa bar kodom ${ean} nije pronađen. Pokušajte drugi metod unosa.`,
			duration: 10,
		});
	};

	onPrint = (size, skipped) => {
		this.setState((prevState) => ({
			...prevState,
			size,
			skipped,
			printModalVisible: false,
		}), () => {
			print.printElement(findDOMNode(this.printRef));
		});

	};

	renderHeader = () => (
		<Space>
			<Button
				onClick={() => {
					this.props.removeAllProducts();
				}}
				icon={<DeleteOutlined />}
			>
				Ukloni sve proizvode
			</Button>
			<Button
				onClick={() => {
					this.setState({
						searchVisible: true,
					});
				}}
				icon={<PlusOutlined />}
			>
				Dodaj proizvod
			</Button>
			<Button
				onClick={() => {
					this.setState({printModalVisible: true});
				}}
				icon={<PrinterOutlined />}
			>
				Štampaj
			</Button>
		</Space>
	);


	render() {
		const skipArray = Array.from(Array(this.state.skipped < 0 ? 0 : this.state.skipped));
		const { printPrices } = this.props;
		const { searchVisible } = this.state;

		const printPricesFinal = [
			...(this.state.skipped < quantityPerType[this.state.size]/2 ? skipArray : []),
			...printPrices.filter(price => price.type === this.state.size)
				.reduce((prev, curr) => {
					return [
						...prev,
						...Array(curr.quantity).fill({...curr, quantity: 1}),
					];
				}, []),
		];
		console.log('before',printPricesFinal);


		if (this.state.skipped >= quantityPerType[this.state.size]/2) {
			printPricesFinal.splice(quantityPerType[this.state.size] - this.state.skipped, 0, ...skipArray);
		}


		console.log(printPricesFinal);

		return (
			<>
				<Table
					bordered
					dataSource={printPrices}
					rowKey={record => record.product.id}
					columns={[
						{
							key: 'name',
							dataIndex: ['product', 'imported', 'name'],
							title: 'Naziv',
						},
						{
							key: 'quantity',
							dataIndex: ['quantity'],
							title: 'Količina',
							render: (text, record) => (
								<QuantityInput
									field="quantity"
									onChange={(value: any) => {
										record.updateQuantity(value);
									}}
									record={record}
								/>
							),
						},
						{
							key: 'price',
							dataIndex: ['product', 'imported', 'price'],
							title: 'Cena',
							render: (text, record) => (
								<QuantityInput
									field="price"
									onChange={(value: any) => {
										record.updatePrice(value);
									}}
									record={record}
								/>
							),
						},
						{
							key: 'oldPrice',
							dataIndex: ['product', 'imported', 'oldPrice'],
							title: 'Stara Cena',
							render: (text, record) => (
								<QuantityInput
									field="oldPrice"
									onChange={(value: any) => {
										record.updateOldPrice(value);
									}}
									record={record}
								/>
							),
						},
						{
							key: 'discount',
							dataIndex: ['product', 'imported', 'discount'],
							title: 'Akcija',
							render: (text, record) => (
								<>
									<div>Akcija</div>
									<Input
										field="discount"
										onChange={({ target: { value } }) => {
											record.updateDiscount(value);
										}}
										record={record}
									/>
									{record.type === 'discount' && (
										<>
											<div>Razlog akcije</div>
											<Input
												field="discountReason"
												onChange={({ target: { value } }) => {
													record.updateDiscountReason(value);
												}}
												record={record}
											/>
											<div>Završetak akcije</div>
											<Input
												field="discountEnd"
												onChange={({ target: { value } }) => {
													record.updateDiscountEnd(value);
												}}
												record={record}
											/>
											<div>Broj malih cena</div>
											<QuantityInput
												field="discountCount"
												onChange={( value: number ) => {
													record.updateDiscountCount(value);
												}}
												record={record}
											/>
										</>
									)}
									
								</>
							),
						},
						{
							key: 'type',
							dataIndex: ['type'],
							title: 'Tip cene',
							render: (text, record) => (
								<Select
									field="type"
									onChange={value => {
										record.updateType(value);
									}}
									options={[
										{
											label: 'Regularna',
											value: 'regular',
										},
										{
											label: 'Mala',
											value: 'small',
										},
										{
											label: 'Velika',
											value: 'big',
										},
										{
											label: 'Akcijska',
											value: 'discount',
										},
									]}
									record={record}
								/>
							),
						},
						{
							key: 'preview',
							title: 'Pregled',
							render: (text, record) => (
								<Price
									record={record}
								/>
							),
						},
						{
							key: 'actions',
							title: '',
							render: (text, record) => (
								<Button
									onClick={() => {
										record.removeProduct();
									}}
									size="small"
									icon={<DeleteOutlined />}
								>Obriši
								</Button>
							),
						},

					]}
					pagination={false}
				/>
				<div className={styles.print} ref={p => this.printRef = p}>
					<style>
						{`@page {
							margin: ${margins[this.state.size]};
							size: A4;

						}`}
					</style>

					{printPricesFinal.map((record, i) => {
						return !record ? <div className={`${styles.price} ${styles[this.state.size]}`} />: <Prices record={record} key={`p${i}`} />; 
					})}
				</div>
				<SearchDrawer
					onRowClick={product => {
						const { addProduct } = this.props;
						addProduct(product, 1);
					}}
					closeSearch={() => {
						this.setState({
							searchVisible: false,
						});
					}}
					visible={searchVisible}
				/>
				<PrintModal 
					visible={this.state.printModalVisible} 
					onClose={() => {}} 
					prices={printPrices} 
					onCancel={() => this.setState({printModalVisible: false})} 
					onSubmit={this.onPrint}
				/>
			</>
		);
	}
}
