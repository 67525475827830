import { createAction } from 'redux-actions';
import axios from 'axios';

import {
	FETCH_DISTRIBUTORS_ATTEMPT,
	FETCH_DISTRIBUTORS_FAILED,
	FETCH_DISTRIBUTORS_SUCCESS,
	CREATE_DISTRIBUTORS_ATTEMPT,
	CREATE_DISTRIBUTORS_FAILED,
	CREATE_DISTRIBUTORS_SUCCESS,
	UPDATE_DISTRIBUTORS_ATTEMPT,
	UPDATE_DISTRIBUTORS_FAILED,
	UPDATE_DISTRIBUTORS_SUCCESS,
	DELETE_DISTRIBUTORS_ATTEMPT,
	DELETE_DISTRIBUTORS_FAILED,
	DELETE_DISTRIBUTORS_SUCCESS,
} from './types';

const fetchDistributorsAttempt = createAction(FETCH_DISTRIBUTORS_ATTEMPT);
const fetchDistributorsFailed = createAction(FETCH_DISTRIBUTORS_FAILED);
const fetchDistributorsSuccess = createAction(FETCH_DISTRIBUTORS_SUCCESS);

const createDistributorAttempt = createAction(CREATE_DISTRIBUTORS_ATTEMPT);
const createDistributorFailed = createAction(CREATE_DISTRIBUTORS_FAILED);
const createDistributorSuccess = createAction(CREATE_DISTRIBUTORS_SUCCESS);

const updateDistributorAttempt = createAction(UPDATE_DISTRIBUTORS_ATTEMPT);
const updateDistributorFailed = createAction(UPDATE_DISTRIBUTORS_FAILED);
const updateDistributorSuccess = createAction(UPDATE_DISTRIBUTORS_SUCCESS);

const deleteDistributorAttempt = createAction(DELETE_DISTRIBUTORS_ATTEMPT);
const deleteDistributorFailed = createAction(DELETE_DISTRIBUTORS_FAILED);
const deleteDistributorSuccess = createAction(DELETE_DISTRIBUTORS_SUCCESS);

export function fetchDistributors() {
	return async (dispatch) => {
		dispatch(fetchDistributorsAttempt());
		try {
			const distributors = await axios.get('/distributors');
			dispatch(fetchDistributorsSuccess(distributors.data));
		} catch (error) {
			dispatch(fetchDistributorsFailed(error.response.data));
		}
	};
}

export function createDistributor(data) {
	return async (dispatch) => {
		dispatch(createDistributorAttempt());
		try {
			const distributors = await axios.post('/distributors', data);
			dispatch(createDistributorSuccess(distributors.data));
		} catch (error) {
			dispatch(createDistributorFailed(error.response.data));
		}
	};
}

export function updateDistributor(data) {
	return async (dispatch) => {
		dispatch(updateDistributorAttempt());
		try {
			const distributors = await axios.put(`/distributors/${data.id}`, data);
			dispatch(updateDistributorSuccess(distributors.data));
		} catch (error) {
			dispatch(updateDistributorFailed(error.response.data));
		}
	};
}


export function deleteDistributor(id) {
	return async (dispatch) => {
		dispatch(deleteDistributorAttempt());
		try {
			await axios.delete(`/distributors/${id}`);
			dispatch(deleteDistributorSuccess(id));
		} catch (error) {
			dispatch(deleteDistributorFailed(error.response.data));
		}
	};
}
