import React from 'react';
import { inject, observer, Provider as MobXProvider } from 'mobx-react';
import {
	BrowserRouter,
	Route,
} from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';
import makeInspectable from 'mobx-devtools-mst';

// import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import { Provider } from 'react-redux';

import axios from 'axios';
import { ConfigProvider } from 'antd';

import moment from 'moment';

import srRS from 'antd/lib/locale-provider/sr_RS';
import 'moment/locale/sr';
import { hot } from 'react-hot-loader';

// import configureStore from './store';

import Login from './modules/Login';
import Application from './modules/Application';
import withAuth from './hocs/withAuth';

import { returnToApp } from './actions/auth';

import mstStore from './stores';

import config from './config.json';


import './App.css';


// Create a history of your choosing (we're using a browser history in this case)

// Build the middleware for intercepting and dispatching navigation actions
// const middleware = routerMiddleware(history);

// const { store, persistor } = configureStore({}, history, middleware);

axios.defaults.baseURL = config.api;

moment.locale('sr');

// store.dispatch(returnToApp());
// makeInspectable(store);

// window.addEventListener('beforeunload', ev => {
// 	ev.preventDefault();
// 	return ev.returnValue = 'Da li ste sigurni da želite da zatvorite aplikaciju?';
// });

function App(props) {
	const { initialized } = props;
	return (
		<ConfigProvider locale={srRS}>
			<MobXProvider
				store={mstStore}
			>
				{/* <Provider store={store}> */}
				{/* <PersistGate loading={null} persistor={persistor}> */}

				{initialized && (
					<BrowserRouter>
						<>
							<Route exact path="/" component={withAuth(Login)} />
							<Route path="/app" component={withAuth(Application, true)} />
						</>
					</BrowserRouter>
				)}
				{/* </PersistGate> */}
				{/* </Provider> */}
			</MobXProvider>
		</ConfigProvider>
	);
}

export default hot(module)(
	inject(stores => ({
		initialized: mstStore.applicationStore.initialized,
	}))(
		observer(
			App
		)
	)
);

(() => {

})();
