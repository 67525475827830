import {Modal, Radio,Form } from 'antd';
import React, { useCallback, useState } from 'react';
import { getPluralFunc } from 'plural-forms';
import StickerChooser from './StickerChooser';

type Props = {
	visible: boolean;
	prices: any;
	onCancel: () => void
	onSubmit: (type: string, skip: number) => void
};

const options = [
	{ label: 'Regularne', value: 'regular' },
	{ label: 'Male', value: 'small' },
	{ label: 'Velike', value: 'big' },
	{ label: 'Akcijske', value: 'discount' },
];

const quantityPerType = {
	regular: 51,
	small: 64,
	big: 8,
};

const pluralFunc = getPluralFunc('sr') as any;

export default function PrintModal({
	visible,
	prices,
	onSubmit,
	onCancel,
}:Props): JSX.Element {
	const [currentSize, setCurrentSize] = useState<'regular'|'small'|'big'|'discount'>('regular');
	const [skipped, setSkipped] = useState<number>(0);
    
	const [form] = Form.useForm();

	const handleSizeChange = useCallback(
		(e) => {
			const {value} = e.target;
			setCurrentSize(value);
		},
		[],
	);

	const quantity = quantityPerType[currentSize];

	const handleFormChange = useCallback(() => {
		const fields = form.getFieldsValue();
		setSkipped(fields.skip);
	}, []);

	const handleSubmit = useCallback(() => {
		onSubmit(currentSize, skipped);
	}, [currentSize, skipped]);

	const filteredPrices = prices.filter(price => price.type === currentSize);
	const pricesAmount = filteredPrices.reduce((acc, price) => acc + price.quantity, 0);
	const paperAmount = Math.ceil((pricesAmount + skipped )/ quantity);

	return (
		<Modal
			width={600}
			title="Štampa cena"
			visible={visible}
			onCancel={onCancel}
			onOk={handleSubmit}
		>
			<Form
				labelCol={{span: 6}}
				wrapperCol={{span: 14}}
				form={form}
				layout="horizontal"
				onFieldsChange={handleFormChange}
				initialValues={{
					size: 'regular',
					skip: 0,
				}}
			>
				<Form.Item label="Tip cene" name="size">
					<Radio.Group options={options} optionType="button" buttonStyle="solid" onChange={handleSizeChange} />
				</Form.Item>
				<Form.Item label="Početak štampe" extra="Odaberite prvu neiskorišćenu nalepnicu" name="skip">
					<StickerChooser type={currentSize} quantity={quantity} />
				</Form.Item>
				<Form.Item label="Uputstvo za štampu">
					<p>
						Ubacite <b>{paperAmount}</b> {pluralFunc(paperAmount, ['list', 'lista', 'listova'])} sa nalepnicama u fioku štampača, tako da delimično iskorišćen list bude na vrhu, da strana sa nalepnicama bude okrenuta dole, a iskorišćen deo papira <b>{skipped < quantity/2 ? 'ka vama':'suprotno od vas'}</b>.
					</p>
				</Form.Item>
			</Form>

		</Modal>
	);
}