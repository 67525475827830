const locale = 'sr-RS';

export default function numberFormat(value) {
	return Number(value).toLocaleString(
		locale,
		{
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}
	);
}
