// @flow
import {
	types, flow, destroy, getParent, clone,
} from 'mobx-state-tree';

import localforage from 'localforage';
import { defaultClient } from '../store/client';

import { Product } from './Product';

export const ApplicationStore = types
	.model('ApplicationStore', {
		initialized: types.optional(types.boolean, false),
	})
	.actions(self => {
		const actions = {
			setInitialized: initialized => {
				self.initialized = initialized;
			},
			afterAuth: () => {
				console.log('init');
				actions.setInitialized(true);
			},
		};

		return actions;
	});
