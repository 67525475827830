import React, { Component } from 'react';
import numeral from 'numeral';
import { inject, observer } from 'mobx-react';
import {
	Calendar, Badge, Spin, Divider, Tooltip, Row, Col, Card, Timeline,
} from 'antd';

import moment, { Moment } from 'moment';
import { SaleDay } from '../../../../stores/SaleDay';

import SalesDrawer from './SalesDrawer';
import Controls from './Components/Controls/Controls';

import styles from './Sales.module.less';

type Props = {
	fetchSaleDays: Function,
	saleDays: Map<string, typeof SaleDay.Type>,
	loading: boolean,
};

type State = {
	currentDate: Moment,
	drawer: {
		visible: boolean,
		saleDay: typeof SaleDay.Type,
	}
};

class Sales extends Component<Props, State> {
	state = {
		currentDate: moment(),
		drawer: {
			visible: false,
			saleDay: null,
		},
	};

	componentDidMount() {
		const { fetchSaleDays } = this.props;
		fetchSaleDays();
	}

	handleSaleDaySelect = (date: Moment) => {
		const { saleDays } = this.props;

		const key = date.clone().startOf('day').toISOString();

		if (!saleDays[key]) {
			return false;
		}

		return this.setState({
			drawer: {
				visible: true,
				saleDay: saleDays[key],
			},
		});
	};

	handleSaleDrawerClose = () => {
		this.setState({
			drawer: {
				visible: false,
				saleDay: null,
			},
		});
	};

	dateCellRender = (date: Moment) => {
		const { saleDays } = this.props;

		const key = date.clone().startOf('day').toISOString();
		return saleDays[key]
			? (
				<Tooltip
					title={(
						<span>
							<strong>Gotovina: </strong>{numeral(saleDays[key].paidCash).format('0,0.00')}<br />
							<strong>Čekovi: </strong>{numeral(saleDays[key].paidCheck).format('0,0.00')}<br />
							<strong>Kartice: </strong>{numeral(saleDays[key].paidCard).format('0,0.00')}
						</span>
					)}
				>
					<Badge color="green" text={numeral(saleDays[key].paidTotal).format('0,0.00')} />
				</Tooltip>
			)
			: <Badge status="default" text="Nema prodaja" />;
	};

	dateRowRender = (date: Moment) => {
		const { saleDays } = this.props;

		const key = date.clone().startOf('day').toISOString();
		return (
			<Timeline.Item
				color={saleDays[key] ? 'green' : 'grey'}
				onClick={() => this.handleSaleDaySelect(date)}
				key={key}
				label={date.format('dddd, LL')}
			>
				{saleDays[key]
					? (

						<Tooltip
							title={(
								<span>
									<strong>Gotovina: </strong>{numeral(saleDays[key].paidCash).format('0,0.00')}<br />
									<strong>Čekovi: </strong>{numeral(saleDays[key].paidCheck).format('0,0.00')}<br />
									<strong>Kartice: </strong>{numeral(saleDays[key].paidCard).format('0,0.00')}
								</span>
							)}
						>
							{numeral(saleDays[key].paidTotal).format('0,0.00')}
						</Tooltip>
					)
					: 'Nema prodaja'
				}
			</Timeline.Item>
		);
	};

	handleDateChange = (date: Moment) => {
		const { fetchSaleDays } = this.props;

		this.setState({ currentDate: date });
		fetchSaleDays(date.clone().startOf('month').startOf('week'));
	};

	renderHeader = () => (
		<Controls
			onDateChange={this.handleDateChange}
		/>
	);


	render() {
		const { loading } = this.props;
		const { drawer: salesDrawerProps, currentDate } = this.state;

		const monthStart = moment(currentDate).startOf('month');
		const days = [];
		const day = monthStart.clone();

		while (day.month() === monthStart.month()) {
			days.push(day.clone());
			day.add(1, 'day');
		}

		return (
			<Spin
				spinning={loading}
			>
				<Row>
					<Col
						xs={{ span: 0 }}
						sm={{ span: 0 }}
						md={{ span: 0 }}
						lg={{ span: 24 }}

					>
						<div
							className={styles.container}
						>
							<Calendar

								className={styles.calendar}
								dateCellRender={this.dateCellRender}
								onSelect={this.handleSaleDaySelect}
								value={currentDate}
							/>
							<SalesDrawer
								{...salesDrawerProps}
								onClose={this.handleSaleDrawerClose}
							/>
						</div>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 0 }}>
						<Timeline
							mode="left"

						>
							{days.map(day => this.dateRowRender(day))}
						</Timeline>
					</Col>
				</Row>
			</Spin>
		);
	}
}
export default inject(
	({ store: { saleDayStore } }) => ({
		fetchSaleDays: saleDayStore.fetchSaleDays,
		saleDays: saleDayStore.byDate,
		loading: saleDayStore.loading,
	})
)(
	observer(Sales)
);
