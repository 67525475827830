// Auth
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

// Distributors
export const FETCH_DISTRIBUTORS_ATTEMPT = 'FETCH_DISTRIBUTORS_ATTEMPT';
export const FETCH_DISTRIBUTORS_SUCCESS = 'FETCH_DISTRIBUTORS_SUCCESS';
export const FETCH_DISTRIBUTORS_FAILED = 'FETCH_DISTRIBUTORS_FAILED';

export const CREATE_DISTRIBUTORS_ATTEMPT = 'CREATE_DISTRIBUTORS_ATTEMPT';
export const CREATE_DISTRIBUTORS_SUCCESS = 'CREATE_DISTRIBUTORS_SUCCESS';
export const CREATE_DISTRIBUTORS_FAILED = 'CREATE_DISTRIBUTORS_FAILED';

export const UPDATE_DISTRIBUTORS_ATTEMPT = 'UPDATE_DISTRIBUTORS_ATTEMPT';
export const UPDATE_DISTRIBUTORS_SUCCESS = 'UPDATE_DISTRIBUTORS_SUCCESS';
export const UPDATE_DISTRIBUTORS_FAILED = 'UPDATE_DISTRIBUTORS_FAILED';

export const DELETE_DISTRIBUTORS_ATTEMPT = 'DELETE_DISTRIBUTORS_ATTEMPT';
export const DELETE_DISTRIBUTORS_SUCCESS = 'DELETE_DISTRIBUTORS_SUCCESS';
export const DELETE_DISTRIBUTORS_FAILED = 'DELETE_DISTRIBUTORS_FAILED';

// Stores
export const FETCH_STORES_ATTEMPT = 'FETCH_STORES_ATTEMPT';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAILED = 'FETCH_STORES_FAILED';

export const CREATE_STORES_ATTEMPT = 'CREATE_STORES_ATTEMPT';
export const CREATE_STORES_SUCCESS = 'CREATE_STORES_SUCCESS';
export const CREATE_STORES_FAILED = 'CREATE_STORES_FAILED';

export const UPDATE_STORES_ATTEMPT = 'UPDATE_STORES_ATTEMPT';
export const UPDATE_STORES_SUCCESS = 'UPDATE_STORES_SUCCESS';
export const UPDATE_STORES_FAILED = 'UPDATE_STORES_FAILED';

export const DELETE_STORES_ATTEMPT = 'DELETE_STORES_ATTEMPT';
export const DELETE_STORES_SUCCESS = 'DELETE_STORES_SUCCESS';
export const DELETE_STORES_FAILED = 'DELETE_STORES_FAILED';


export const FETCH_BRANDS_ATTEMPT = 'FETCH_BRANDS_ATTEMPT';
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
export const FETCH_BRANDS_FAILED = 'FETCH_BRANDS_FAILED';

export const CREATE_BRANDS_ATTEMPT = 'CREATE_BRANDS_ATTEMPT';
export const CREATE_BRANDS_SUCCESS = 'CREATE_BRANDS_SUCCESS';
export const CREATE_BRANDS_FAILED = 'CREATE_BRANDS_FAILED';

export const UPDATE_BRANDS_ATTEMPT = 'UPDATE_BRANDS_ATTEMPT';
export const UPDATE_BRANDS_SUCCESS = 'UPDATE_BRANDS_SUCCESS';
export const UPDATE_BRANDS_FAILED = 'UPDATE_BRANDS_FAILED';

export const DELETE_BRANDS_ATTEMPT = 'DELETE_BRANDS_ATTEMPT';
export const DELETE_BRANDS_SUCCESS = 'DELETE_BRANDS_SUCCESS';
export const DELETE_BRANDS_FAILED = 'DELETE_BRANDS_FAILED';
