import crudActions from './crudActions';


export const {
	fetchSales, createSale, updateSale, deleteSale, createSaleItem, updateSaleItem,
} = crudActions('sale');

export function processSale(sale) {
	return {
		type: 'SALE_PROCESS',
		payload: {
			request: {
				url: `/sales/${sale.id}/process`,
				method: 'put',
			},
		},
	};
}

// export function createSaleItem(saleItem) {
// 	return {
// 		type: 'SALE_CREATE_ITEM',
// 		payload: {
// 			request: {
// 				url: `/sales/${saleItem.saleId}/items`,
// 				method: 'post',
// 				data: saleItem,
// 			},
// 		},
// 	};
// }
