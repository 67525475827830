import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
	BookOutlined,
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	PlusOutlined,
	PrinterOutlined,
} from '@ant-design/icons';
import {
	Input, Button, Table, Select, DatePicker, Spin, Popconfirm, Row, Col,
} from 'antd';
import print from 'print-html-element';
import nl2br from 'react-newline-to-break';

import moment from 'moment';

import {
	fetchPricechanges, createPricechange, deletePricechange, updatePricechange, createItem, updateItem, deleteItem, processPricechange,
} from '../../../../actions/pricechanges';
import { fetchStores } from '../../../../actions/stores';
import { fetchDistributors } from '../../../../actions/distributors';
import { fetchInvoices } from '../../../../actions/invoices';
import CrudPage from '../../../../components/CrudPage';
import CreatePricechangeItemModal from './CreatePricechangeItemModal';

import numberFormat from '../../../../lib/numberFormat';

class Pricechanges extends Component {
	static propTypes = {
		fetchInvoices: PropTypes.func.isRequired,
		updateItem: PropTypes.func.isRequired,
		createItem: PropTypes.func.isRequired,
		deleteItem: PropTypes.func.isRequired,
		fetchStores: PropTypes.func.isRequired,
		processPricechange: PropTypes.func.isRequired,
		isLoading: PropTypes.bool.isRequired,
		isLoadingDistributors: PropTypes.bool.isRequired,
		isLoadingStores: PropTypes.bool.isRequired,
		isLoadingInvoices: PropTypes.bool.isRequired,
		stores: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		invoices: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		distributors: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
	};

	state = {
		createVisible: false,
		pricechangeItem: null,
	};

	calcRefs = {};

	componentDidMount() {
		this.props.fetchInvoices();
		this.props.fetchStores();
		this.props.fetchDistributors();
	}

	editRender(property, editComponent, render) {
		return (text, record) => (render ? render(text, record) : text)
		// }

		// return cloneElement(editComponent, {
		// 	onChange: (event) => {
		// 		const newRecord = {
		// 			...record,
		// 		};
		// 		if (event.currentTarget) {
		// 			return this.props.updateItem(_.set(newRecord, property, event.currentTarget.value));
		// 		}

		// 		return this.props.updateItem(_.set(newRecord, property, event));
		// 	},
		// 	defaultValue: text,
		// });
		;
	}

	expandedRowRender(data) {
		let totalOldValue = 0;
		let totalNewValue = 0;

		data.pricechangeItems.forEach(item => {
			totalOldValue += (item.oldPrice * item.quantity);
			totalNewValue += (item.price * item.quantity);
		});
		const totalDiff = totalNewValue - totalOldValue;

		return (
			<div
				className="bo-pricechange"
				ref={el => { this.calcRefs[data.id] = el; }}
			>
				<div className="bo-pricechange-info-print">
					<Row gutter={8}>
						<Col span="8">
							<strong>Pet Shop Moja Mačkica</strong><br />
							Toše Jovanovića 7/4, Beograd<br />
							PIB: 110228902<br />
							Prodavnica: {data.store.name} ({data.store.address})
						</Col>
						<Col span="8" />
						<Col span="8">
							<strong>Nivelacija:</strong> {data.number} / {moment(data.date).format('L')}<br />

						</Col>
					</Row>
					<h2>Nivelacija broj {data.number} od {moment(data.date).format('L')}</h2>
				</div>
				<Table

					footer={() => [
						<Button
							onClick={() => this.setState({ createVisible: true, pricechange: data })}
							icon={<PlusOutlined />}
							style={{ marginRight: 8 }}
							key="addButton"
						>
								Dodaj novu stavku
						</Button>,
						<Button
							icon={<PrinterOutlined />}
							onClick={() => {
								print.printElement(findDOMNode(this.calcRefs[data.id]));
							}}
							key="printButton"
						>
								Štampaj
						</Button>,
					]
					}
					pagination={false}
					size="middle"
					bordered
					rowKey={record => record.id || record.tmpId}
					dataSource={data.pricechangeItems}
					columns={[
						{
							title: '#',
							key: 'index',
							width: '80px',
							render(text, record, index) {
								return index + 1;
							},
						},
						{
							title: 'Šifra',
							key: 'product.sku',
							dataIndex: ['product', 'sku'],
							width: '70px',

						},
						{
							title: 'Naziv',
							key: 'product.name',
							dataIndex: ['product', 'name'],
						},
						{
							title: 'Stara cena',
							key: 'oldPrice',
							dataIndex: 'oldPrice',
							width: '100px',
							render: text => numberFormat(text),
						},
						{
							title: 'Nova cena',
							key: 'price',
							dataIndex: 'price',
							width: '100px',
							render: text => numberFormat(text),
						},
						{
							title: 'Količina',
							key: 'quantity',
							dataIndex: 'quantity',
							width: '70px',
						},
						{
							title: 'Stara vrednost',
							key: 'oldValue',
							width: '140px',
							render: (text, record) => numberFormat(record.oldPrice * record.quantity),
						},
						{
							title: 'Nova vrednost',
							key: 'value',
							width: '140px',
							render: (text, record) => numberFormat(record.price * record.quantity),
						},
						{
							title: 'Razlika',
							key: 'priceDifference',
							width: '120px',
							render: (text, record) => numberFormat((record.oldPrice * record.quantity) - (record.price * record.quantity)),
						},
						{
							title: 'Akcije',
							key: 'actions',
							className: 'bo-column-actions',
							width: '140px',
							render: (text, record) => (
								<Button.Group>
									<Button
										onClick={() => {
											this.setState({
												pricechangeItem: record,
												createVisible: true,
												pricechange: data,
											});
										}}
									>
									Izmeni
									</Button>
									<Popconfirm
										placement="topRight"
										title="Da li ste sigurni da želite da obrišete ovu stavku?"
										onConfirm={() => {
											this.props.deleteItem(record);
										}}
										okText="Da"
										cancelText="Ne"
									>
										<Button icon={<DeleteOutlined />} />
									</Popconfirm>
								</Button.Group>
							),
						},

					]}
				/>
				<Row
					className="bo-pricechange-summary-container"
					gutter={8}
					style={{ marginBottom: -1 }}
				>
					<Col
						className="bo-pricechange-summary-col1"
						span="6"
					>
						<Table
							className="bo-pricechange-summary"
							rowClassName={record => record.className || ''}
							pagination={false}
							dataSource={[
								{
									title: 'Ukupna stara vrednost',
									amount: numberFormat(totalOldValue),
									key: 'totalOldValue',
								},
								{
									title: 'Ukupna nova vrednost',
									amount: numberFormat(totalNewValue),
									key: 'totalNewValue',
								},
								{
									title: 'Ukupna razlika',
									amount: numberFormat(totalDiff),
									key: 'totalDiff',
								},
							]}
							bordered
							size="middle"
							showHeader={false}
							columns={[
								{
									dataIndex: 'title',
								},
								{
									dataIndex: 'amount',
								},
							]}
						/>
					</Col>
					<Col
						className="bo-pricechange-summary-col2"
						span="18"
					/>
				</Row>
				<div className="bo-pricechange-signature-print">
					Kalkulisao:<br /><br />
					_______________________
				</div>
			</div>
		);
	}

	render() {
		if (this.props.isLoading) {
			return <Spin />;
		}

		return (
			<CrudPage
				tableProps={{
					expandedRowRender: data => this.expandedRowRender(data),
					className: 'bo-nested-table',
				}}
				fetchAction={fetchPricechanges}
				createAction={createPricechange}
				deleteAction={deletePricechange}
				updateAction={updatePricechange}
				useMap
				createTitle="Dodaj Nivelaciju"
				editTitle="Izmeni Nivelaciju"
				addButtonText="Dodaj Nivelaciju"
				deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovu nivelaciju?"
				stateProperty="pricechanges"
				columns={[
					{
						title: 'Broj nivelacije',
						dataIndex: 'number',
						key: 'number',
						sorter: (a, b) => {
							if (a.number < b.number) return -1;
							if (a.number > b.number) return 1;
							return 0;
						},
					},
					{
						title: 'Prodavnica',
						dataIndex: ['store', 'name'],
						key: 'store.name',
						sorter: (a, b) => {
							if (a.store.name < b.store.name) return -1;
							if (a.store.name > b.store.name) return 1;
							return 0;
						},
						filters: this.props.stores.map(store => ({
							text: store.name,
							value: store.id,
						})),
						filterMultiple: true,
						onFilter: (value, record) => `${record.storeId}` === value,
					},
					{
						title: 'Datum nivelacije',
						dataIndex: 'date',
						key: 'date',
						render: text => moment(text).format('L'),
					},
					{
						title: 'Proknjižena',
						dataIndex: 'processed',
						render: value => <span className={value ? 'bo-paid' : 'bo-not-paid'}>{value ? <CheckOutlined /> : <CloseOutlined />}</span>,
					},
				]}
				convert={{
					date: moment,
					storeId: storeId => `${storeId}`,
				}}
				extraButtons={(text, record) => [
					<Button
						key="process"
						onClick={() => {
							this.props.processPricechange(record);
						}}
						icon={<BookOutlined />}
					/>,
				]}
				properties={[
					{
						label: 'Broj nivelacije',
						property: 'number',
						rules: [],
						component: <Input />,
					},
					{
						label: 'Prodavnica',
						property: 'storeId',
						rules: [{
							required: true, message: 'Prodavnica je obavezna',
						}],
						component: <Select
							showSearch
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						>
							{this.props.isLoadingStores ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
							{this.props.stores.map(
								store => <Select.Option key={store.id} value={`${store.id}`}>{store.name}</Select.Option>
							)
							}
						</Select>,
					},
					{
						label: 'Datum nivelacije',
						property: 'date',
						rules: [{
							required: true, message: 'Datum nivelacije je obavezan',
						}],
						component: <DatePicker />,
					},
				]}
			>
				<CreatePricechangeItemModal
					visible={this.state.createVisible}
					pricechange={this.state.pricechange}
					entity={this.state.pricechangeItem}
					handleSave={() => {
						this.setState({ createVisible: false, pricechangeItem: null });
					}}
					handleCancel={() => {
						this.setState({ createVisible: false, pricechangeItem: null });
					}}
					convert={{}}
				/>
			</CrudPage>
		);
	}
}

function mapStateToProps(state) {
	return {
		isLoading: state.stores.isFetching || state.invoices.isFetching || state.distributors.isFetching,
		isLoadingStores: state.stores.isFetching,
		isLoadingInvoices: state.invoices.isFetching,
		isLoadingDistributors: state.distributors.isFetching,
		invoices: state.invoices.invoices,
		stores: state.stores.stores,
		distributors: state.distributors.distributors,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchStores,
		fetchDistributors,
		fetchInvoices,
		updateItem,
		createItem,
		deleteItem,
		processPricechange,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Pricechanges);
