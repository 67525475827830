import { types, flow } from 'mobx-state-tree';
import { v2Client } from '../store/client';

export const Calculation = types
	.model('Calculation', {
		id: types.identifierNumber,
		number: types.number,
		date: types.Date,
		storeId: types.number, // TODO: store reference
		invoiceId: types.number, // TODO: invoice reference
		processed: types.boolean,
		createdAt: types.Date,
		updatedAt: types.Date,
		deletedAt: types.maybe(types.Date),
	});

export const CalculationStore = types
	.model('CalculationStore', {
		calculations: types.array(Calculation),
		fetched: types.boolean,
		loading: types.boolean,
		pages: types.number,
		page: types.number,
	})
	.actions(self => ({
		fetch: flow(function* fetch(page = 1) {
			self.loading = true;
			self.page = page;

			const storeId = 2; // TODO: unhardcode this
			try {
				const response = yield v2Client.get(`/stores/${storeId}/calculations?year=2018`, {
					headers: {
						'pagination-limit': 10,
						'pagination-offset': page - 1,
					},
				});
				self.fetched = true;
				const {
					data,
					headers,
				} = response as any;

				if (data) {
					self.calculations.replace(data);
					self.pages = Math.ceil(headers['pagination-count'] / 10);
				}
			} catch (error) {
				console.log('network error');
			} finally {
				self.loading = false;
			}
		}),
		afterAuth: () => { },
	}))
	.views(self => ({

	}));

export type ProductStoreType = {
	productsByEan: Map<string, Object>,
	productsBySku: Map<string, Object>
};
