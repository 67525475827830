import React from 'react';
import { inject, observer } from 'mobx-react';

import Barcode from 'react-barcode';
import { Ean13Utils } from 'ean13-lib';

import styles from './Declaration.module.less';

function padZero(s, len, c = '0') {
	while (s.length < len) s = c + s;
	return s;
}

type Props = {
	record: any,
};

const Declaration = ({
	record: {
		product: {
			name,
			sku,
			imported: { barcodes },
		},
		unit,
		expiration,
		weight,
	},
}: Props) => {
	const ean = `200${`${padZero(`${sku}`, 4)}`}${`${padZero(
		`${weight * (unit === 'g' ? 1 : 1000)}`,
		5
	)}`}`;

	return (
		<div className={styles.declaration}>
			<div className="container">
				<div className="left">
					<div className="name">
						<div>{name}</div>
					</div>
				</div>
				<div className="right">
					<span className="expiration">
						Datum isteka: <strong>{expiration}</strong>
					</span>
				</div>
			</div>
			{/* <p>Quis eum laudantium quae modi aperiam. Dolorem dolor ducimus sapiente. Suscipit consequatur animi impedit nihil nulla voluptas. Voluptatem est consequatur et perspiciatis nostrum voluptatibus vel. Beatae illo eos eligendi quo molestiae. Nihil eos eligendi dolorem voluptatem saepe ea ut enim praesentium.

				Labore quia aliquam aperiam quia quo voluptas perspiciatis amet impedit. Cupiditate dignissimos et quis. Dolorem eius excepturi qui amet voluptatem omnis. Esse doloremque ut rerum natus animi eum.

				Animi quae debitis ducimus ducimus impedit eligendi voluptates magnam incidunt. Nesciunt similique voluptas labore quaerat delectus consequatur dolorem quia incidunt. Sed nam ipsum. Aut accusantium et. Aspernatur perspiciatis aut numquam cumque eos dolorum voluptatibus minima aut. Optio nobis nostrum.
			</p> */}
			<div className="container">
				<div className="left">
					<div className="sku">
						<span className="label">#</span>
						{sku}
						<Barcode
							value={`${ean}${Ean13Utils.calculateCheckDigit(
								ean
							)}`}
							format="EAN13"
							width={2}
							height={10}
							margin={0}
							marginLeft={10}
							background="transparent"
							fontSize="8"
						/>
					</div>
				</div>
				<div className="right">
					<div className="sku">
						{unit === 'g' ? (
							<span className="label">Neto</span>
						) : (
							''
						)}{' '}
						{weight}
						{unit === 'g' ? '' : ' '}
						{unit}{' '}
						{unit === 'g' ? <span className="label">℮</span> : ''}
					</div>
				</div>
			</div>
			<p className="center">
				Pet Shop Moja Mačkica doo | Bulevar Kralja Aleksandra 195/2,
				Beograd
				<br /> 069/762252 | info@mojamackica.com | mojamackica.com
			</p>
		</div>
	);
};

export default Declaration;
