import React, { Component } from 'react';
import { DatePicker, InputNumber, Input } from 'antd';
import withCrud from '../../../hocs/withCrud';
import DistributorSelect from './Components/DistributorSelect';
import ReferenceInput from './Components/ReferenceInput';

type Props = {};

type State = {};

class Invoices extends Component<Props, State> {
	componentDidMount = () => {};

	render() {
		return null;
	}
}

export default withCrud(Invoices, {
	model: 'invoice',
	columns: [
		{
			title: 'Broj fakture',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: 'Poziv na broj',
			key: 'reference',
			render: (record) =>
				`${
					record.reference.model ? `(${record.reference.model})` : ''
				} ${record.reference.number}`,
		},
	],
	createButtonText: 'Dodaj Fakturu',
	titles: {
		create: 'Dodaj Fakturu',
		edit: 'Izmeni Fakturu',
		delete: 'Da li ste sigurni da želite da obrišete ovu fakturu?',
		view: 'Pregled Fakture',
	},
	view: [],
	formFields: [
		{
			key: 'row1',
			fields: [
				{
					key: 'distributorId',
					label: 'Distributer',
					rules: [
						{ required: true, message: 'Distributer je obavezan' },
					],
					component: <DistributorSelect />,
				},
			],
		},
		{
			key: 'row2',
			fields: [
				{
					key: 'date',
					label: 'Datum fakture',
					span: 12,
					rules: [
						{
							required: true,
							message: 'Datum fakture je obavezan',
						},
					],
					component: <DatePicker style={{ width: '100%' }} />,
				},
				{
					key: 'dueDate',
					label: 'Datum valute',
					span: 12,
					rules: [
						{
							required: true,
							message: 'Datum valute je obavezan',
						},
					],
					component: <DatePicker style={{ width: '100%' }} />,
				},
			],
		},
		{
			key: 'row3',
			fields: [
				{
					key: 'number',
					label: 'Broj fakture',
					span: 12,
					rules: [
						{
							required: true,
							message: 'Broj fakture je obavezan',
						},
					],
					component: <Input />,
				},
				{
					key: 'reference',
					label: 'Poziv na broj',
					span: 12,
					rules: [],
					component: <ReferenceInput />,
				},
			],
		},
		{
			key: 'row4',
			fields: [
				{
					key: 'amount',
					label: 'Iznos',
					span: 6,
					rules: [
						{
							required: true,
							message: 'Iznos je obavezan',
						},
					],
					component: <InputNumber style={{ width: '100%' }} />,
				},
			],
		},
		{
			key: 'row5',
			fields: [
				{
					initialValue: '2',
					key: 'storeId',
					component: <Input type="hidden" />,
				},
			],
		},
	],
});
