import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import numeral from 'numeral';
import round from 'lodash/round';
import { SnapshotOrInstance, castToSnapshot } from 'mobx-state-tree';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Modal, InputNumber, Divider, notification, Button, Alert, Spin, Typography,
} from 'antd';
import { LocalSaleItem, LocalSale } from '../../../stores/LocalSale';

type Props = {
	form: any,
	visible?: boolean,
	saleItem: typeof LocalSaleItem.Type,
	sale: typeof LocalSale.Type,
	closeEdit: Function,
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 18 },
	},
};

@inject(({ store }, props: Props) => ({
	saleItem: props.saleItem,
}))
@observer
class EditModal extends Component<Props> {
	static defaultProps = {
		visible: false,
	};

	submit = async () => {
		const {
			form: { getFieldsValue }, sale, saleItem, closeEdit,
		} = this.props;
		const values = getFieldsValue();

		const amount = parseFloat(values.amount);
		const discount = parseFloat(values.discount);

		saleItem.updateDiscount(discount);

		if (amount <= 0 || Number.isNaN(amount)) {
			sale.removeItem(saleItem.product.id);
		} else {
			sale.updateQuantity(saleItem.id, amount);
		}
		setTimeout(closeEdit);
	};

	render() {
		const {
			saleItem,
			closeEdit,
			visible,
			form: {
				getFieldDecorator,
			},
		} = this.props;

		return (
			<Modal
				width={400}
				title="Izmena Artikla"
				visible={visible}
				onOk={() => {
					this.submit();
				}}
				onCancel={() => {
					closeEdit();
				}}
			>
				<Form
					{...formItemLayout}
				>
					<Typography.Title level={4}>{saleItem.product.name}</Typography.Title>
					<Form.Item
						label="Količina"
					>{getFieldDecorator('amount', {
							initialValue: saleItem.amount,
						})(
							<InputNumber
								ref={input => {
									setTimeout(() => {
										(input as any).focus();
										(input as any).select();
									});
								}}
								onPressEnter={() => {
									this.submit();
								}}
							/>
						)}
					</Form.Item>
					<Form.Item
						label="Popust"
					>{getFieldDecorator('discount', {
							initialValue: saleItem.discount,
						})(
							<InputNumber
								onPressEnter={() => {
									this.submit();
								}}
								max={100}
								min={0}
							/>
						)}
					</Form.Item>
					{/* <Form.Item
						label="Cena"
					>{getFieldDecorator('price', {
							initialValue: saleItem.price,
						})(
							<Input />
						)}
					</Form.Item> */}
				</Form>
			</Modal>
		);
	}
}

export default Form.create({
	name: 'editSaleItem',
})(EditModal);
