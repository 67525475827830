import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from 'antd';
import { useEffectOnce } from 'react-use';
import { Distributor } from '../../../../../stores/Distributor';

interface Props {
	loadingDistributors?: boolean;
	fetchDistributors?: Function;
	distributors?: [typeof Distributor.Type];
}

function DistributorSelect({
	loadingDistributors,
	fetchDistributors,
	distributors,
	...rest
}: Props) {
	useEffectOnce(() => {
		fetchDistributors();
	});
	return (
		<Select
			showSearch
			filterOption={(input, option) =>
				option.props.children
					.toLowerCase()
					.includes(input.toLowerCase())
			}
			loading={loadingDistributors}
			{...rest}
		>
			{distributors.map((distributor) => (
				<Select.Option key={distributor.id} value={`${distributor.id}`}>
					{distributor.name}
				</Select.Option>
			))}
		</Select>
	);
}
DistributorSelect.defaultProps = {
	loadingDistributors: false,
	fetchDistributors: () => {},
	distributors: [],
};

export default inject(({ store }) => ({
	loadingDistributors: store.distributorStore.isFetching,
	fetchDistributors: store.distributorStore.fetchAll,
	distributors: store.distributorStore.distributors,
}))(observer(DistributorSelect));
