import {
	types, flow, destroy, getParent, clone, applySnapshot, cast, getRoot,
} from 'mobx-state-tree';
import { Product } from './Product';

export const PrintPrice = types
	.model('PrintPrice', {
		product: types.reference(Product),
		quantity: types.number,
		price: types.number,
		oldPrice: types.maybeNull(types.number),
		discount: types.maybeNull(types.string),
		discountReason: types.maybeNull(types.string),
		discountEnd: types.maybeNull(types.string),
		discountCount: types.maybeNull(types.optional(types.number,1)),
		type: types.optional(types.enumeration(['regular', 'small', 'big', 'discount']), 'regular'),
	})
	.actions(self => {
		function removeProduct() {
			(getParent(getParent(self)) as any).removeProduct(self);
		}

		function updateQuantity(quantity) {
			self.quantity = quantity || 0;
		}
		function updateType(type) {
			self.type = type;
		}

		function updatePrice(price) {
			self.price = parseFloat(price || 0);
		}

		function updateOldPrice(price) {
			self.oldPrice = parseFloat(price || 0);
		}

		function updateDiscount(discount) {
			self.discount = discount;
		}
		function updateDiscountReason(discountReason) {
			self.discountReason = discountReason;
		}

		function updateDiscountEnd(discountEnd) {
			self.discountEnd = discountEnd;
		}
		function updateDiscountCount(discountCount) {
			self.discountCount = discountCount;
		}

		return {
			removeProduct,
			updateQuantity,
			updateType,
			updatePrice,
			updateOldPrice,
			updateDiscount,
			updateDiscountEnd,
			updateDiscountReason,
			updateDiscountCount,
		};
	});

export const PrintPricesStore = types
	.model('PrintPricesStore', {
		items: types.optional(types.array(PrintPrice), []),
		cache: types.optional(types.boolean, true),
	})
	.actions(self => {
		function addProduct(product, quantity) {
			self.items.push({
				product, quantity, price: product.imported.price, oldPrice: product.imported.oldPrice, discount: product.imported.discount,
			});
		}
		function removeProduct(product) {
			destroy(product);
		}
		function removeAllProducts() {
			self.items.splice(0, self.items.length);
		}
		return {
			addProduct,
			removeProduct,
			removeAllProducts,
		};
	});
