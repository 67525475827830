import React, { Component, useState } from 'react';
import { observer, inject } from 'mobx-react';
import numeral from 'numeral';
import round from 'lodash/round';
import { SnapshotOrInstance, castToSnapshot, Instance } from 'mobx-state-tree';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Modal, Input, Divider, notification, Button, Alert, Spin, Select, Result
} from 'antd';
import NewERPIcon from "../../../components/ERPIcon/new";
import { LocalSale } from '../../../stores/LocalSale';
import { Delivery } from '../../../stores/Delivery';

type Props = {
	amount: Number,
	form: any,
	visible?: boolean,
	sale: Instance<typeof LocalSale>,
	closePayment?: Function,
	fetchDeliveries?: Function,
	payment?: any
	printing?: boolean,
	completed?: boolean,
	prefillPayment: [any, any, any],
	deliveries?: Array<Instance<typeof Delivery>>
};

type State = {
	success: boolean,
}

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 18 },
	},
};

type Fields = {
	cash: {
		value: string,
	},
	check: {
		value: string,
	},
	card: {
		value: string,
	},
	delivery: {
		value: string,
	}
};

@inject(({ store }, props: Props) => ({
	payment: props.sale.payment,
	printing: props.sale.printing,
	completed: props.sale.completed,
	deliveries: store.deliveryStore.deliveries,
}))
@observer
class PaymentModal extends Component<Props, State> {
	static defaultProps = {
		visible: false,
	};

	state = {
		success: false,
	}

	componentDidMount = () => {
		const { sale, prefillPayment } = this.props;
		sale.updatePayment(...prefillPayment);
	};

	submit = async (cash, check, card, amount, sale, change) => {
		const paid = Number(cash) + Number(check) + Number(card);
		const remaining = Number(amount) - paid;
		if (paid < Number(amount)) {
			return notification.error({
				key: 'paymentError',
				message: 'Obavezan unos iznosa',
				description: `Obavezan je unos celog iznosa plaćanja. Nedostaje još ${numeral(remaining).format('0,0.00')} RSD.`,
				duration: 10,
			});
		}

		if (Number(card) > Number(amount)) {
			return notification.error({
				key: 'paymentError',
				message: 'Pogrešan unos',
				description: 'Karticom je moguće platiti manji ili jednak iznos računa',
				duration: 10,
			});
		}

		if (Number(check) > Number(amount)) {
			return notification.error({
				key: 'paymentError',
				message: 'Pogrešan unos',
				description: 'Čekom je moguće platiti manji ili jednak iznos računa',
				duration: 10,
			});
		}

		const completedSale = await sale.completeSale();

		return completedSale;
	};

	render() {
		const {
			form: {
				getFieldDecorator,
			},
			sale,
			closePayment,
			amount,
			visible,
			payment,
			form,
			printing,
			completed,
			prefillPayment,
		} = this.props;

		const {
			change, cash, card, check, delivery, waitForCard,
		} = payment;

		if (printing) {
			return <Modal
				width={450}
				visible={visible}
				footer={null}
				closable={false}
			>
				<Result
					title="Štampa računa"
					subTitle="Štampa računa je u toku, sačekajte molim"
					icon={<NewERPIcon type="erpa-006-receipt-1" />}
				/>
			</Modal>;
		}
		if (waitForCard) {
			return <Modal
				width={450}
				visible={visible}
				footer={null}
				closable={false}
			>
				<Result
					title="Plaćanje karticom"
					subTitle="Čekanje uplate putem POS terminala"
					icon={<NewERPIcon type="erpcredit-card" />}
					extra={(
						<Button type="primary" onClick={() => sale.cancelPos()}>
							Odustani
						</Button>
					)}
				/>
			</Modal>;
		}

		return (
			<Modal
				width={450}
				title={!printing && !waitForCard ? "Plaćanje" : null}
				visible={visible}
				onOk={() => {
					this.submit(cash, check, card, amount, sale, change);
				}}
				onCancel={() => {
					closePayment();
				}}
				okButtonProps={{ disabled: completed || printing || waitForCard }}
				cancelButtonProps={{ disabled: completed || printing ||waitForCard}}
				confirmLoading={printing}
			>
					<Form
						{...formItemLayout}
					>
						<Form.Item
							label="Ukupno"
						>
							<Input
								value={`${amount}`}
								readOnly
							/>
						</Form.Item>
						<Divider />
						<Form.Item
							label="Gotovina"
						>
							{
								getFieldDecorator('cash', {
									initialValue: cash,
									value: cash,
								})(
									<Input
										ref={input => {
											setTimeout(() => {
												if (prefillPayment[0] > 0 || prefillPayment[0] + prefillPayment[1] + prefillPayment[2] === '') { input.focus(); input.select(); }
											});
										}}
										allowClear
										onPressEnter={() => {
											this.submit(cash, check, card, amount, sale, change);
										}}
										suffix={(
											<Button.Group>
												<Button
													onClick={() => {
														sale.updatePayment(
															round(Number(amount) - check - card, 2),
															Number(check),
															Number(card),
														);
														form.setFieldsValue({
															cash: round(Number(amount) - check - card, 2),
															check,
															card,
														});
													}}
													size="small"
												>Ostatak Iznosa
												</Button>
												<Button
													onClick={() => {
														sale.updatePayment(
															Number(amount),
															0,
															0,
														);
														form.setFieldsValue({
															cash: Number(amount),
															check: 0,
															card: 0,
														});
													}}
													size="small"
												>Ceo Iznos
												</Button>
											</Button.Group>
										)}
									/>
								)
							}
						</Form.Item>
						<Form.Item
							label="Ček"
						>
							{
								getFieldDecorator('check', {
									initialValue: check,
								})(
									<Input
										ref={input => {
											setTimeout(() => {
												if (prefillPayment[1] > 0) { input.focus(); input.select(); }
											});
										}}
										allowClear
										onPressEnter={() => {
											this.submit(cash, check, card, amount, sale, change);
										}}
										suffix={(
											<Button.Group>
												<Button
													onClick={() => {
														sale.updatePayment(
															Number(cash),
															round(Number(amount) - cash - card, 2),
															Number(card),
														);
														form.setFieldsValue({
															cash: Number(cash),
															check: round(Number(amount) - cash - card, 2),
															card: Number(card),
														});
													}}
													size="small"
												>Ostatak Iznosa
												</Button>
												<Button
													onClick={() => {
														sale.updatePayment(
															0,
															Number(amount),
															0,
														);
														form.setFieldsValue({
															cash: 0,
															check: Number(amount),
															card: 0,
														});
													}}
													size="small"
												>Ceo Iznos
												</Button>
											</Button.Group>
										)}
									/>

								)
							}
						</Form.Item>
						<Form.Item
							label="Kartica"
						>
							{
								getFieldDecorator('card', {
									initialValue: card,
								})(
									<Input
										ref={input => { setTimeout(() => { if (prefillPayment[2] > 0) { input.focus(); input.select(); } }); }}
										allowClear
										onPressEnter={() => {
											this.submit(cash, check, card, amount, sale, change);
										}}
										suffix={(
											<Button.Group>
												<Button
													onClick={() => {
														sale.updatePayment(
															Number(cash),
															Number(check),
															round(Number(amount) - cash - check, 2),
														);
														form.setFieldsValue({
															cash: Number(cash),
															check: Number(check),
															card: round(Number(amount) - cash - check, 2),
														});
													}}
													size="small"
												>Ostatak Iznosa
												</Button>
												<Button
													onClick={() => {
														sale.updatePayment(
															0,
															0,
															Number(amount),
														);
														form.setFieldsValue({
															cash: 0,
															check: 0,
															card: Number(amount),
														});
													}}
													size="small"
												>Ceo Iznos
												</Button>
											</Button.Group>
										)}
									/>
								)
							}
						</Form.Item>
						<Divider />
						<Form.Item
							label="Povraćaj"
						>
							{
								getFieldDecorator('change', {
									initialValue: change,
								})(
									<Input
										readOnly
									/>
								)
							}
						</Form.Item>
						<Divider />
						<Form.Item
							label="Dostava"
						>
							{
								getFieldDecorator('delivery', {
									initialValue: null,
								})(
									<Select>
										<Select.Option value={null}>Bez dostave</Select.Option>
										{this.props.deliveries.map((delivery)=> <Select.Option key={delivery.id} value={delivery.id}>{delivery.name}</Select.Option>)}
									</Select>
								)
							}
						</Form.Item>
					</Form>
			</Modal>
		);
	}
}

export default Form.create({
	name: 'payment',
	onFieldsChange: (props: Props, fields: Fields) => {
		if (typeof fields.delivery !== 'undefined') {
			props.sale.updateDelivery(fields.delivery ? parseInt(fields.delivery.value) : null)
		} else {
			props.sale.updatePayment(
				fields.cash ? parseFloat(fields.cash.value) : null,
				fields.check ? parseFloat(fields.check.value) : null,
				fields.card ? parseFloat(fields.card.value) : null,
			);
		}
	},
})(PaymentModal);
