import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SocketIo from 'socket.io-client';
import moment from 'moment';
import { PrinterOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import print from 'print-html-element';
import { findDOMNode } from 'react-dom';

import {
	Button,
	Space,
	Input,
	Table,
	Alert,
	Form,
	DatePicker,
	Select,
	Row,
	Col,
} from 'antd';
import styles from './Declaration.module.less';

import SearchDrawer from '../../POS/Search';
import QuantityInput from './QuantityInput';
import Declaration from './Declaration';
import Declarations from './Declarations';
import { ProductStore, Product } from '../../../../stores/Product';

type Props = {
	productsStore: typeof ProductStore.Type
};

type Weight = {
	weight?: number,
	quantity?: number,
	id?: number,
};

type State = {
	searchVisible: boolean,
	product: typeof Product.Type,
	unit: string,
	weights: Weight[],
	expiration: any,
};


// eslint-disable-next-line react/prefer-stateless-function
@inject(({ store: { productStore } }) => ({
	products: productStore.products,
}))
export default class PrintDeclarations extends Component<Props, State> {
	state = {
		searchVisible: false,
		product: null,
		unit: 'g',
		weights: [],
		expiration: moment(),
	};

	printRef = null;

	renderHeader = () => (
		<Space>
			<Button
				onClick={() => {
					this.setState({
						searchVisible: true,
					});
				}}
				icon={<PlusOutlined />}
			>
				Izaberi proizvod
			</Button>
			<Button
				onClick={() => {
					print.printElement(findDOMNode(this.printRef));
				}}
				icon={<PrinterOutlined />}
			>
				Štampaj
			</Button>
		</Space>
	);

	render() {
		const { searchVisible, product, weights } = this.state;
		return (
			<>
				{!product && <Alert type="error" message="Izaberite proizvod" />}
				{product && (
					<Form
						name="declarations"
						initialValues={{}}
						onFinish={() => {}}
						layout="vertical"
					>
						<Alert type="info" message={<span>Izabran je proizvod <strong>{product.sku} - {product.name}</strong></span>} /><br />
						<Row gutter={8}>
							<Col span={3}>
								<Form.Item
									label="Jedinica mere"
									name="unit"
								>
									<Select
										onChange={value => {
											this.setState(state => ({
												unit: value as string,
												weights: state.weights.map(weight => ({
													...weight,
													weight: value === 'kom' && weight.weight > 99 ? 99 : weight.weight,
												})),
											}));
										}}
									>
										<Select.Option value="g">g</Select.Option>
										<Select.Option value="kom">kom</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item
									label="Datum isteka"
									name="expiration"
									rules={[{ required: true, message: 'Unesite datum isteka!' }]}
								>
									<DatePicker
										onChange={value => {
											this.setState({ expiration: moment(value) });
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Button
									onClick={() => {
										this.setState(state => ({
											weights: [
												...state.weights,
												{
													id: state.weights.length + 1,
													weight: this.state.unit === 'kom' ? 50 : 500,
												},
											],
										}));
									}}
									className={styles.addButton}
								>Dodaj težinu
								</Button>
							</Col>
						</Row>
						<Table
							pagination={false}
							bordered
							dataSource={weights}
							rowKey="id"
							columns={[
								{
									key: 'weight',
									title: 'Težina',
									render: (text, record) => (
										<QuantityInput
											record={record}
											max={this.state.unit === 'kom' ? 99 : 99999}
											field="weight"
											onChange={value => {
												if ((this.state.unit === 'kom' ? 99 : 99999) < value) {
													return;
												}
												this.setState(state => {
													const index = state.weights.findIndex(weight => weight.id === record.id);
													const data = state.weights[index];
													return {
														weights: [
															...state.weights.slice(0, index),
															{
																...data,
																weight: parseInt(value || 0, 10),
															},
															...state.weights.slice(index + 1),
														],
													};
												});
											}}
										/>
									),
								},
								{
									key: 'quantity',
									title: 'Količina',
									render: (text, record) => (
										<QuantityInput
											record={record}
											field="quantity"
											onChange={value => {
												this.setState(state => {
													const index = state.weights.findIndex(weight => weight.id === record.id);
													const data = state.weights[index];
													return {
														weights: [
															...state.weights.slice(0, index),
															{
																...data,
																quantity: parseInt(value || 0, 10),
															},
															...state.weights.slice(index + 1),
														],
													};
												});
											}}
										/>
									),
								},
								{
									key: 'preview',
									title: 'Pregled',
									render: (text, record) => (
										<Declaration
											record={{
												product: this.state.product,
												expiration: (this.state.expiration as any).format('LL'),
												unit: this.state.unit,
												...record,
											}}
										/>
									),
								},
							]}
						/>

					</Form>
				)
				}
				<div className={styles.print} ref={p => this.printRef = p}>
					{weights.map(record => (
						<Declarations record={{
							product: this.state.product,
							expiration: (this.state.expiration as any).format('LL'),
							unit: this.state.unit,
							...record,
						}}
						/>
					))}
				</div>
				<SearchDrawer
					onRowClick={product => {
						this.setState({
							product,
						});
					}}
					closeSearch={() => {
						this.setState({
							searchVisible: false,
						});
					}}
					visible={searchVisible}
				/>
			</>
		);
	}
}
