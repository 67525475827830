import React, { useState, forwardRef } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {
	Row, Col, Input, Button, Card, Form,
} from 'antd';
import shortid from 'shortid';

import styles from './ContactsInput.module.less';

type Contact = {
	name?: string,
	role?: string,
	phone?: string,
	email?: string,
	id: string,
};

type Props = {
	value?: Array<Contact>
	onChange?: Function,
};


function ContactsInput(props: Props, ref) {
	const {
		value = [],
		onChange = () => {},
	} = props;

	const [contacts, setContacts] = useState(value || []);

	function handleEdit(index: number, field: string, editValue: string) {
		const newContacts = contacts.slice();
		newContacts.splice(index, 1, {
			...contacts[index],
			[field]: editValue,
		});

		setContacts(newContacts);
		onChange(newContacts);
	}

	function handleDeleteContact(index) {
		const newContacts = contacts.slice();
		newContacts.splice(index, 1);

		setContacts(newContacts);
		onChange(newContacts);
	}

	function renderContact(contact: Contact, index: number) {
		const {
			name, role, email, phone, id,
		} = contact;

		return (
			<Row
				key={id}
				gutter={8}
			>
				<Col
					span={6}
				>
					<Form.Item
						label="Ime / Naziv"
					>
						<Input
							value={name}
							onChange={event => handleEdit(index, 'name', event.currentTarget.value)}
						/>
					</Form.Item>
				</Col>
				<Col
					span={4}
				>
					<Form.Item
						label="Uloga"
					>
						<Input
							value={role}
							onChange={event => handleEdit(index, 'role', event.currentTarget.value)}
						/>
					</Form.Item>
				</Col>
				<Col
					span={6}
				>
					<Form.Item
						label="Telefon"
					>
						<Input
							value={phone}
							onChange={event => handleEdit(index, 'phone', event.currentTarget.value)}
						/>
					</Form.Item>
				</Col>
				<Col
					span={8}
				>
					<Form.Item
						label="E-Mail"
					>
						<Row gutter={8}>
							<Col span={20}>
								<Input
									value={email}
									onChange={event => handleEdit(index, 'email', event.currentTarget.value)}
								/>
							</Col>
							<Col span={4}>
								<Button
									icon={<DeleteOutlined />}
									onClick={() => handleDeleteContact(index)}
								/>
							</Col>
						</Row>

					</Form.Item>
				</Col>
			</Row>
		);
	}

	function handleAddContact() {
		setContacts([
			...contacts,
			{
				id: shortid(),
			},
		]);
	}

	return (
		<Card>
			<div ref={ref}>
				{contacts.map((contact, index) => renderContact(contact, index))}
				<Row>
					<Col>
						<Button
							className={styles.addButton}
							type="link"
							onClick={handleAddContact}
						>
                        Dodaj kontakt
						</Button>
					</Col>
				</Row>
			</div>
		</Card>
	);
}

export default forwardRef(ContactsInput);
