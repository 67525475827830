import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import SocketIo from 'socket.io-client';
import trim from 'lodash/trim';
import Sound from 'react-sound';
import ProductNotFound from './ProductNotFound';
import NewERPIcon from "../../../components/ERPIcon/new";

import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import numeral from 'numeral';
import {
	BarcodeOutlined,
	CaretRightOutlined,
	CheckOutlined,
	DeleteOutlined,
	EditOutlined,
	DownOutlined,
	FolderOutlined,
	SearchOutlined,
	TagOutlined,
	UserOutlined,
} from '@ant-design/icons';
import {
	Table,
	Button,
	notification,
	Input,
	Popover,
	Divider,
	Menu,
	Dropdown,
	ConfigProvider,
	Empty,
} from 'antd';

import Icon from '../../../components/ERPIcon';
import PaymentModal from './PaymentModal';
import EditModal from './EditModal';
import Search from './Search';

import * as styles from './POS.module.less';

import { ProductStore } from '../../../stores/Product';
import { LocalSaleStore, LocalSaleItem } from '../../../stores/LocalSale';

const SOUNDS = {
	ERROR: '/sounds/Basso.mp3',
	BEEP: '/sounds/beep.mp3',
	DELETE: '/sounds/trash.mp3',
};

type Props = {
	height: number,
	sale: any,
	active: boolean,
	socket: SocketIo,
	store?: {
		productStore: typeof ProductStore.Type,
		localSaleStore: typeof LocalSaleStore.Type,
	},
	loading: boolean,
	activeProductId?: number,
};

type State = {
	popovers: {
		sku: boolean,
		ean: boolean,
	},
	values: {
		sku: string,
		ean: string,
	},
	paymentModalVisible: boolean,
	editModalVisible: boolean,
	editSaleItem: typeof LocalSaleItem,
	prefillPayment: [any, any, any],
	searchVisible: boolean,
	sound: {
		playing: boolean,
		file: string,
	}
};

notification.config({
	placement: 'bottomRight',
});

const menu = (
	<Menu>
		<Menu.Item key="1">Gotovinski račun</Menu.Item>
	</Menu>
);

@inject(({ store }, props: Props) => ({
	store,
	activeProductId: props.sale.activeProductId,
	loading: props.loading,
}))
@observer
class Sale extends Component<Props, State> {
	state = {
		popovers: {
			sku: false,
			ean: false,
		},
		values: {
			sku: '',
			ean: '',
		},
		notFoundModalVisible: false,
		notFoundModalText: '',
		paymentModalVisible: false,
		editModalVisible: false,
		editSaleItem: null,
		searchVisible: false,
		sound: {
			playing: false,
			file: '',
		},
		prefillPayment: ['', '', ''] as [any, any, any],
	};

	focusedInput: boolean = false;

	lastSku: string = '';

	componentDidMount() {
		const { socket } = this.props;
		socket.on('ean', this.onEan);

		document.addEventListener('keydown', this.handleKeyDown);
		document.addEventListener('keyup', this.handleKeyUp);
	}

	componentDidUpdate(previousProps: Props) {
		if (previousProps.activeProductId !== this.props.activeProductId) {
			const el = document.querySelector('.posTable table tr .active');
			if (el) {
				el.parentElement.scrollIntoView();
			}
		}
	}

	componentWillUnmount() {
		const { socket } = this.props;
		socket.off('ean', this.onEan);

		document.removeEventListener('keydown', this.handleKeyDown);
		document.removeEventListener('keyup', this.handleKeyUp);
	}

	handleKeyDown = (e: KeyboardEvent) => {
		const { active, sale } = this.props;
		const {
			notFoundModalVisible, paymentModalVisible, editModalVisible, searchVisible, popovers: { sku: skuPopOverVisible, ean: eanPopOverVisible },
		} = this.state;

		if (notFoundModalVisible) {
			return;
		}

		if (active && !paymentModalVisible && !searchVisible && !editModalVisible) {
			if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
				if (!this.focusedInput) {
					this.setState(state => ({
						popovers: {
							...state.popovers,
							sku: true,
						},
						values: {
							...state.values,
							sku: e.key,
						},
					}));

					this.focusedInput = true;
				}
			}

			if (skuPopOverVisible || eanPopOverVisible) {
				if (e.keyCode === 27) {
					this.setState(() => ({
						popovers: {
							ean: false,
							sku: false,
						},
						values: {
							ean: '',
							sku: '',
						},
					}));
				}
			}
			if (!skuPopOverVisible && !eanPopOverVisible && !searchVisible && !paymentModalVisible && !editModalVisible) {
				// if (e.keyCode === 13 && !this.focusedInput && this.lastSku !== '') {
				// 	this.addBySku(parseInt(this.lastSku, 10));
				// }

				if (e.keyCode === 130) {
					this.addBySku(1000);
				}

				if ((e.keyCode === 8 || e.keyCode === 46) && !this.focusedInput) {
					sale.removeItem(sale.activeProductId);
					this.playSound(SOUNDS.DELETE);
				}

				if (e.keyCode === 38) {
					// Up arrow
					sale.selectPreviousItem();
				}

				if (e.keyCode === 40) {
					// Down arrow
					sale.selectNextItem();
				}
				if (e.keyCode === 114 || ((e.ctrlKey || e.metaKey) && e.keyCode === 70)) {
					this.setState(() => ({
						searchVisible: true,
					}));
					e.preventDefault();
					return false;
				}
			}

			if (skuPopOverVisible && !paymentModalVisible && !editModalVisible) {
				if (e.keyCode === 127 || e.keyCode === 115) {
					if (sale.itemsAsArray.length === 0) {
						return notification.error({
							key: 'paymentError',
							message: 'Račun je prazan',
							description: 'Nije moguće naplatiti prazan račun.',
							duration: 10,
						});
					}
					this.setState(state => ({
						paymentModalVisible: true,
						prefillPayment: [parseFloat(state.values.sku), '', ''],
						popovers: {
							ean: false,
							sku: false,
						},
						values: {
							ean: '',
							sku: '',
						},
					}));
				}
			} else if (e.keyCode === 127 || e.keyCode === 115) {
				e.preventDefault();
				if (sale.itemsAsArray.length === 0) {
					return notification.error({
						key: 'paymentError',
						message: 'Račun je prazan',
						description: 'Nije moguće naplatiti prazan račun.',
						duration: 10,
					});
				}
				this.setState(() => ({
					paymentModalVisible: true,
					prefillPayment: [parseFloat(sale.total), '', ''],
				}));
			} else if (e.keyCode === 128 || e.keyCode === 116) {
				e.preventDefault();
				if (sale.itemsAsArray.length === 0) {
					return notification.error({
						key: 'paymentError',
						message: 'Račun je prazan',
						description: 'Nije moguće naplatiti prazan račun.',
						duration: 10,
					});
				}
				this.setState(() => ({
					paymentModalVisible: true,
					prefillPayment: ['', parseFloat(sale.total), ''],
				}));
			} else if (e.keyCode === 129 || e.keyCode === 117) {
				e.preventDefault();
				if (sale.itemsAsArray.length === 0) {
					return notification.error({
						key: 'paymentError',
						message: 'Račun je prazan',
						description: 'Nije moguće naplatiti prazan račun.',
						duration: 10,
					});
				}
				this.setState(() => ({
					paymentModalVisible: true,
					prefillPayment: ['', '', parseFloat(sale.total)],
				}));
			}
		}
	};

	handleKeyUp = (e: KeyboardEvent) => {
		const { active, sale } = this.props;
		const {
			paymentModalVisible, editModalVisible, searchVisible, popovers: { sku: skuPopOverVisible, ean: eanPopOverVisible },
		} = this.state;

		if (active && !paymentModalVisible && !searchVisible && !editModalVisible) {
			if (!skuPopOverVisible && !eanPopOverVisible) {
				if (e.keyCode === 187 && !e.shiftKey) {
					// =
					if (sale.itemsAsArray.length === 0) {
						return notification.error({
							key: 'paymentError',
							message: 'Račun je prazan',
							description: 'Nije moguće naplatiti prazan račun.',
							duration: 10,
						});
					}
					this.setState(() => ({
						paymentModalVisible: true,
					}));
				}
				if (e.keyCode === 107 || (e.keyCode === 187 && e.shiftKey)) {
					// document.querySelector('.posTable table tr .active').parentElement.parentElement.querySelector('input').select();
					if (sale.activeSaleItem) {
						this.setState(() => ({
							editModalVisible: true,
							editSaleItem: sale.activeSaleItem,
						}));
					}
					
				}
			}
		}
	};

	onEan = (ean: string) => {
		const { active } = this.props;
		const {notFoundModalVisible} = this.state;

		if (notFoundModalVisible) {
			return;
		}

		if (active) {
			this.addByEan(ean);
		}
	};

	addByEan = async (emittedEan:string) => {
		const { store: { productStore: { productsBySku } } } = this.props;
		const self = this;
		const ean: string = trim(`${emittedEan}`);

		const { values: { sku: value } } = this.state;

		let amountMultiplier = 1;
		if (value.includes('x') || value.includes('*')) {
			const parts = value.split(/[x*]/);
			amountMultiplier = parseFloat(parts[0]);

			this.setState(state => ({
				popovers: {
					...state.popovers,
					sku: false,
				},
				values: {
					...state.values,
					sku: '',
				},
			}));
		}

		if (amountMultiplier <= 0) {
			this.playSound(SOUNDS.ERROR);
			return notification.error({
				key: 'amountError',
				message: 'Neispravna količina',
				description: `Količina ne može biti ${amountMultiplier}`,
				duration: 10,
			});
		}
		if (ean.startsWith('DISCOUNT')) {
			const [, skuString, priceString] = ean.split('-');
			const sku = parseInt(skuString, 10);
			const price = parseFloat(priceString);

			const product = productsBySku[sku];

			if (product) {
				return self.add(product, 1, price);
			}

			this.playSound(SOUNDS.ERROR);
			return this.setState((state) => ({
				...state,
				notFoundModalVisible: true,
				notFoundModalText: `Proizvod sa šifrom ${sku} nije pronađen. Pokušajte drugi metod unosa.`
			}));
		} if (ean[0] === '2') {
			const sku = parseInt(ean.substr(2, 5), 10);
			const amount = parseInt(ean.substr(7, 5), 10) / 1000;

			const product = productsBySku[sku];

			if (product) {
				return self.add(product, amount * amountMultiplier);
			}

			this.playSound(SOUNDS.ERROR);
			return this.setState((state) => ({
				...state,
				notFoundModalVisible: true,
				notFoundModalText: `Proizvod sa šifrom ${sku} nije pronađen. Pokušajte drugi metod unosa.`
			}));
		}
		const product = self.props.store.productStore.productsByEan[ean] ? self.props.store.productStore.productsByEan[ean][0] : null;
		if (product) {
			return self.add(product, amountMultiplier);
		}

		this.playSound(SOUNDS.ERROR);
		return this.setState((state) => ({
			...state,
			notFoundModalVisible: true,
			notFoundModalText: `Proizvod sa bar kodom ${ean} nije pronađen. Pokušajte drugi metod unosa.`
		}));
	};

	addBySku = (sku: number, amount: number = 1) => {
		const { store: { productStore: { productsBySku } } } = this.props;

		const product = productsBySku[sku];

		if (product) {
			return this.add(product, amount);
		}

		this.playSound(SOUNDS.ERROR);
		return this.setState((state) => ({
			...state,
			notFoundModalVisible: true,
			notFoundModalText: `Proizvod sa šifrom ${sku} nije pronađen. Pokušajte drugi metod unosa.`
		}));
	};

	add = async (product, amount: number, overridePrice = 0) => {
		const { sale: { addItem } } = this.props;
		try {
			await addItem(product, amount, overridePrice);
			this.playSound(SOUNDS.BEEP);
		} catch (error) {
			this.playSound(SOUNDS.ERROR);
			return notification.error({
				key: 'addError',
				message: 'Greška',
				description: `${error.message}`,
				duration: 10,
			});
		}
	};

	playSound = file => {
		this.setState({
			sound: {
				playing: false,
				file: '',
			},
		}, () => {
			this.setState({
				sound: {
					playing: true,
					file,
				},
			});
		});
	};

	render() {
		const {
			sale, height, store: { localSaleStore }, activeProductId, loading,
		} = this.props;
		const {
			paymentModalVisible, searchVisible, editModalVisible, editSaleItem,
		} = this.state;
		const columns = [{
			title: '',
			width: 32,
			render: (text, record) => (record.product.id === activeProductId ? <CaretRightOutlined className="active" /> : <div />),
		}, {
			title: 'Šifra',
			dataIndex: ['product', 'sku'],
			width: 80,
		}, {
			title: 'Naziv',
			dataIndex: ['product', 'name'],
		}, {
			title: 'Količina',
			dataIndex: 'amount',
			width: 100,
		}, {
			title: 'Cena',
			dataIndex: ['product', 'imported', 'price'],
			render: (text, record) => (record.discount
				? <><span className={styles.strikethrough}>{`${numeral(record.price).format('0,0.00')} RSD`}</span> {`${numeral(record.finalPrice).format('0,0.00')} RSD`} ({record.discount}% Popusta)</>
				: `${numeral(record.finalPrice).format('0,0.00')} RSD`),
			width: 150,
		}, {
			title: 'Iznos',
			key: 'total',
			render: record => (
				record.discount
				 ? <><span className={styles.strikethrough}>{`${numeral(record.price * record.amount).format('0,0.00')} RSD`}</span> {`${numeral(record.finalPrice * record.amount).format('0,0.00')} RSD`} ({record.discount}% Popusta)</>
				 : `${numeral(record.price * record.amount).format('0,0.00')} RSD`
			),
			width: 150,
		}, {
			title: 'Akcije',
			key: 'actions',
			width: 260,
			render: record => (
				<span>
					<Button
						size="small"
						icon={<EditOutlined />}
						style={{ marginRight: 8 }}
						onClick={() => {
							this.setState({
								editModalVisible: true,
								editSaleItem: record,
							});
						}}
					>
						Izmena
					</Button>
					<Button
						danger
						size="small"
						icon={<DeleteOutlined />}
						style={{ marginRight: 8 }}
						onClick={() => {
							sale.removeItem(record.id);
							this.playSound(SOUNDS.DELETE);
						}}
					>
						Storno
					</Button>
				</span>
			),
		},
		];

		const { popovers, sound: { file: soundFile, playing }, prefillPayment, notFoundModalVisible, notFoundModalText } = this.state;

		return (
			<div
				style={{ height: height - 180 }}
			>
				<Sound
					url={soundFile}
					playStatus={playing ? Sound.status.PLAYING : Sound.status.STOPPED}
					onFinishedPlaying={() => {
						this.setState({
							sound: {
								playing: false,
								file: '',
							},
						});
					}}
				/>
				<div className="wrap">
					<div className="content">
						<div
							style={{ float: 'left' }}
							className={styles.subTotal}
						>
							<NumberInfo
								style={{ textAlign: 'left' }}
								title="MEĐUZBIR"
								total={(
									<h2 style={{ marginRight: -32 }}>
										<strong>{numeral(
											sale.total
										).format('0,0.00')}
										</strong>
									</h2>
								)}
							/>
						</div>
						<div
							style={{
								float: 'right',
								marginBottom: 24,
							}}
						>
							<Popover
								trigger="click"
								visible={popovers.sku}
								onVisibleChange={visible => {
									this.setState(state => ({
										popovers: {
											...state.popovers,
											sku: visible,
										},
										values: {
											...state.values,
											sku: '',
										},
									}));
									this.focusedInput = visible;
								}}
								title="Unesite šifru"
								content={(

									<Input
										ref={skuInput => {
											if (skuInput) {
												setTimeout(() => skuInput.focus());
											}
										}}
										onFocus={() => {
											this.focusedInput = true;
										}}
										onBlur={() => {
											this.focusedInput = false;
										}}
										onChange={event => {
											const { value } = event.target;
											this.setState(state => ({
												values: {
													...state.values,
													sku: value,
												},
											}));
										}}
										value={`${this.state.values.sku}`}
										onPressEnter={event => {
											const { value } = event.currentTarget;
											if (value === '') {
												return;
											}

											if (value.includes('x') || value.includes('*')) {
												const parts = value.split(/[x*]/);
												const [amount, sku] = parts;
												const amountMultiplier = parseFloat(amount);
												if (amountMultiplier <= 0) {
													this.playSound(SOUNDS.ERROR);
													return notification.error({
														key: 'amountError',
														message: 'Neispravna količina',
														description: `Količina ne može biti ${amountMultiplier}`,
														duration: 10,
													});
												}
												this.addBySku(parseInt(sku, 10), amountMultiplier);
												this.lastSku = sku;
											} else {
												this.addBySku(parseInt(value, 10));
												this.lastSku = value;
											}

											this.setState(state => ({
												popovers: {
													...state.popovers,
													sku: false,
												},
												values: {
													...state.values,
													sku: '',
												},
											}));
										}}
									/>
								)}
							>
								<Button
									icon={<TagOutlined />}
									style={{ marginRight: 8 }}
								>Šifra
								</Button>
							</Popover>
							<Popover
								trigger="click"
								visible={popovers.ean}
								onVisibleChange={visible => {
									this.setState(state => ({
										popovers: {
											...state.popovers,
											ean: visible,
										},
										values: {
											...state.values,
											ean: '',
										},
									}));
								}}
								title="Unesite bar kod"
								content={(
									<Input
										ref={eanInput => {
											if (eanInput) {
												setTimeout(() => eanInput.focus());
											}
										}}
										onFocus={() => {
											this.focusedInput = true;
										}}
										onBlur={() => {
											this.focusedInput = false;
										}}
										value={this.state.values.ean}
										onChange={event => {
											const { value } = event.target;

											if (value === '') {
												return;
											}

											this.setState(state => ({
												values: {
													...state.values,
													ean: value,
												},
											}));
										}}
										onPressEnter={event => {
											const { value } = event.currentTarget;

											this.addByEan(value);

											this.setState(state => ({
												popovers: {
													...state.popovers,
													ean: false,
												},
												values: {
													...state.values,
													ean: '',
												},
											}));
										}}
									/>
								)}
							>
								<Button
									icon={<BarcodeOutlined />}
									style={{ marginRight: 8 }}
								>Bar Kod
								</Button>
							</Popover>
							{/* <Button icon={<FolderOutlined />} disabled style={{ marginRight: 8 }}>Kategorije</Button> */}
							<Button
								icon={<SearchOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									this.setState(() => ({
										searchVisible: true,
									}));
								}}
							>Pretraga
							</Button>
							<Divider type="vertical" />
							<Button icon={<UserOutlined />} disabled style={{ marginLeft: 8, marginRight: 8 }}>Član</Button>
							<Divider type="vertical" />
							<Dropdown overlay={menu} disabled>
								<Button
									style={{ marginLeft: 8, marginRight: 8 }}
								>
									Više <DownOutlined />
								</Button>
							</Dropdown>
							<Divider type="vertical" />
							<Button
								icon={<DeleteOutlined />}
								danger
								style={{ marginLeft: 8, marginRight: 8 }}
								onClick={() => {
									(localSaleStore as any).removeSale(sale);
								}}
							>
								Storno
							</Button>
							<Button
								onClick={() => {
									this.setState(() => ({
										paymentModalVisible: true,
									}));
								}}
								icon={<CheckOutlined />}
								type="primary"
								style={{ marginRight: 8 }}
								disabled={sale.total <= 0}
							>
								Plaćanje
							</Button>

						</div>

						<div style={{ clear: 'both' }} />
					</div>
				</div>
				<ConfigProvider renderEmpty={() => <Empty 
					image={<NewERPIcon type="erpa-010-barcode"/>}
					description="Nema proizvoda. Koristite bar kod čitač ili unesite šifru." />}>
					<Table
						size="small"
						columns={columns}
						dataSource={loading ? [] : sale.itemsAsArray}
						bordered
						scroll={{ y: height - 302 }}
						pagination={false}
						rowKey="id"
						className="posTable"
					/>
				</ConfigProvider>
				{paymentModalVisible && (
					<PaymentModal
						visible={paymentModalVisible}
						amount={sale.total}
						sale={sale}
						prefillPayment={prefillPayment as [any, any, any]}
						closePayment={() => {
							this.setState(() => ({
								paymentModalVisible: false,
							}));
						}}
					/>
				)}
				{editModalVisible && (
					<EditModal
						visible={editModalVisible}
						saleItem={editSaleItem}
						sale={sale}
						closeEdit={() => {
							this.setState(() => ({
								editModalVisible: false,
							}));
						}}
					/>
				)}
				<Search
					closeSearch={() => {
						this.setState(() => ({
							searchVisible: false,
						}));
					}}
					onRowClick={record => {
						this.addBySku(record.sku);
					}}
					visible={searchVisible}
					sale={sale}
				/>
				<ProductNotFound 
					visible={notFoundModalVisible}
					text={notFoundModalText}
					onCancel={() => {
						this.setState((state) => ({
							...state,
							notFoundModalVisible: false,
						}))
					}}
					onOk={() => {
						this.setState((state) => ({
							...state,
							notFoundModalVisible: false,
						}))
					}}
				/>
			</div>
		);
	}
}

export default Sale;
