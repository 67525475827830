import React, { Component, SyntheticEvent, FocusEvent } from 'react';
import { inject, observer } from 'mobx-react';
import Declaration from './Declaration';

type Props = any;

export default class Declarations extends Component<Props> {
	render() {
		const { record } = this.props;
		return (
			<>
				{Array.from(Array(record.quantity)).map(() => (
					<Declaration
						record={record}
					/>
				))}
			</>
		);
	}
}
