import crudActions from './crudActions';


export const { fetchPricechanges, createPricechange, updatePricechange, deletePricechange } = crudActions('pricechange');

export function createItem(pricechangeItem) {
	return {
		type: 'PRICECHANGE_CREATE_ITEM',
		payload: {
			request: {
				url: `/pricechanges/${pricechangeItem.pricechangeId}/items`,
				method: 'post',
				data: pricechangeItem,
			},
		},
	};
}
export function updateItem(pricechangeItem) {
	return {
		type: 'PRICECHANGE_UPDATE_ITEM',
		payload: {
			request: {
				url: `/pricechanges/${pricechangeItem.pricechangeId}/items/${pricechangeItem.id}`,
				method: 'put',
				data: pricechangeItem,
			},
		},
	};
}

export function deleteItem(pricechangeItem) {
	return (dispatch) => {
		if (pricechangeItem.id) {
			return dispatch({
				type: 'PRICECHANGE_DELETE_ITEM',
				payload: {
					request: {
						url: `/pricechanges/${pricechangeItem.pricechangeId}/items/${pricechangeItem.id}`,
						method: 'delete',
						data: pricechangeItem,
					},
				},
			});
		}

		return dispatch({
			type: 'PRICECHANGE_DELETE_ITEM_SUCCESS',
			payload: pricechangeItem,
		});
	};
}


export function processPricechange(pricechange) {
	return {
		type: 'PRICECHANGE_PROCESS',
		payload: {
			request: {
				url: `/pricechanges/${pricechange.id}/process`,
				method: 'put',
			},
		},
	};
}
