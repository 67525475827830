import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Spin, Row, Modal, Button, Input, Select, DatePicker } from 'antd';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _ from 'lodash';

import { fetchStores } from '../../../../../actions/stores';
import { importInventoryItems } from '../../../../../actions/inventoryItems';

const FormItem = Form.Item;

class CreateInventoryItemModal extends Component {
	state = {
		selectedProduct: null,
		product: {
			sku: '',
			name: '',
			shortName: '',
			ean: '',
		},
		tax: '',
		price: '',
	}

	componentDidMount() {
		this.props.fetchStores();
	}

	getProperties = () => [
		{
			type: 'columns',
			key: 'productInfo',
			columns: [
				{
					span: 5,
					label: 'Prodavnica',
					property: 'storeId',
					rules: [{
						required: true, message: 'Prodavnica je obavezna',
					}],
					component: <Select
						showSearch
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{this.props.isLoadingStores ? <Select.Option className="bo-select-loading" key="loading" disabled><Spin /></Select.Option> : null}
						{this.props.stores.map(
							store => <Select.Option key={store.id} value={`${store.id}`}>{store.name}</Select.Option>
						)
						}
					</Select>,
				},
				{
					span: 3,
					label: 'Datum dodavanja',
					property: 'date',
					rules: [],
					initialValue: moment(),
					component: <DatePicker
						placeholder=""
						notFoundContent=""
					/>,
				},
			],
		},
		{
			type: 'columns',
			key: 'data',
			columns: [
				{
					span: 24,
					label: 'Podaci (TSV)',
					property: 'data',
					rules: [

					],
					component: <Input.TextArea
						autosize={{ minRows: 5, maxRows: 30 }}
					/>,
				},
			],
		},

	];


	handleChange = (values) => {
		const currentState = {
			...this.state,
		};

		Object.keys(values).forEach((key) => {
			_.set(currentState, key, values[key]);
		});

		this.setState(currentState);
	}


	handleCancel = () => {
		this.props.form.resetFields();
		this.props.handleCancel();
	}

	handleSave = () => {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				return this.props.importInventoryItems({
					storeId: values.storeId,
					date: values.date,
					data: values.data,
				})
					.then(() => this.props.handleSave())
					.then(() => this.props.form.resetFields());
			}

			return false;
		});
	}

	render() {
		if (!this.props.visible) {
			return null;
		}

		const { getFieldDecorator } = this.props.form;


		return (
			<Modal
				width={900}
				visible={this.props.visible}
				title="Uvoz inventara"
				onCancel={this.handleCancel}
				footer={[
					<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
					<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Uvezi
					</Button>,
				]}
			>
				<Form
					layout={this.props.formLayout}
				>
					{this.getProperties().map((property) => {
						switch (property.type) {
						case 'columns':
							return (
								<Row
									align="bottom"
									key={property.key}
									gutter={8}
								>
									{property.columns.map(column => (
										<Col
											span={column.span}
											key={column.property}
										>
											<FormItem
												labelCol={column.labelCol || { span: 24 }}
												wrapperCol={column.wrapperCol || { span: 24 }}
												label={column.label}
												colon={column.colon}
												{...this.props.formItemProps}
											>
												{getFieldDecorator(column.property, {
													rules: column.rules,
													initialValue: column.initialValue,
												})(
													column.component
												)}
											</FormItem>
										</Col>
									))}
								</Row>
							);
						default:
							return (
								<FormItem
									labelCol={property.labelCol || { span: 24 }}
									wrapperCol={property.wrapperCol || { span: 24 }}
									label={property.label}
									colon={property.colon}
									key={property.property}
									{...this.props.formItemProps}
								>
									{getFieldDecorator(property.property, {
										rules: property.rules,
										initialValue: property.initialValue,
									})(
										property.component
									)}
								</FormItem>
							);
						}
					})}
				</Form>
			</Modal>
		);
	}
}

CreateInventoryItemModal.propTypes = {
	formItemProps: PropTypes.shape({}),
	formLayout: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,
	isLoadingStores: PropTypes.bool.isRequired,

	fetchStores: PropTypes.func.isRequired,
	fetchProducts: PropTypes.func.isRequired,
	importInventoryItems: PropTypes.func.isRequired,

	stores: PropTypes.arrayOf(
		PropTypes.shape({})
	).isRequired,
};

CreateInventoryItemModal.defaultProps = {
	width: 700,
	context: null,
	formItemProps: {},
	formLayout: 'horizontal',
	handleChange: null,
	handleSave: () => {},
	convert: {},
};

function mapStateToProps(state) {
	return {
		isLoadingStores: state.stores.isLoading,
		isCreating: state.inventoryItems.isCreating,
		stores: state.stores.stores,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		importInventoryItems,
		fetchStores,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CreateInventoryItemModal));
