import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import DocumentTitle from 'react-document-title';

import PropTypes from 'prop-types';
import Layout from 'antd/lib/layout';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Input, Button, Checkbox, Alert } from 'antd';

import {
	withRouter, Route, Switch, Link, Redirect,
} from 'react-router-dom';

import * as styles from './Login.module.less';

const { Content, Footer } = Layout;
const { Password } = Input;

const FormItem = Form.Item;

type Props = {
	form: {
		getFieldDecorator: Function,
		validateFields: Function,
	},
	user: {
		token: String,
		isLoading: boolean,
		error: String,
		login: Function
	}
}

function Login(props: Props) {
	const {
		form: {
			getFieldDecorator,
			validateFields,
		},
		user: {
			isLoading,
			login,
			error,
			token,
		},
	} = props;

	function handleSubmit(event) {
		event.preventDefault();
		validateFields((err, values) => {
			if (!err) {
				login(values.email, values.password);
			}
		});
	}

	return (
        <DocumentTitle
			title="Pet Shop Moja Mačkica - Prijavljivanje"
		>
			<div className={styles.container}>
				{token && <Redirect to="/app" />}
				<div className={styles.content}>
					<div className={styles.top}>
						<div className={styles.header}>
							<Link to="/">
								<span className={styles.title}>Pet Shop Moja Mačkica</span>
							</Link>
						</div>
					</div>
					<Form onSubmit={handleSubmit} className={styles.form}>
						<FormItem>
							{error && <Alert type="error" message={error} />}
						</FormItem>
						<FormItem>
							{getFieldDecorator('email', {
								rules: [
									{ required: true, message: 'Unesite E-Mail!' },
									{ type: 'email', message: 'E-Mail Adresa je neispravna!' },
								],
							})(
								<Input size="large" prefix={<MailOutlined />} placeholder="E-Mail" />
							)}
						</FormItem>
						<FormItem>
							{getFieldDecorator('password', {
								rules: [{ required: true, message: 'Unesite lozinku!' }],
							})(
								<Password size="large" prefix={<LockOutlined />} type="password" placeholder="Lozinka" />
							)}
						</FormItem>
						<FormItem>
							<Button size="large" loading={isLoading} type="primary" htmlType="submit" block>Prijavi se</Button>
						</FormItem>
					</Form>
				</div>
			</div>
		</DocumentTitle>
    );
}


export default Form.create()(
	inject(({ store: { userStore: user } }) => ({
		user,
	}))(
		observer(
			Login
		)

	)
);
// class Login extends Component {
// 	constructor(props) {
// 		super(props);

// 		this.handleSubmit = this.handleSubmit.bind(this);
// 	}

// 	handleSubmit(e) {
// 		// this.props.dispatch(login('ads', 'asd'));
// 		e.preventDefault();
// 		this.props.form.validateFields((err, values) => {
// 			if (!err) {
// 				this.props.dispatch(login(values.email, values.password));
// 			}
// 		});
// 		// return false;
// 	}

// 	render() {
// 		const { getFieldDecorator } = this.props.form;
// 		return (
// 			<Layout className="bo-login">
// 				<Content className="bo-login-content">
// 					<Card
// 						bordered={false}
// 						className="bo-login-card"
// 					>
// 						<Form onSubmit={this.handleSubmit} className="login-form">
// 							<FormItem>
// 								{getFieldDecorator('email', {
// 									rules: [{ required: true, message: 'Unesite E-Mail!' }],
// 								})(
// 									<Input prefix={<Icon type="mail" style={{ fontSize: 13 }} />} placeholder="E-Mail" />
// 								)}
// 							</FormItem>
// 							<FormItem>
// 								{getFieldDecorator('password', {
// 									rules: [{ required: true, message: 'Unesite lozinku!' }],
// 								})(
// 									<Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" placeholder="Lozinka" />
// 								)}
// 							</FormItem>
// 							<FormItem>
// 								{getFieldDecorator('remember', {
// 									valuePropName: 'checked',
// 									initialValue: true,
// 								})(
// 									<Checkbox className="bo-login-remember">Zapamti me</Checkbox>
// 								)}
// 								<a className="bo-login-forgot" href="">Zaboravio/la sam lozinku</a>

// 								<Button loading={this.props.isLoading} type="primary" htmlType="submit" className="bo-login-button">Prijavi se</Button>
// 								Ili
// 								<a href="">se učlani!</a>
// 							</FormItem>
// 						</Form>
// 					</Card>
// 				</Content>

// 				<Footer className="bo-login-footer">Copyright &copy; 2017 Pet Shop Moja Mačkica</Footer>
// 			</Layout>
// 		);
// 	}
// }

// Login.propTypes = {
// 	dispatch: PropTypes.func.isRequired,
// 	isLoading: PropTypes.bool.isRequired,
// };

// function mapStateToProps(state) {
// 	return {
// 		isLoading: state.auth.isLoading,
// 	};
// }

// export default connect(mapStateToProps)(Form.create()(Login));
