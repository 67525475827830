import React from 'react';
import {
	Input, Select, Button, notification, Progress,
} from 'antd';
import { connect } from 'react-redux';
import diacritics from 'diacritics';
import floor from 'lodash/floor';
import {
	fetchProducts, createProduct, deleteProduct, updateProduct,
} from '../../../../actions/products';
import CrudPage from '../../../../components/CrudPage';


function Products(props) {
	return (
		<CrudPage
			headerContents={(
				<span style={{ marginLeft: 8 }}>
					<Button
						onClick={async () => {
							const keys = Object.keys(props.productsById);
							notification.info({
								key: 'import',
								message: 'Uvoz je u toku',
								description: <span><Progress percent={0} status="active" /></span>,
								duration: 0,
							});

							for (let i = 0; i < keys.length; i += 1) {
								const product = props.productsById[keys[i]];

								if (product.import) {
									notification.info({
										key: 'import',
										message: 'Uvoz je u toku',
										description: <span><Progress percent={Math.floor((i / keys.length) * 100)} status="active" /></span>,
										duration: 0,
									});
									await props.createProduct({
										sku: product.sku,
										name: product.name,
										shortName: diacritics.remove(product.shortName),
										barcodes: product.barcodes
											.map((barcode => barcode.barcode)),
										tax: product.tax,
									});
								}

								notification.success({
									key: 'import',
									message: 'Uvoz je završen',
									description: <span><Progress percent={100} /></span>,
									duration: 5,
								});
							}
						}}
					>
						Uvezi sa Google Drive
					</Button>
				</span>
			)
			}
			fetchAction={fetchProducts}
			createAction={createProduct}
			deleteAction={deleteProduct}
			updateAction={updateProduct}
			createTitle="Dodaj Proizvod"
			editTitle="Izmeni Proizvod"
			addButtonText="Dodaj Proizvod"
			deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovaj proizvod?"
			stateProperty="products"
			columns={[
				{
					title: 'Šifra',
					dataIndex: 'sku',
					key: 'sku',
					sorter: (a, b) => {
						if (a.sku < b.sku) return -1;
						if (a.sku > b.sku) return 1;
						return 0;
					},
				},
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
					sorter: (a, b) => {
						if (a.name < b.name) return -1;
						if (a.name > b.name) return 1;
						return 0;
					},
				},
				{
					title: 'Cena',
					dataIndex: 'price',
					key: 'price',
				},
			]}
			convert={{
				barcodes: barcodes => barcodes.map(barcode => barcode.barcode),
			}}
			properties={[
				{
					label: 'Šifra',
					property: 'sku',
					rules: [{
						required: true, message: 'Šifra je obavezan!',
					}],
					component: <Input />,
				},
				{
					label: 'Naziv',
					property: 'name',
					rules: [{
						required: true, message: 'Naziv proizvoda je obavezan!',
					}],
					component: <Input />,
				},
				{
					label: 'Skraćeni Naziv',
					property: 'shortName',
					rules: [
						{ max: 22, message: 'Skraćeni naziv ne sme biti duži od 22 karaktera' },
					],
					component: <Input />,
				},
				{
					label: 'Bar kod',
					property: 'barcodes',
					rules: [],
					component: <Select
						mode="tags"
						tokenSeparators={[',', ' ', ';']}
					/>,
				},
			]}
		/>
	);
}

function mapStateToProps(state) {
	return {
		productsById: state.search.productsById,
	};
}

const mapDispatchToProps = {
	createProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
