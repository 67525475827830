import React from 'react';
import { Input } from 'antd';

import {
	fetchBrands, createBrand, deleteBrand, updateBrand,
} from '../../../../actions/brands';
import CrudPage from '../../../../components/CrudPage';

function Brands() {
	return (
		<CrudPage
			fetchAction={fetchBrands}
			createAction={createBrand}
			deleteAction={deleteBrand}
			updateAction={updateBrand}
			createTitle="Dodaj Brend"
			editTitle="Izmeni Brend"
			addButtonText="Dodaj Brend"
			deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovaj brend?"
			stateProperty="brands"
			columns={[
				{
					title: 'Naziv',
					dataIndex: 'name',
					key: 'name',
				},
			]}
			properties={[
				{
					label: 'Naziv',
					property: 'name',
					rules: [{
						required: true, message: 'Naziv brenda je obavezan!',
					}],
					component: <Input />,
				},
			]}
		/>
	);
}

export default Brands;
