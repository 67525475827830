// @flow
import {
	types, flow, getRoot,
} from 'mobx-state-tree';

import moment from 'moment';

import keyBy from 'lodash/keyBy';

import { v2Client } from '../store/client';

import { SaleItem } from './SaleItem';
import { Delivery } from './Delivery';


export const Sale = types
	.model('Sale', {
		id: types.identifierNumber,
		timestamp: types.string,
		paidTotal: types.maybeNull(types.number),
		paidCash: types.maybeNull(types.number),
		paidCard: types.maybeNull(types.number),
		paidCheck: types.maybeNull(types.number),
		tax: types.maybeNull(types.number),
		saleItems: types.array(SaleItem),
		fiscalReceiptNumber: types.maybeNull(types.number),
		delivery: types.maybeNull(types.reference(Delivery, {
			get(identifier, store) {
				const deliveryStore = (getRoot(store) as any).deliveryStore;
				return deliveryStore.mappedById[identifier];
			},
			set(value) {
				return value.id
			}
		})),
		deliveryId: types.maybeNull(types.number),
	})
	.preProcessSnapshot(
		snapshot => {
			snapshot.delivery = snapshot.deliveryId;
			return snapshot;
		}
	)
	.actions(self => {
		const actions = {
		};

		return actions;
	})
	.views(self => ({

	}));

export const SaleStore = types
	.model('Salestore', {
		fetched: types.optional(types.boolean, false),
		loading: types.optional(types.boolean, false),
		cache: types.optional(types.boolean, true),
		// currentStartDate: types.optional(types.number, 1),
	})
	.actions(self => {
		const actions = {

		};

		return actions;
	})
	.views(self => {
		const views = {

		};

		return views;
	});
