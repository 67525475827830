import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Select, Breadcrumb, Row, Col, Table } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { fetchStores } from '../../../actions/stores';
import { fetchLedger } from '../../../actions/ledger';

const { Content, Header } = Layout;

class Ledger extends Component {
	constructor(props) {
		super(props);

		this.columns = [
			{
				title: '#',
				key: 'index',
				width: '100px',
				render(text, record, index) {
					return index + 1;
				},
			},
			{
				title: 'Datum evidencije',
				dataIndex: 'date',
				key: 'date',
				render: (text, record) => {
					if (record.type === 'previous' || record.type === 'next') {
						return '';
					}

					return moment(text).format('L');
				},
				width: '150px',
			},
			{
				title: 'Opis evidentirane promene',
				colSpan: 2,
				key: 'description',
				render: (text, record) => {
					const obj = {
						children: null,
						props: {
						},
					};
					if (record.type === 'previous') {
						obj.children = 'Donos';
					}

					if (record.type === 'next') {
						obj.children = 'Prenos';
						obj.props.colSpan = 0;
					}

					if (record.type === 'initial') {
						obj.children = 'Početno stanje';
					}

					if (record.type === 'calculation') {
						obj.children = `Kalkulacija ${record.calculation.number} od ${moment(record.calculation.date).format('L')}, Dobavljač: ${record.calculation.invoice.distributor.name}`;
					}

					if (record.type === 'pricechange') {
						obj.children = `Nivelacija ${record.pricechange.number} od ${moment(record.pricechange.date).format('L')}`;
					}

					return obj;
				},
			},
			{
				title: 'Total',
				colSpan: 0,
				key: 'total',
				dataIndex: 'total',
				render: (text, record) => {
					const obj = {
						children: null,
						props: {
						},
					};
					if (record.type === 'next') {
						obj.children = 123;
						obj.props.colSpan = 0;
					}

					return obj;
				},
			},
			{
				title: 'Zaduženje',
				dataIndex: 'in',
				key: 'in',
			},
			{
				title: 'Razduženje',
				dataIndex: 'out',
				key: 'out',
			},
		];
	}

	componentDidMount() {
		this.props.fetchStores();
		this.props.fetchLedger();
	}
	// TODO: Move header to another component
	render() {
		return (<div>
			<Header>
				<Row>
					<Col>
						<Breadcrumb
							routes={[
								{
									breadcrumbName: 'Početna',
									path: '/',
								},
								{
									breadcrumbName: 'KEP Knjiga',
								},
							]}
						/>
						<h2>KEP Knjiga</h2>
					</Col>
				</Row>
				<Row>
					{/* <Tabs>
						{this.props.stores.map(store => <Tabs.TabPane key={store.id} tab={store.name} />)}
					</Tabs> */}
				</Row>
			</Header>

			<Content>
				<Table
					loading={this.props.isLoading}
					rowKey="id"
					columns={this.columns}
					dataSource={this.props.ledger}
				/>
			</Content>
		</div>);
	}
}

// breadcrumbList = {
// [
// 	{
// 		title: 'Početna',
// 		href: '/',
// 	},
// 	{
// 		title: 'KEP Knjiga',
// 	},
// 				]}
// tabList = {
// 	this.props.stores.map(store => ({ key: store.id, tab: store.name }))
// }
// title = "KEP Knjiga"
// logo = {<Icon size= { 28} icon= { IconBook } />}

Ledger.propTypes = {
	stores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	ledger: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	fetchStores: PropTypes.func.isRequired,
	fetchLedger: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
	return {
		stores: state.stores.stores,
		ledger: state.ledger.ledger,
		isLoading: state.ledger.isLoading || state.stores.isLoading,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchStores,
		fetchLedger,
	}, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Ledger);
