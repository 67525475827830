import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import SocketIo from 'socket.io-client';
import { PlusOutlined, ExceptionOutlined } from '@ant-design/icons';
import {
	Layout, List, Row, Col, Table, Card, Button, notification, Input, Popover, PageHeader, Tabs, Alert, Divider, Anchor, message, Spin,
} from 'antd';

// import { PageHeader } from 'ant-design-pro';

import Sale from './sale';
import Key from '../../../components/Key';

import * as styles from './POS.module.less';

import config from '../../../config.json';

const { Content } = Layout;
const { Item } = List;
const { Link } = Anchor;

type Props = {
	store: any,
	loading: boolean,
};

type State = {
	height: number,
	socketError?: Function,
};

@inject(({ store }) => ({
	store,
	loading: store.productStore.loading,
}))
@observer
class POS extends Component<Props, State> {
	state = {
		height: 0,
		socketError: null,
	};

	socketError: Function | null = null;

	UNSAFE_componentWillMount = () => {
		message.config({
			top: 10,
		});
		this.socket = new SocketIo(config.ecrservice);

		this.socket.on('connect', socket => {
			if (this.state.socketError) {
				this.state.socketError();
				this.setState({
					socketError: null,
				});
				message.success('Pozadinski servis je ponovo dostupan ', 5);
			}
		});

		this.socket.on('error', () => {
			if (!this.state.socketError) {
				this.setState({
					socketError: message.warning('Upozorenje: Pozadinski servis nije dostupan, pozovite podršku! ', 0),
				});
			}
		});
		this.socket.on('connect_error', () => {
			if (!this.state.socketError) {
				this.setState({
					socketError: message.warning('Upozorenje: Pozadinski servis nije dostupan, pozovite podršku! ', 0),
				});
			}
		});
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);

		this.updateDimensions();
		// this.props.store.productStore.fetchProducts();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);

		this.socket.close();
	}

	updateDimensions = () => {
		this.setState({ height: window.innerHeight });
	};

	socket: SocketIo;

	renderHeader = () => {
		const { store: { localSaleStore } } = this.props;

		return (
			<>
				<Button
					onClick={() => {
						localSaleStore.ecrUnblock();
					}}
					icon={<ExceptionOutlined />}
					className={styles.unblockButton}
				>
					Odblokiraj Kasu
				</Button>
				<Button
					onClick={() => {
						localSaleStore.createSale();
					}}
					icon={<PlusOutlined />}
					className={styles.addButton}
				>
					Dodaj Račun
				</Button>
			</>
		);
	};

	render() {
		const { store: { localSaleStore }, loading } = this.props;
		const { height } = this.state;
		return (
			<div

				className={styles.container}
			>
				<Spin
					spinning={loading}
				>
					<Content
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
						}}
						id="posContent"
					>

						<Tabs
							className={styles.tabs}
							activeKey={`${localSaleStore.active}`}
							tabPosition="top"
							type="line"
							onChange={key => {
								localSaleStore.setActive(parseInt(key, 10));
							}}
							animated
							tabBarGutter={8}
						>
							{localSaleStore.sales.map(sale => (
								<Tabs.TabPane
									style={{ padding: '24px 24px 0' }}
									key={sale.id}
									tab={`Prodaja ${sale.id}`}
								>
									<Sale
										socket={this.socket}
										height={height}
										sale={sale}
										loading={loading}
										active={sale.id === localSaleStore.active}
									/>
								</Tabs.TabPane>
							))}
						</Tabs>

						<div className={styles.keys}>
							<Key text="Unos šifre">0-9</Key>
							<Divider type="vertical" />
							<Key text="PLU">enter</Key>
							<Divider type="vertical" />
							<Key text="Odabir artikla">↑/↓</Key>
							<Divider type="vertical" />
							<Key text="Storno">⌫</Key>
							<Divider type="vertical" />
							<Key text="Promena količine">+</Key>
							<Divider type="vertical" />
							<Key text="Total">F16</Key>
							<Divider type="vertical" />
							<Key text="Ček">F17</Key>
							<Divider type="vertical" />
							<Key text="Kartica">F18</Key>
							<Divider type="vertical" />
							<Key text="">⌘ command</Key>
							<Key text="Pretraga">f</Key>


						</div>
					</Content>

				</Spin>
			</div>

		);
	}
}


export default POS;
