import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CloudOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Col,
    Row,
    Modal,
    Button,
    AutoComplete,
    Input,
    Select,
    DatePicker,
    Alert,
    Drawer,
    Layout,
    Menu,
    Table,
} from 'antd';
import { bindActionCreators } from 'redux';
import _, { uniqBy, sortBy } from 'lodash';
import moment from 'moment';

import {
	createProduct, fetchProducts, updateProduct, productsImport,
} from '../../../../../actions/products';
import { createItem, updateItem } from '../../../../../actions/calculations';

const FormItem = Form.Item;

const { Search } = Input;
const { Sider, Content } = Layout;
const { SubMenu } = Menu;

class CreateCalculationItemModal extends Component {
	constructor(props) {
		super(props);

		this.handleCancel = this.handleCancel.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	state = {
		selectedProduct: null,
		product: {
			sku: '',
			name: '',
			shortName: '',
			ean: '',
		},
		originalSku: '',
		calculatedPrice: '',
		difference: '',
		finalPriceNoTax: '',
		taxAmount: '',
		tax: '',
		price: '',
		margin: 0,
		rebates: [],
		priceWithRebate: '',

		search: {
			sku: '',
			name: '',
			shortName: '',
			ean: '',
			results: [],
		},
	}

	componentDidMount() {
		this.props.fetchProducts()
			.then(() => this.props.productsImport());
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!this.props.entity && props.entity) {
			this.selectProduct(props.entity.product, () => {
				this.handleChange({
					...props.entity,
				});
			});
		}
	}

	getProperties = () => [
		...(this.state.selectedProduct ? [{
			type: 'columns',
			key: 'selectedProduct',
			columns: [
				{
					span: 24,
					property: 'selectedProductInfo',
					component: <Alert
						message={`Izabran je proizvod ${this.state.selectedProduct.sku} - ${this.state.selectedProduct.name}.`}
						banner
						type="info"
						closeText="Ukloni"
						style={{ margin: -24 }}
						showIcon={false}
						onClose={() => {
							this.resetProduct();
						}}
					/>,
				},
			],
		}] : []),
		// {
		// 	type: 'columns',
		// 	key: 'import',
		// 	columns: [
		// 		{
		// 			span: 24,
		// 			property: 'import',
		// 			component: <Input
		// 				onChange={(event) => {
		// 					const values = event.target.value.split('\t');
		// 					this.props.form.setFieldsValue({
		// 						'product.sku': values[0],
		// 						'product.name': values[2],
		// 						'product.shortName': values[9],
		// 						'product.ean': values[11],
		// 					});
		// 				}}
		// 			/>,
		// 			rules: [],
		// 			label: 'Uvoz podataka',
		// 		},
		// 	],
		// },
		{
			title: 'Osnovne Informacije:',
			type: 'columns',
			key: 'productInfo',
			columns: [
				{
					span: 5,
					label: 'Šifra',
					property: 'product.sku',
					rules: [
						{ required: true, message: 'Šifra je obavezna' },
					],
					// component: <AutoComplete
					// 	defaultActiveFirstOption={false}
					// 	filterOption={false}
					// 	onSearch={this.handleSearchBySku}
					// 	onSelect={(val, options) => {
					// 		this.selectProduct(options.props.data);
					// 	}}
					// 	dropdownMatchSelectWidth={false}
					// 	disabled={!!this.state.selectedProduct}
					// 	dataSource={this.searchBySkuResults.map(d => <AutoComplete.Option key={`product.sku.autocomplete.${d.sku}`} data={d}>{`${d.sku} - ${d.name}`}</AutoComplete.Option>)}
					// >
					// 	<Input />
					// </AutoComplete>,
					component: <Search
						onSearch={() => {
							this.setState(() => ({
								childrenDrawer: true,
							}));
						}}
						enterButton
					/>,
				},
				{
					span: 5,
					label: 'Šif. distrib.',
					property: 'originalSku',
					rules: [],
					component: <Input />,
				},
				{
					span: 8,
					label: 'Naziv',
					property: 'product.name',
					rules: [
						{ required: true, message: 'Naziv je obavezan' },
					],
					component: <Input />,
				},
				{
					span: 6,
					label: 'Skraćeni naziv',
					property: 'product.shortName',
					rules: [
						{ max: 22, message: 'Skraćeni naziv ne sme biti duži od 22 karaktera' },
					],
					component: <Input
						disabled={!!this.state.selectedProduct}
					/>,
				},

			],
		},
		{
			title: 'Informacije sa Fakture:',
			type: 'columns',
			key: 'priceInfo',
			columns: [
				{
					span: 4,
					label: 'Cena (sa fakture)',
					property: 'invoicePrice',
					rules: [
						{ required: true, message: 'Cena je obavezna' },
					],
					component: <Input
						type="number"
						addonAfter="RSD"
						onChange={event => this.handleChange({ invoicePrice: event.target.value })}
					/>,
				},
				{
					span: 4,
					label: 'Količina (sa fakture)',
					property: 'invoiceQuantity',
					rules: [
						{ required: true, message: 'Količina je obavezna' },
					],
					component: <Input
						type="number"
						onChange={event => this.handleChange({ invoiceQuantity: event.target.value })}
					/>,
				},
				{
					span: 4,
					label: 'Količina u pakovanju',
					property: 'quantityPerPack',
					rules: [],
					initialValue: 1,
					component: <Input
						type="number"
						onChange={event => this.handleChange({ quantityPerPack: event.target.value })}

					/>,
				},
				{
					span: 4,
					label: 'Ulazna cena',
					property: 'price',
					rules: [
						{ required: true, message: 'Ulazna cena je obavezna' },
					],
					component: <Input
						type="number"
						addonAfter="RSD"
						disabled
					/>,
				},
				{
					span: 4,
					label: 'Ulazna količina',
					property: 'quantity',
					rules: [
						{ required: true, message: 'Ulazna količina je obavezna' },
					],
					component: <Input
						disabled
						type="number"
					/>,
				},
				{
					span: 2,
					label: 'JM',
					property: 'unit',
					rules: [
						{ required: true, message: 'Jedinica mere je obavezna' },
					],
					component: <Select
						showSearch
						showArrow={false}
						dropdownMatchSelectWidth={false}
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						<Select.Option value="kg">kg</Select.Option>
						<Select.Option value="kom">kom</Select.Option>
						<Select.Option value="l">l</Select.Option>
					</Select>,
				},
				{
					span: 2,
					label: 'PDV',
					property: 'tax',
					rules: [
						{ required: true, message: 'PDV je obavezan' },
					],
					component: <Input
						addonAfter="%"
						onChange={event => this.handleChange({ tax: event.target.value })}
					/>,
				},

			],
		},
		{
			title: 'Rabati:',
			type: 'columns',
			key: 'rebates',
			columns: [
				{
					span: 5,
					label: 'Rabat 1',
					property: 'rebates.0',
					rules: [],
					component: <Input
						addonAfter="%"
						onChange={event => this.handleChange({ 'rebates.0': event.target.value })}
					/>,
				},
				{
					span: 5,
					label: 'Rabat 2',
					property: 'rebates.1',
					rules: [],
					component: <Input
						addonAfter="%"
						onChange={event => this.handleChange({ 'rebates.1': event.target.value })}
					/>,
					colon: false,
				},
				{
					span: 5,
					label: 'Rabat 3',
					property: 'rebates.2',
					rules: [],
					component: <Input
						addonAfter="%"
						onChange={event => this.handleChange({ 'rebates.2': event.target.value })}
					/>,
					colon: false,
				},
			],
		},
		{
			title: 'Bar Kodovi:',
			type: 'columns',
			key: 'eans',
			columns: [
				{
					span: 24,
					label: 'Bar kodovi (razvojiti razmakom, "," ili ";")',
					property: 'product.ean',
					rules: [

					],
					component: <Select
						mode="tags"
						tokenSeparators={[',', ' ', ';']}
					/>,
				},
			],
		},
		{
			title: 'Ostale Informacije:',
			type: 'columns',
			key: 'other',
			columns: [
				{
					span: 5,
					label: 'Datum isteka',
					property: 'expirationDate',
					rules: [],
					component: <DatePicker
						placeholder=""
						notFoundContent=""
					/>,
				},
			],
		},


		{
			type: 'columns',
			key: 'priceTotal',
			title: 'Obračun Cene:',
			columns: [
				{
					span: 3,
					label: 'Marža',
					property: 'margin',
					rules: [],
					component: <Input
						addonAfter="%"
						onChange={event => this.handleChange({ margin: event.target.value })}
					/>,
				},
				{
					span: 4,
					label: 'Preračunata cena',
					property: 'calculatedPrice',
					rules: [],
					component: <div><Input
						type="number"
						disabled
						value={`${this.state.calculatedPrice}`}
						addonAfter="RSD"
					/>
					</div>,
				},
				{
					span: 4,
					label: 'Razlika u ceni',
					property: 'difference',
					rules: [],
					component: <div><Input
						type="number"
						disabled
						value={this.state.difference}
						addonAfter="RSD"
					/>
					</div>,
				},
				{
					span: 4,
					label: 'Prodajna cena b.p.',
					property: 'finalPriceNoTax',
					rules: [],
					component: <div><Input
						type="number"
						disabled
						value={this.state.finalPriceNoTax}
						addonAfter="RSD"
					/>
					</div>,
				},
				{
					span: 4,
					label: 'Iznos poreza',
					property: 'taxAmount',
					rules: [],
					component: <div><Input
						type="number"
						disabled
						value={this.state.taxAmount}
						addonAfter="RSD"
					/>
					</div>,
				},
				{
					span: 5,
					label: 'Konačna cena',
					property: 'finalPrice',
					rules: [],
					component: <Input
						type="number"
						addonAfter="RSD"
						placeholder={(Math.round(this.state.calculatedPrice / 5) * 5) - 0.01}
						onChange={event => this.handleChange({ finalPrice: event.target.value })}
					/>,
				},

			],
		},

	];

	selectProduct = (product, callback = () => {}) => {
		if (!product) {
			return false;
		}

		return this.setState({
			selectedProduct: !product.import ? product : null,
		}, () => {
			const ean = product ? product.barcodes.map(barcode => barcode.barcode) : [];
			let lastCalculationItem;

			if (this.props.entity) {
				lastCalculationItem = this.props.entity;
			} else if (product && product.calculationItems) {
				lastCalculationItem = _.last(product.calculationItems);
			}
			setTimeout(() => {
				this.handleChange({
					'product.sku': `${product.sku}`,
					'product.name': product.name,
					'product.shortName': product.shortName,
					...(product.barcodes.length > 0 ? { 'product.ean': ean } : {}),
					finalPrice: product.import ? product.price : '',
					...(lastCalculationItem ? {
						originalSku: lastCalculationItem.originalSku,
						invoicePrice: lastCalculationItem.invoicePrice,
						price: lastCalculationItem.price,
						quantityPerPack: lastCalculationItem.quantityPerPack,
						'rebates.0': lastCalculationItem.rebates[0] || 0,
						'rebates.1': lastCalculationItem.rebates[1] || 0,
						'rebates.2': lastCalculationItem.rebates[2] || 0,
						tax: lastCalculationItem.tax,
						margin: lastCalculationItem.margin,
						finalPrice: lastCalculationItem.finalPrice,
						unit: lastCalculationItem.unit,
					} : {}),
				});

				callback();
			});
		});
	};

	resetProduct = () => {
		this.setState({
			selectedProduct: null,
		}, () => {
			this.props.form.resetFields();
		});
	}

	searchBySkuResults = [];

	searchByNameResults = [];


	handleSearch = (field, value) => {
		this.setState(() => ({
			search: {
				[field]: value,
			},
		}), () => {
			const sku = this.state.search.sku ? this.props.searchProductsBySku.search(this.state.search.sku) : [];
			const name = this.state.search.name ? this.props.searchProductsByName.search(this.state.search.name) : [];
			const shortName = this.state.search.shortName ? this.props.searchProductsByShortName.search(this.state.search.shortName) : [];
			const ean = this.state.search.ean ? this.props.searchProductsByEan.search(this.state.search.ean) : [];
			const sortedUniq = uniqBy(
				[...sku, ...name, ...shortName, ...ean],
				 item => item.sku
			);


			this.setState(() => ({
				search: {
					results: sortedUniq,
				},
			}));
		});
	}

	handleSearchBySku = (value) => {
		this.searchBySkuResults = this.props.searchProductsBySku.search(value);
	}

	handleSearchByName = (value) => {
		this.searchByNameResults = this.props.searchProductsByName.search(value);
		console.log(this.searchByNameResults);
	}

	handleChange = (values) => {
		this.props.form.setFieldsValue({
			..._.deepMap(_.pick(
				values,
				'product.sku', 'originalSku', 'product.name', 'product.shortName',
				'invoicePrice', 'invoiceQuantity', 'quantityPerPack', 'price', 'quantity',
				'unit', 'tax', 'product.ean',
				'expirationDate', 'margin', 'finalPrice', 'rebates.0', 'rebates.1', 'rebates.2'
			), value => value || undefined),
		});


		this.props.form.setFieldsValue({
			// 'product.sku': values['product.sku'],
			// originalSku: values.originalSku,

			price: this.props.form.getFieldValue('invoicePrice') / this.props.form.getFieldValue('quantityPerPack'),
			quantity: this.props.form.getFieldValue('invoiceQuantity') * this.props.form.getFieldValue('quantityPerPack'),
		});
		const rebates = _.values(this.props.form.getFieldValue('rebates'));

		const price = this.props.form.getFieldValue('price');
		const tax = this.props.form.getFieldValue('tax');
		const margin = this.props.form.getFieldValue('margin');
		const finalPrice = this.props.form.getFieldValue('finalPrice');


		const currentState = {};

		currentState.priceWithRebate = rebates.reduce((prev, curr) => prev - (prev * ((curr || 0) / 100)), price || 0);
		currentState.calculatedPrice = currentState.priceWithRebate * (1 + ((tax || 0) / 100)) * (1 + ((margin || 0) / 100));
		currentState.finalPriceNoTax = (finalPrice || 0) / (1 + ((tax || 0) / 100));
		currentState.difference = currentState.finalPriceNoTax - (currentState.priceWithRebate || 0);
		currentState.taxAmount = _.round((finalPrice || 0) - currentState.finalPriceNoTax, 2);
		this.setState(currentState);
	}


	handleCancel() {
		this.resetProduct();
		this.props.handleCancel();
	}

	handleSave() {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const productFunc = this.state.selectedProduct ? this.props.updateProduct : this.props.createProduct;
				const itemFunc = this.props.entity ? this.props.updateItem : this.props.createItem;

				const calculationId = this.props.calculation.id;
				return productFunc({
					...values.product,
					...(this.state.selectedProduct ? { id: this.state.selectedProduct.id } : {}),
				})
					.then((response) => {
						const product = response.payload.data;
						return itemFunc({
							calculationId,
							productId: product.id,
							price: values.price,
							invoicePrice: values.invoicePrice,
							quantityPerPack: values.quantityPerPack,
							quantity: values.quantity,
							invoiceQuantity: values.invoiceQuantity,
							unit: values.unit,
							tax: values.tax,
							rebates: values.rebates.filter(rebate => !!rebate),
							margin: values.margin,
							originalSku: values.originalSku,
							finalPrice: values.finalPrice,
							priceDifference: this.state.difference,
							priceWithRebate: this.state.priceWithRebate,
							finalPriceBeforeTax: this.state.finalPriceNoTax,
							taxAmount: this.state.taxAmount,
							expirationDate: values.expirationDate,
							...(this.props.entity ? { id: this.props.entity.id } : {}),
						});
					})
					.then(() => this.resetProduct())
					.then(() => this.props.handleSave())
					.then(() => this.props.form.resetFields());
			}

			return false;
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;


		return (
            <Drawer
				width={1200}
				visible={this.props.visible}
				title={this.props.entity ? 'Izmena stavke kalkulacije' : 'Dodavanje stavke kalkulacije'}
				onClose={this.handleCancel}
				closable
				footer={[
					<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>,
					<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Sačuvaj
					</Button>,
				]}
			>

				<Drawer
					title="Pretraga"
					width={1000}
					closable
					onClose={() => {
						this.setState(() => ({
							childrenDrawer: false,
						}));
					}}
					visible={this.state.childrenDrawer}
					style={{ padding: 0, height: '100%' }}
				>
					<Layout style={{ background: '#fff', height: '100%' }}>
						<Sider
							width={200}
							style={{
								background: '#fafafa', padding: 16, height: '100%', borderRight: '1px solid #f0f0f0',
							}}
						>
							<Form
								layout="vertical"
							>
								<Form.Item
									label="Šifra"
								>
									<Input
										onChange={event => this.handleSearch('sku', event.target.value)}
									/>
								</Form.Item>
								<Form.Item
									label="Naziv"
								>
									<Input
										onChange={event => this.handleSearch('name', event.target.value)}
									/>
								</Form.Item>
								<Form.Item
									label="Skraćeni Naziv"
								>
									<Input
										onChange={event => this.handleSearch('shortName', event.target.value)}
									/>
								</Form.Item>
								<Form.Item
									label="Bar Kod"
								>
									<Input
										onChange={event => this.handleSearch('ean', event.target.value)}
									/>
								</Form.Item>

							</Form>

						</Sider>
						<Content>

							<Table
								style={{ marginTop: -1, marginLeft: -1 }}
								dataSource={this.state.search.results}
								rowKey="id"
								onRow={record => ({
									onClick: () => {
										this.selectProduct(record);
										this.setState(() => ({
											childrenDrawer: false,
										}));
									},
								})}
								columns={[
									{
										title: 'Šifra',
										dataIndex: 'sku',
										key: 'sku',
										render: (text, record) => (
											<span>
												{`${record.id}`.includes('import') ? (
													<CloudOutlined />
												) : null} {text}
											</span>
										),
									},
									{
										title: 'Skraćeni Naziv',
										dataIndex: 'shortName',
										key: 'shortName',
									},
									{
										title: 'Naziv',
										dataIndex: 'name',
										key: 'name',
									},
									{
										title: 'Bar Kod',
										dataIndex: 'ean',
										key: 'ean',
										render: (text, record) => (record.barcodes
											? record.barcodes
												.map(barcode => barcode.barcode)
												.join(', ')
											: ''),
									},
								]}
							/>
						</Content>
					</Layout>
				</Drawer>
				<Form
					layout={this.props.formLayout}
				>
					{this.getProperties().map((property) => {
						switch (property.type) {
						case 'columns':
							return (
								<Row
									align="bottom"
									key={property.key}
									gutter={8}
								>
									{property.title ? <Col span={24}><h3>{property.title}</h3></Col> : null}
									{property.columns.map(column => (
										<Col
											span={column.span}
											key={column.property}
										>
											<FormItem
												labelCol={column.labelCol || { span: 24 }}
												wrapperCol={column.wrapperCol || { span: 24 }}
												label={column.label}
												colon={column.colon}
												{...this.props.formItemProps}
											>
												{getFieldDecorator(column.property, {
													rules: column.rules,
													initialValue: column.initialValue,
												})(
													column.component
												)}
											</FormItem>
										</Col>
))}
								</Row>
							);
						default:
							return (
								<FormItem
									labelCol={property.labelCol || { span: 24 }}
									wrapperCol={property.wrapperCol || { span: 24 }}
									label={property.label}
									colon={property.colon}
									key={property.property}
									{...this.props.formItemProps}
								>
									{getFieldDecorator(property.property, {
										rules: property.rules,
										initialValue: property.initialValue,
									})(
										property.component
									)}
								</FormItem>
							);
						}
					})}
				</Form>
				<div
					style={{
						position: 'absolute',
						bottom: 0,
						width: '100%',
						borderTop: '1px solid #e8e8e8',
						padding: '10px 16px',
						textAlign: 'right',
						left: 0,
						background: '#fff',
						borderRadius: '0 0 4px 4px',
					}}
				>
					<Button key="cancel" onClick={this.handleCancel}>Poništi</Button>
					<Button key="save" type="primary" loading={this.props.isCreating} onClick={this.handleSave}>
					Sačuvaj
					</Button>
				</div>

			</Drawer>
        );
	}
}

CreateCalculationItemModal.propTypes = {
	formItemProps: PropTypes.shape({}),
	formLayout: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,


	createItem: PropTypes.func.isRequired,
	updateItem: PropTypes.func.isRequired,
	createProduct: PropTypes.func.isRequired,
	fetchProducts: PropTypes.func.isRequired,
	updateProduct: PropTypes.func.isRequired,
	productsImport: PropTypes.func.isRequired,

	entity: PropTypes.shape({
		id: PropTypes.number,
		product: PropTypes.shape({}),
	}),

	calculation: PropTypes.shape({
		id: PropTypes.number,
	}),
	searchProductsBySku: PropTypes.func.isRequired,
	searchProductsByName: PropTypes.func.isRequired,
	searchProductsByShortName: PropTypes.func.isRequired,
	searchProductsByEan: PropTypes.func.isRequired,
	convert: PropTypes.shape({}),
};

CreateCalculationItemModal.defaultProps = {
	width: 700,
	context: null,
	formItemProps: {},
	entity: null,
	formLayout: 'horizontal',
	handleChange: null,
	handleSave: () => {},
	convert: {},
	calculation: null,
};

function mapStateToProps(state) {
	return {
		isCreating: state.calculations.isCreating,
		searchProductsBySku: state.search.productBySku,
		searchProductsByName: state.search.productByName,
		searchProductsByShortName: state.search.productByShortName,
		searchProductsByEan: state.search.productByEan,
		imported: state.products.import,
	};
}

function mapPropsToFields(props) {
	if (!props.entity) {
		return {};
	}

	return {
		..._.mapValues(props.entity, entity => (Form.createFormField({ value: entity }))),
		product: {
			..._.mapValues(props.entity.product, product => (Form.createFormField({ value: product }))),
			ean: Form.createFormField({ value: props.entity.product ? props.entity.product.barcodes.map(barcode => barcode.barcode) : [] }),
		},
		'rebates.0': Form.createFormField({ value: props.entity.rebates[0] }),
		'rebates.1': Form.createFormField({ value: props.entity.rebates[1] }),
		'rebates.2': Form.createFormField({ value: props.entity.rebates[2] }),
		expirationDate: Form.createFormField({ value: props.entity.expirationDate ? moment(props.entity.expirationDate) : null }),
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		createProduct,
		updateProduct,
		fetchProducts,
		createItem,
		updateItem,
		productsImport,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ mapPropsToFields })(CreateCalculationItemModal));
