import React, {useState, useCallback, useEffect} from 'react';
import {Button} from 'antd';
import upperFirst from 'lodash/upperFirst';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import styles from './Price.module.less';

type Props = {
	onChange?: (value: number) => void,
	value?: number,
	type: 'regular'|'small'|'big'|'discount',
	quantity: number,
};

export default function StickerChooser({onChange, value = 0, type, quantity}:Props):JSX.Element {
	const [hovered, setHovered] = useState(0);
	const [stickers, setStickers] = useState(Array(quantity).fill({selected: true, hovered: false}));
	const [start, setStart] = useState(null);

	const [isCmdPressed] = useKeyboardJs('command');

	console.log('isCmdPressed',isCmdPressed);
	useEffect(() => {
        onChange?.(value);
	}, []);

	useEffect(() => {
		setStickers(Array(quantity).fill({selected: true, hovered: false}));
	}, [quantity]);

	const onMouseEnter = useCallback((index) => {
		setHovered(index);
	}, []);

	const onMouseDown= useCallback((index) => {
		if (!isCmdPressed) {
			setStickers(Array(quantity).fill({selected: false, hovered: false}));
		}

		setStart(index);
		console.log('start on mouse down', start);

	}, [start]);
	const onMouseUp= useCallback((index) => {
		console.log('start', start);
		if (start !== null) {
			// set stickers selected state in the index range between start and index
			const newStickers = stickers
				.map((sticker, i) => i >= start && i <= index ? {selected: true, hovered: false} : sticker);
			setStickers(
				newStickers
			);
			console.log(newStickers);
			setStart(null);
		}

		if (start === index && isCmdPressed) {
			// toggle the sticker selected state
			setStickers(stickers.map((sticker, i) => {
				if (i === start) {
					sticker.selected = !sticker.selected;
				}
				return sticker;
			}));
		}
		console.log('up',index);
	}, [start, stickers]);

	const onMouseLeave = useCallback((index) => {
		setHovered(value);
	}, []);
	const onClick = useCallback((index) => {
        onChange?.(index);
	}, []);

	const quantityElements = Array.from(Array(quantity));
	return (
		<div className={styles.stickerChooserPaper} onMouseLeave={onMouseLeave}>
			<div>
				{stickers.map((q, i )=> (
					<Button 
						key={i}
						onMouseDown={() => onMouseDown(i)}
						onMouseUp={() => onMouseUp(i)}
						onMouseEnter={() => onMouseEnter(i)} 
						onClick={() => onClick(i)} 
						type={q.selected ? 'default' : 'dashed'}
						className={styles[`stickerChooserSticker${upperFirst(type)}`]}
					>
						<span className="scName" />
						<span className="scSku" />
						<span className="scPrice" />
						<span className="scPrice1" />
						<span className="scPrice2" />
					</Button>
				))}
			</div>
		</div>
	);
}