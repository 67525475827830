import React from 'react';
import { inject, observer } from 'mobx-react';
import { HeaderDropdown } from 'ant-design-pro';
import { Link } from 'react-router-dom';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Card, List, Tag } from 'antd';
import './style.less';

type Props = {
	user?: {
		initials: String,
		fullName: String,
		email: String,
		isAdmin: boolean,
	}
}

function UserMenu(props: Props) {
	const {
		user: {
			initials, fullName, email, isAdmin,
		},
	} = props;

	const userMenu = (
		<Menu className="erp-userMenu-dropdown">
			<Card
				bordered={false}
			>
				<Card.Meta
					avatar={<Avatar size="large">{initials}</Avatar>}
					title={fullName}
					description={email}
				/>

			</Card>
			{/* <Menu.Item key="store-1">
					Mačkica <Tag color="green">Odabrana</Tag>
			</Menu.Item>
			<Menu.Item key="store-2">
					Dante
			</Menu.Item> */}
			<Menu.Divider />
			<Menu.Item key="profile">
				<UserOutlined /> Profil
			</Menu.Item>
			{isAdmin && (
				<Menu.Item key="settings">
					<SettingOutlined /> Podešavanja
				</Menu.Item>
			)}
			<Menu.Item key="logout">
				<LogoutOutlined /> Odjavi se
			</Menu.Item>


		</Menu>
	);

	return (
		<span className="erp-userMenu">
			<Dropdown
				overlay={userMenu}
				trigger={['click']}
				placement="bottomRight"
			>
				<Link to="/app/user/profile" className="clickArea">
					<Avatar
						size="small"
					>
						{initials}
					</Avatar>
				</Link>
			</Dropdown>
		</span>

	);
}

export default inject(({ store }) => ({
	user: store.userStore.authenticatedUser,
}))(
	observer(UserMenu)
);
