import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import upperFirst from 'lodash/upperFirst';
import {
	Tag,
	Input,
} from 'antd';
import shortid from 'shortid';

import withCrud from '../../../hocs/withCrud';


type Props = {

};

type State = {

};

// eslint-disable-next-line react/prefer-stateless-function
class Users extends Component<Props, State> {
	render() {
		return null;
	}
}

export default withCrud(Users, {
	model: 'user',
	columns: [
		{
			title: 'Ime',
			dataIndex: 'fullName',
			key: 'fullName',
		},
		{
			title: 'E-Mail',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Nivo',
			dataIndex: 'level',
			key: 'level',
			render: level => upperFirst(level),
		},
	],
	createButtonText: 'Dodaj Korisnika',
	titles: {
		create: 'Dodaj Korisnika',
		edit: 'Izmeni Korisnika',
		delete: 'Da li ste sigurni da želite da obrišete ovog korisnika?',
		view: 'Pregled Korisnika',
	},
	view: [

	],
	formFields: [
		{
			key: 'row1',
			fields: [
				{
					key: 'firstName',
					label: 'Ime',
					rules: [{ required: true, message: 'Ime je obavezno' }],
					component: <Input />,
				},
				{
					key: 'lastName',
					label: 'Prezime',
					rules: [{ required: true, message: 'Prezime je obavezno' }],
					component: <Input />,
				},
			],
		},
		{
			key: 'row2',
			fields: [
				{
					key: 'email',
					label: 'E-Mail Adresa',
					rules: [{ email: true, message: 'E-Mail adresa je neispravna' }],
					component: <Input />,
				},
			],
		},

	],
});
