import React, { ReactNode, FunctionComponent } from 'react';

import { Col, Row, Button, Form as AntForm } from 'antd';

import styles from './Form.module.less';

interface Props {
	formLayout?: 'inline' | 'horizontal' | 'vertical';
	formFields: Array<{
		key: string,
		fields: Array<{
			label?: String,
			key?: string,
			rules?: any,
			component: ReactNode,
			span?: number,
			initialValue?: any,
		}>,
	}>;
	isCreating?: boolean;
	entity?: any;
	onSave?: Function;
	onCancel?: Function;
}

const Form: FunctionComponent<Props> = ({
	formLayout,
	formFields: rows,
	entity,
	onCancel,
	onSave,
	isCreating,
}) => {
	const [form] = AntForm.useForm();

	async function handleSave() {
		try {
			const values = await form.validateFields();
			onSave(values);
		} catch (e) {}
	}

	return (
		<>
			<AntForm layout={formLayout} initialValues={entity} form={form}>
				{rows.map((row) => (
					<Row key={row.key} gutter={8}>
						{row.fields.map((field) => (
							<Col key={field.key} span={field.span || 24}>
								<AntForm.Item
									label={field.label}
									name={field.key}
									rules={field.rules}
									initialValue={field.initialValue}
								>
									{field.component}
								</AntForm.Item>
							</Col>
						))}
					</Row>
				))}
			</AntForm>
			<div className={styles.footer}>
				<Button
					key="cancel"
					onClick={() => onCancel()}
					style={{ marginRight: 8 }}
				>
					Poništi
				</Button>
				<Button
					key="save"
					type="primary"
					loading={isCreating}
					onClick={handleSave}
				>
					Sačuvaj
				</Button>
			</div>
		</>
	);
};

Form.defaultProps = {
	convert: {},
	formLayout: 'vertical',
	isCreating: false,
	onCancel: () => {},
	onSave: () => {},
};

export default Form;
