import { types, flow } from 'mobx-state-tree';
import { v2Client, defaultClient } from '../store/client';
import { CrudStore } from './Crud';

export const cache = false;

export const User = types
	.model('User', {
		id: types.identifierNumber,
		firstName: types.string,
		lastName: types.string,
		email: types.string,
		level: types.enumeration(['superadmin', 'admin', 'employee', 'member']),
	})
	.views(self => ({
		get initials() {
			return `${self.firstName[0]}${self.lastName[0]}`.toUpperCase();
		},
		get fullName() {
			return `${self.firstName} ${self.lastName}`;
		},
		get isAdmin() {
			return self.level === 'superadmin' || self.level === 'admin';
		},
	}));

export const Token = types
	.model('Token', {
		token: types.string,
	});

// export const UserStore = types
// 	.model('UserStore', {
// 		authenticatedUser: types.maybeNull(User),
// 		token: types.maybeNull(Token),
// 		fetched: types.optional(types.boolean, false),
// 		loading: types.optional(types.boolean, false),
// 		error: types.maybeNull(types.string),
// 		cache: types.optional(types.boolean, true),
// 	})
// 	.actions(self => ({
// 		afterHydrate: () => {
// 			if (self.token) {
// 				defaultClient.defaults.headers.common['x-access-token'] = self.token.token;
// 				v2Client.defaults.headers.common['x-access-token'] = self.token.token;
// 			}
// 		},

// 		login: flow(function* login(email, password) {
// 			self.loading = true;

// 			try {
// 				const response = yield defaultClient.post('/users/authenticate', {
// 					email,
// 					password,
// 				});

// 				const { data } = response as any;
// 				if (data) {
// 					defaultClient.defaults.headers.common['x-access-token'] = data.token;
// 					v2Client.defaults.headers.common['x-access-token'] = data.token;

// 					const user = yield defaultClient.get('/users/me');
// 					const { data: userData } = user as any;
// 					self.authenticatedUser = userData;
// 					self.token = data;
// 				}

// 				self.fetched = true;
// 			} catch (error) {
// 				self.error = error.response.data.message;
// 			} finally {
// 				self.loading = false;
// 			}
// 		}),
// 		afterAuth: () => { },
// 	}))
// 	.views(self => ({
// 	}));

const CrudUserStore = CrudStore(
	'user',
	User,
	undefined,
	'v1'
);

const AdditionalUserStore = types.model(
	'UserStore', {
		authenticatedUser: types.maybeNull(User),
		token: types.maybeNull(Token),
	}
);


export const UserStore = types.compose(
	CrudUserStore,
	AdditionalUserStore,
).actions(self => ({
	afterHydrate: () => {
		console.log(self.token);
		if (self.token) {
			defaultClient.defaults.headers.common['x-access-token'] = self.token.token;
			v2Client.defaults.headers.common['x-access-token'] = self.token.token;
		}
	},

	login: flow(function* login(email, password) {
		self.isFetching = true;

		try {
			const response = yield defaultClient.post('/users/authenticate', {
				email,
				password,
			});

			const { data } = response as any;
			if (data) {
				defaultClient.defaults.headers.common['x-access-token'] = data.token;
				v2Client.defaults.headers.common['x-access-token'] = data.token;

				const user = yield defaultClient.get('/users/me');
				const { data: userData } = user as any;
				self.authenticatedUser = userData;
				self.token = data;
			}

			// self.fetched = true;
		} catch (error) {
			self.error = error.response.data.message;
		} finally {
			self.isFetching = false;
		}
	}),
	afterAuth: () => { },
}));
