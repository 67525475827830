import { createAction } from 'redux-actions';
import axios from 'axios';

import {
	FETCH_BRANDS_ATTEMPT,
	FETCH_BRANDS_FAILED,
	FETCH_BRANDS_SUCCESS,
	CREATE_BRANDS_ATTEMPT,
	CREATE_BRANDS_FAILED,
	CREATE_BRANDS_SUCCESS,
	UPDATE_BRANDS_ATTEMPT,
	UPDATE_BRANDS_FAILED,
	UPDATE_BRANDS_SUCCESS,
	DELETE_BRANDS_ATTEMPT,
	DELETE_BRANDS_FAILED,
	DELETE_BRANDS_SUCCESS,
} from './types';

const fetchBrandsAttempt = createAction(FETCH_BRANDS_ATTEMPT);
const fetchBrandsFailed = createAction(FETCH_BRANDS_FAILED);
const fetchBrandsSuccess = createAction(FETCH_BRANDS_SUCCESS);

const createBrandAttempt = createAction(CREATE_BRANDS_ATTEMPT);
const createBrandFailed = createAction(CREATE_BRANDS_FAILED);
const createBrandSuccess = createAction(CREATE_BRANDS_SUCCESS);

const updateBrandAttempt = createAction(UPDATE_BRANDS_ATTEMPT);
const updateBrandFailed = createAction(UPDATE_BRANDS_FAILED);
const updateBrandSuccess = createAction(UPDATE_BRANDS_SUCCESS);

const deleteBrandAttempt = createAction(DELETE_BRANDS_ATTEMPT);
const deleteBrandFailed = createAction(DELETE_BRANDS_FAILED);
const deleteBrandSuccess = createAction(DELETE_BRANDS_SUCCESS);

export function fetchBrands() {
	return async (dispatch) => {
		dispatch(fetchBrandsAttempt());
		try {
			const brands = await axios.get('/brands');
			dispatch(fetchBrandsSuccess(brands.data));
		} catch (error) {
			dispatch(fetchBrandsFailed(error.response.data));
		}
	};
}

export function createBrand(data) {
	return async (dispatch) => {
		dispatch(createBrandAttempt());
		try {
			const brands = await axios.post('/brands', data);
			dispatch(createBrandSuccess(brands.data));
		} catch (error) {
			dispatch(createBrandFailed(error.response.data));
		}
	};
}

export function updateBrand(data) {
	return async (dispatch) => {
		dispatch(updateBrandAttempt());
		try {
			const brands = await axios.put(`/brands/${data.id}`, data);
			dispatch(updateBrandSuccess(brands.data));
		} catch (error) {
			dispatch(updateBrandFailed(error.response.data));
		}
	};
}


export function deleteBrand(id) {
	return async (dispatch) => {
		dispatch(deleteBrandAttempt());
		try {
			await axios.delete(`/brands/${id}`);
			dispatch(deleteBrandSuccess(id));
		} catch (error) {
			dispatch(deleteBrandFailed(error.response.data));
		}
	};
}
