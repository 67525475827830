import React, { Fragment } from 'react';
import UserMenu from '../UserMenu';

import * as styles from './Header.module.less';

type Props = {
	breadcrumb: {
		[key: string]: {
			name: string,
		}

	},
	location: {
		pathname: string,
	}
	extraContent: React.ReactNode | null,
};

export default function Header(props: Props) {
	const { breadcrumb, location: { pathname }, extraContent } = props;
	const title = breadcrumb[pathname] ? breadcrumb[pathname].name : '';

	return (
		<Fragment>
			<div className={styles.header}>
				<div className={styles.title}>{title}</div>
				<div className={styles.extraHeader}>{extraContent}</div>
			</div>

			<UserMenu />
		</Fragment>
	);
}
