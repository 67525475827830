import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

import { LinkOutlined } from '@ant-design/icons';

import { PageHeader, Button, Table } from 'antd';

type Props = {
	calculationStore: any
};

@inject(({ store }) => ({
	calculationStore: store.calculationStore,
}))
@observer
export default class Calculations extends Component<Props> {
	componentDidMount() {
		const { calculationStore } = this.props;
		calculationStore.fetch();
	}

	render() {
		return (
			<div>
				<PageHeader
					backIcon={false}
					title="Kalkulacije"
					extra={(
						<div>
							<Button
								type="primary"
							>Dodaj Kalkulaciju
							</Button>
						</div>
					)}
				/>
				<Table
					bordered
					columns={[
						{
							title: 'Broj',
							key: 'number',
							dataIndex: 'number',
							width: 20,
						},
						{
							title: 'Distributer',
							key: 'invoice.distributor.name',
							dataIndex: ['invoice', 'distributor', 'name'],
							width: 200,
						},
						{
							title: 'Faktura',
							key: 'invoice.number',
							dataIndex: ['invoice', 'number'],
							width: 200,
							render: (text, record) => (
								<span>
									{text} <Button type="link" icon={<LinkOutlined />} />
								</span>
							),
						},
						{
							title: 'Datum kalkulacije',
							dataIndex: 'date',
							key: 'date',
							render: text => moment(text).format('L'),
							filters: [
								{
									text: '2017',
									value: '2017',
								},
								{
									text: '2018',
									value: '2018',
								},
								{
									text: '2019',
									value: '2019',
								},
							],
							filteredValue: [2019],
							filterMultiple: true,
							onFilter: (value, record) => moment(record.date).format('YYYY') === value,
						},
					]}
					dataSource={[
						{
							number: '1',
							invoice: {
								distributor: {
									name: 'Premil',
								},
								number: 12345,
							},
							date: moment(),
						},
					]}
				/>
			</div>
		);
	}
}
