import { createActions } from 'redux-actions';
import axios from 'axios';

import { LOGIN_ATTEMPT, LOGIN_FAILED, LOGIN_SUCCESS } from './types';

import { defaultClient } from '../store/client';

const { loginAttempt, loginFailed, loginSuccess } = createActions({
	[LOGIN_ATTEMPT]: () => ({}),
	[LOGIN_FAILED]: error => ({ error }),
	[LOGIN_SUCCESS]: token => token,
});

export function login(email, password) {
	return async (dispatch) => {
		dispatch(loginAttempt());
		try {
			const token = await axios.post('/users/authenticate', {
				password,
				email,
			});
			defaultClient.defaults.headers.common['x-access-token'] = token.data.token;
			axios.defaults.headers.common['x-access-token'] = token.data.token;
			// TODO: Remember me functionality
			sessionStorage.setItem('token', JSON.stringify(token.data));
			return dispatch(loginSuccess(token.data));
		} catch (error) {
			return dispatch(loginFailed(error.response.data.message));
		}
	};
}

export function returnToApp() {
	const ssToken = sessionStorage.getItem('token');
	const lsToken = localStorage.getItem('token');

	if (lsToken) {
		const token = JSON.parse(lsToken);
		defaultClient.defaults.headers.common['x-access-token'] = token.token;
		axios.defaults.headers.common['x-access-token'] = token.token;

		return {
			type: 'LOGIN_SUCCESS',
			payload: token,
		};
	}

	if (ssToken) {
		const token = JSON.parse(ssToken);
		defaultClient.defaults.headers.common['x-access-token'] = token.token;
		axios.defaults.headers.common['x-access-token'] = token.token;

		return {
			type: 'LOGIN_SUCCESS',
			payload: token,
		};
	}

	return {
		type: 'NOOP',
	};
}

export function logout() {

}
