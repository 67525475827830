import crudActions from './crudActions';


export const {
	fetchCalculations, createCalculation, updateCalculation, deleteCalculation,
} = crudActions('calculation');

export function createItem(calculationItem) {
	return {
		type: 'CALCULATION_CREATE_ITEM',
		payload: {
			request: {
				url: `/calculations/${calculationItem.calculationId}/items`,
				method: 'post',
				data: calculationItem,
			},
		},
	};
}
export function updateItem(calculationItem) {
	return {
		type: 'CALCULATION_UPDATE_ITEM',
		payload: {
			request: {
				url: `/calculations/${calculationItem.calculationId}/items/${calculationItem.id}`,
				method: 'put',
				data: calculationItem,
			},
		},
	};
}

export function deleteItem(calculationItem) {
	return (dispatch) => {
		if (calculationItem.id) {
			return dispatch({
				type: 'CALCULATION_DELETE_ITEM',
				payload: {
					request: {
						url: `/calculations/${calculationItem.calculationId}/items/${calculationItem.id}`,
						method: 'delete',
						data: calculationItem,
					},
				},
			});
		}

		return dispatch({
			type: 'CALCULATION_DELETE_ITEM_SUCCESS',
			payload: calculationItem,
		});
	};
}

export function processCalculation(calculation) {
	return {
		type: 'CALCULATION_PROCESS',
		payload: {
			request: {
				url: `/calculations/${calculation.id}/process`,
				method: 'put',
			},
		},
	};
}
