import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { CloudUploadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
	Button, notification, Col, Row,
} from 'antd';
import { inject, observer } from 'mobx-react';

// import FiscalPrinter from '../../../../../../lib/fiscalPrinter';
import styles from './Controls.module.less';


const { Group: ButtonGroup } = Button;


type Props = {
	onDateChange: (date: Moment) => void,
	importSaleDay?: () => void,
	importing?: boolean,
	failedSalesCount?: number
};

function Controls(props: Props) {
	const {
		onDateChange, importSaleDay, importing, failedSalesCount,
	} = props;
	const [currentDate, setCurrentDate] = useState(moment());

	const currentMonth = currentDate.month();

	function previousMonth() {
		const newDate = currentDate.clone().subtract(1, 'month');
		setCurrentDate(newDate);
		onDateChange(newDate);
	}

	function nextMonth() {
		const newDate = currentDate.clone().add(1, 'month');
		setCurrentDate(newDate);
		onDateChange(newDate);
	}

	return (
		<>
			<ButtonGroup>
				<Button
					disabled={currentMonth === 0}
					type="link"
					onClick={previousMonth}
					className={styles.arrowButton}
				>
					<LeftOutlined />
				</Button>
				<div className={styles.month}>{currentDate.format('MMMM')}</div>
				<Button
					disabled={currentMonth === 11 || currentMonth === moment().month()}
					type="link"
					onClick={nextMonth}
					className={styles.arrowButton}
				>
					<RightOutlined />
				</Button>
			</ButtonGroup>
			<Row>
				<Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }}>
					<Button
						icon={<CloudUploadOutlined />}
						onClick={async () => {
							if (failedSalesCount > 0) {
								return notification.error({
									key: 'importSalesError',
									message: 'Greška',
									description: 'Prodaje nisu sinhronizovane. Sačekajte da se prodaje sinhronizuju pre ponovnog pokušaja',
									duration: 10,
								});
							}
							importSaleDay();
        		// const dp = await FiscalPrinter.getInstance();

        		// // dp.getTotals()
        		// // 	.then(totals => {
        		// // 		console.log(totals);
        		// // 	});

        		// const sold = [];
        		// let first = true;

        		// function getNextSoldItem() {
        		// 	let promise;
        		// 	if (first) {
        		// 		first = false;
        		// 		promise = dp.getFirstSoldItem();
        		// 	} else {
        		// 		promise = dp.getNextSoldItem();
        		// 	}

        		// 	promise.then(data => {
        		// 		console.log(data);
        		// 		if (!data) {
        		// 			return console.log(sold);
        		// 		}

        		// 		sold.push(data);
        		// 		getNextSoldItem();
        		// 	});
        		// }

        		// getNextSoldItem();
        	}}
						loading={importing}
						disabled={importing}
					>

						Uvezi sa Fiskalne Kase
					</Button>
				</Col>
			</Row>
		</>
	);
}

export default inject(
	({ store: { saleDayStore, localSaleStore } }) => ({
		importSaleDay: saleDayStore.importSaleDay,
		importing: saleDayStore.importing,
		failedSalesCount: localSaleStore.failedSales.length,
	})
)(
	observer(Controls)
);
