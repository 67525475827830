import React, { ReactElement } from 'react';
import {
	Drawer, Button, Descriptions, Typography,
} from 'antd';

import { DescriptionsProps } from 'antd/es/descriptions/';

import styles from './View.module.less';

interface Props {
	width?: number
	visible: boolean,
	entity?: any,
	titles: {
		view: String,
	}
	sections: Array<{
		key: string,
		label: string,
		column: DescriptionsProps['column'],
		fields: Array<{
			label?: String,
			key?: string,
			rules?: any,
			component?: ReactElement,
			span?: number,
		}>
	}>,
	onCancel?: Function,
}

function View(props:Props) {
	const {
		width = 600,
		visible,
		entity,
		titles,
		sections: rows,
		onCancel = () => {},
	} = props;

	function handleCancel() {
		onCancel();
	}

	return (
		<Drawer
			width={width}
			visible={visible}
			title={titles.view}
			onClose={handleCancel}
			bodyStyle={{ paddingBottom: 80 }}
		>
			{entity && rows.map(row => (
				<Descriptions
					title={row.label}
					key={row.key}
					column={row.column}
					layout="vertical"
					size="small"
					className={styles.descriptions}
				>
					{row.fields.map(field => {
						const viewComponent = field.component
							? React.cloneElement(
								field.component,
								{ value: entity[field.key] }
							)
							: null;

						return (
							<Descriptions.Item
								key={field.key}
								label={field.label}
								span={field.span}
								className={!field.label ? styles.noLabel : ''}
							>
								{
									viewComponent
										|| entity[field.key]
										|| <Typography.Text disabled>Nije dostupno</Typography.Text>
								}
							</Descriptions.Item>
						);
					})}
				</Descriptions>

			))}
			<div className={styles.footer}>
				<Button key="cancel" onClick={handleCancel} style={{ marginRight: 8 }}>
					Zatvori
				</Button>
			</div>
		</Drawer>
	);
}

export default View;
