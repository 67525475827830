import React from 'react';
import {
	types, flow, applySnapshot,
} from 'mobx-state-tree';

import {
	notification, Progress,
} from 'antd';

import moment from 'moment';

import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';

import round from 'lodash/round';
import { v2Client, ecrServiceClient } from '../store/client';

import { Sale } from './Sale';
import { SaleItem } from './SaleItem';

export const SaleDay = types
	.model('SaleDay', {
		id: types.identifierNumber,
		timestamp: types.string,
		paidTotal: types.maybeNull(types.number),
		paidCheck: types.maybeNull(types.number),
		paidCard: types.maybeNull(types.number),
		paidCash: types.maybeNull(types.number),
		loading: types.optional(types.boolean, false),
		error: types.maybeNull(types.string),
		sales: types.optional(types.array(Sale), []),
		saleItems: types.optional(types.array(SaleItem), []),
	})
	.actions(self => {
		const actions = {
			fetchSales: flow(function* fetchSales() {
				self.loading = true;
				try {
					const response = yield v2Client.get(`/stores/2/sale-days/${self.id}/sales`);
					const { data } = response as { data: typeof Sale.Type[] };

					if (data) {
						self.sales.replace(data);
					}
					self.loading = false;
				} catch (error) {
					self.error = error.message;
					self.loading = false;
				}
			}),
			fetchSaleItems: flow(function* fetchSales() {
				self.loading = true;
				try {
					const response = yield v2Client.get(`/stores/2/sale-days/${self.id}/sale-items`);
					const { data } = response as { data: typeof SaleItem.Type[] };

					if (data) {
						self.saleItems.replace(data);
					}
					self.loading = false;
				} catch (error) {
					self.error = error.message;
					self.loading = false;
				}
			}),
		};

		return actions;
	})
	.views(self => {
		const views = {
			get summedSaleItems() {
				return sortBy(
					Object.values(
						self.saleItems.reduce((prev, curr) => {
							if (!prev[curr.product.id]) {
								prev[curr.product.id] = {
									...curr,
								};

								return prev;
							}

							prev[curr.product.id].quantity += curr.quantity;
							prev[curr.product.id].grossPrice += curr.grossPrice;

							return prev;
						}, {})
					),
					'product.sku'
				);
			},
		};

		return views;
	});

export const SaleDayStore = types
	.model('SaleDayStore', {
		saleDays: types.optional(types.array(SaleDay), []),
		count: types.optional(types.number, 0),
		fetched: types.optional(types.boolean, false),
		loading: types.optional(types.boolean, false),
		cache: types.optional(types.boolean, true),
		importing: types.optional(types.boolean, false),
		// currentStartDate: types.optional(types.number, 1),
	})
	.views(self => {
		const views = {
			get byDate() {
				return keyBy(self.saleDays, (item: typeof SaleDay.Type) => moment(item.timestamp).startOf('day').toISOString());
			},
			get byId() {
				return keyBy(self.saleDays, 'id');
			},
		};

		return views;
	})
	.actions(self => {
		const actions = {
			fetchSaleDays: flow(function* fetchSaleDays(from = moment().startOf('month')) {
				const newFrom = from.clone().startOf('isoWeek');
				const to = from.clone().add(5, 'weeks');
				self.loading = true;
				try {
					const response = yield v2Client({
						method: 'get',
						url: '/stores/2/sale-days/',
						headers: {
							'pagination-limit': to.diff(newFrom, 'days'),
							'pagination-from': newFrom.toISOString(),
							'pagination-type': 'scrolled',
						},
					});

					const { data } = response as {
						data: typeof SaleDay.Type[],
					};
					self.saleDays.replace(data);
					self.loading = false;
				} catch (error) {
					console.log(error);
					self.loading = false;
				}
			}),
			importSaleDay: flow(function* importSaleDay() {
				self.loading = true;
				self.importing = true;
				notification.info({
					key: 'salesImport',
					message: 'Uvoz iznosa sa fiskalne kase',
					description: <span><Progress percent={0} status="active" /></span>,
					duration: 0,
				});

				let totals: any;
				let data: any = [];

				try {
					totals = yield ecrServiceClient.get('/totals');

					notification.info({
						key: 'salesImport',
						message: 'Uvoz prodatih artikala sa fiskalne kase',
						description: <span><Progress percent={33} status="active" /></span>,
						duration: 0,
					});

					const soldItems:
					{
						data: [
							{
								plu: string,
								total: string,
								sold: string,
							}
						]

					} = yield ecrServiceClient.get('/soldItems');

					if (soldItems) {
						data = soldItems.data
							.map(soldItem => ({
								sku: soldItem.plu,
								grossPrice: soldItem.total,
								quantity: soldItem.sold,
							}));
					}

					notification.info({
						key: 'salesImport',
						message: 'Uvoz podataka u ERP',
						description: <span><Progress percent={66} status="active" /></span>,
						duration: 0,
					});
				} catch {
					notification.error({
						key: 'salesImport',
						message: 'Greška',
						description: 'Greška prilikom povezivanja sa fiskalnom kasom. Proverite da li se kasa nalazi u režimu "PC VEZA"',
						duration: 30,
					});
					self.loading = false;
					self.importing = false;

					return;
				}

				const paidTotal = round(totals.data.cash + totals.data.card + totals.data.check, 2);

				try {
					const { data: imported } : any = yield v2Client.post('/stores/2/sale-days/import', {
						data,
						totals: {
							paidCash: totals.data.cash,
							paidCard: totals.data.card,
							paidCheck: totals.data.check,
							paidTotal,
						},
					});

					notification.info({
						key: 'salesImport',
						message: 'Uvoz je završen',
						description: <span><Progress percent={100} /></span>,
						duration: 30,
					});

					self.loading = false;
					self.importing = false;

					if (imported) {
						if (self.byId[imported.id]) {
							applySnapshot(self.byId[imported.id], imported);
						} else {
							self.saleDays.push(imported);
						}
					}
				} catch (error) {
					notification.error({
						key: 'salesImport',
						message: 'Greška',
						description: 'Greška prilikom sinhronizacije podataka. Kontaktirajte podršku.',
						duration: 30,
					});
					self.loading = false;
					self.importing = false;
				}
			}),
			afterAuth: () => {
				// actions.fetchSaleDays({limit: 10, offset: 0});
			},
		};

		return actions;
	});
