import React, { Component, SyntheticEvent, FocusEvent } from 'react';
import { inject, observer } from 'mobx-react';

import { InputNumber } from 'antd';

type Props = {
	record: any,
	fieldValue?: number,
	field: string,
	onChange: Function,
};

@inject((stores, props: Props) => ({
	fieldValue: props.record[props.field],
}))
@observer
export default class QuantityInput extends Component<Props> {
	render() {
		const { fieldValue, onChange } = this.props;
		return (
			<InputNumber
				{...this.props}
				value={fieldValue}
				onChange={(value: any) => onChange(value)}
			/>
		);
	}
}
