import React, { ReactElement, ReactNode, Component } from 'react';
import pluralize from 'pluralize';
import { observer, inject } from 'mobx-react';

import { DescriptionsProps } from 'antd/es/descriptions/';

import upperFirst from 'lodash/upperFirst';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import {
	Layout, Table, Input, Button, Popconfirm,
} from 'antd';

import Create from './Create/mobx';
import View from './View';

import './styles.css';

const { Content } = Layout;

type Props = {
	model: string,
	fetchAll?: Function,
	isFetching?: boolean,
	isCreating?: boolean,
	data?: [any],
	tableProps?: Object,
	columns: any,
	titles: {
		view: String,
		edit: String,
		create: String,
		delete: String,
	},
	createVisible: boolean,
	viewVisible: boolean,
	formFields: Array<{
		key: string,
		fields: Array<{
			label?: String,
			key?: string,
			rules?: any,
			component: ReactNode,
			span?: number,
			initialValue?: any,
		}>
	}>,
	view: Array<{
		key: string,
		label: string,
		column: DescriptionsProps['column'],
		fields: Array<{
			label?: String,
			key?: string,
			component?: ReactElement,
			span?: number,
		}>
	}>,
	canCreate: Function,
	canUpdate: Function,
	canDelete: Function,
	canRead: Function,
	setCreateVisible: Function,
	setViewVisible: Function,
	create?: Function,
};

type State = {
	entity?: any,
};

@inject((stores: any, props: Props) => {
	const { model } = props;
	const modelNamePlural = pluralize(model);

	const storeName = `${model}Store`;
	const store = stores.store[storeName];
	return {
		store,
		data: store[modelNamePlural].toJS(),
		length: store[modelNamePlural].length,
		isFetching: store.isFetching,
		isCreating: store.isCreating,
		fetchAll: store.fetchAll,
		create: store.create,
	};
})
@observer
class CrudPage extends Component<Props, State> {
	static defaultProps = {
		tableProps: {},
	};

	state = {
		entity: null,
	};

	componentDidMount() {
		const { fetchAll } = this.props;
		fetchAll();
	}

	render() {
		const {
			isFetching,
			data,
			tableProps,
			children,
			columns,
			titles,
			createVisible,
			viewVisible,
			formFields,
			canUpdate,
			canDelete,
			setCreateVisible,
			setViewVisible,
			create,
			view: sections,
			isCreating,
		} = this.props;

		const {
			entity,
		} = this.state;

		const [firstColumn, ...restColumns] = columns;

		return (
			<Content>

				<Table
					size="middle"
					bordered
					loading={isFetching}
					rowKey="id"
					columns={[
						{
							...firstColumn,
							render: (text, record) => (
								<Button
									type="link"
									onClick={() => {
										this.setState({
											entity: record,
										});
										setViewVisible(true);
									}}
								>
									{firstColumn.render
										? firstColumn.render(text, record)
										: text
									}
								</Button>
							),
						},
						...restColumns,
						{
							title: 'Akcije',
							key: 'actions',
							render: (text, record) => (
								<Button.Group>
									{canUpdate() && (
										<Button
											size="small"
											icon={<EditOutlined />}
											onClick={() => {
												setCreateVisible(true);
												this.setState(() => ({
													entity: record,
												}));
											}}
										/>
									)}
									{canDelete() && (
										<Popconfirm
											placement="topRight"
											title={titles.delete}
											onConfirm={() => {
												record.destroy();
											}}
											okText="Da"
											cancelText="Ne"
										>
											<Button
												size="small"
												icon={<DeleteOutlined />}
											/>
										</Popconfirm>
									)}
								</Button.Group>
							),
						},
					]}
					dataSource={data}
					{...tableProps}
				/>
				<Create
					entity={entity}
					visible={createVisible}
					titles={titles}
					formFields={formFields}
					isCreating={isCreating}
					onCancel={() => {
						this.setState(() => ({
							entity: null,
						}));
						setCreateVisible(false);
					}}
					onSave={async values => {
						if (entity) {
							await entity.update(values);
						} else {
							await create(values);
						}
						this.setState(() => ({
							entity: null,
						}));
						setCreateVisible(false);
					}}
				/>
				<View
					entity={entity}
					visible={viewVisible}
					titles={titles}
					sections={sections}
					onCancel={() => {
						setViewVisible(false);
						this.setState({
							entity: null,
						});
					}}
				/>
				{children}
			</Content>
		);
	}
}

export default CrudPage;
