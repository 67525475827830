import React from 'react';
import {Modal, Result, Button} from 'antd';
import NewERPIcon from "../../../components/ERPIcon/new";

interface Props {
	visible: boolean;
	onCancel: () => void;
	onOk: () => void;
	text: string,
}

export default function ProductNotFound({
	visible,
	onCancel,
	onOk,
	text,
}: Props) {
	return (
		<Modal
			width={600}
			visible={visible}
			onOk={onOk}
			onCancel={onCancel}
			title={null}
			footer={null}
		>
			<Result 
				title="Proizvod nije pronađen"
				subTitle={text}
				icon={<NewERPIcon type="erpa-011-cancel" />}
				extra={(
					<Button type="primary" onClick={onOk}>
						U redu
					</Button>
				)}
			/>
		</Modal>
	);
}