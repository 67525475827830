import {
	types, onSnapshot, protect, unprotect, applySnapshot, onPatch,
} from 'mobx-state-tree';
import localforage from 'localforage';

import forEach from 'lodash/forEach';

import { ProductStore } from './Product';
import { DistributorStore } from './Distributor';
import { DeliveryStore } from './Delivery';
import { DiscountStore } from './Discount';
import { InvoiceStore } from './Invoice';
import { LocalSaleStore } from './LocalSale';
import { PriceChangeStore } from './PriceChange';
// import { StockCheckupStore } from './StockCheckup';
import { CalculationStore } from './Calculation';
import { UserStore } from './User';
import { ApplicationStore } from './Application';
import { SaleDayStore } from './SaleDay';
import { PrintPricesStore } from './PrintPrices';

let hydrated = false;

export const Store = types
	.model('Store', {
		productStore: types.optional(ProductStore, {
			products: [],
			fetched: false,
			loading: false,
		}),
		localSaleStore: types.optional(LocalSaleStore, {
			active: 0,
			saleCount: 0,
			fetched: false,
			loading: false,
		}),
		priceChangeStore: types.optional(PriceChangeStore, {
			priceChanges: [],
			fetched: false,
			loading: false,
		}),
		// stockCheckupStore: types.optional(StockCheckupStore, {
		// 	stockCheckups: [],
		// 	fetched: false,
		// 	loading: false,
		// 	creating: false,
		// }),
		calculationStore: types.optional(CalculationStore, {
			calculations: [],
			fetched: false,
			loading: false,
			pages: 1,
			page: 1,
		}),
		userStore: types.optional(UserStore, {}),
		applicationStore: types.optional(ApplicationStore, {}),
		saleDayStore: types.optional(SaleDayStore, {}),
		distributorStore: types.optional(DistributorStore, {}),
		deliveryStore: types.optional(DeliveryStore, {}),
		discountStore: types.optional(DiscountStore, {}),
		invoiceStore: types.optional(InvoiceStore, {}),
		printPricesStore: types.optional(PrintPricesStore, {}),
	})
	.actions(self => {
		function afterCreate() {
			const stores = Object.keys(self);


			unprotect(self);
			stores
				.filter(store => self[store].cache)
				.reduce(async (prev, store) => {
					await prev;

					console.log(`Hydrating ${store}`);
					if (self[store].hydrate) {
						await self[store].hydrate();
					} else {
						const stored = await localforage.getItem(store);
						if (stored) {
							applySnapshot(self[store], stored);
						}
					}
					if (self[store].afterHydrate) {
						await self[store].afterHydrate();
					}

				}, Promise.resolve())
				.then(async () => {
					// eslint-disable-next-line no-use-before-define
					if (!store.userStore.token) {
						forEach(self, singleStore => {
							if (Object.hasOwnProperty.call(singleStore, 'afterAuth')) {
								setImmediate(() => {
									(singleStore as {afterAuth: Function}).afterAuth(false);
								});
							}
						});
					}


					hydrated = true;
					protect(self);
				});
		}

		return {
			afterCreate,
		};
	});

const store = Store.create();
onSnapshot(store, snapshot => {
	if (hydrated) {
		forEach(snapshot, (singleStore, storeName) => {
			if ((singleStore as { cache: boolean }).cache) {
				localforage.setItem(storeName, singleStore);
			}
		});


		// localforage.setItem('sales', snapshot.localSaleStore);
		// localforage.setItem('products', snapshot.productStore);
		// localforage.setItem('priceChanges', snapshot.priceChangeStore);
	}
});

onPatch(store, patch => {
	if (patch.op === 'replace' && patch.path === '/userStore/token') {
		forEach(store, singleStore => {
			if ((singleStore as { afterAuth: Function }).afterAuth) {
				setImmediate(() => {
					(singleStore as { afterAuth: Function }).afterAuth(true);
				});
			}
		});
	}
});


export default store;
