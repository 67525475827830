import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
	Tag,
	Input,
} from 'antd';
import shortid from 'shortid';

import withCrud from '../../../hocs/withCrud';

import ContactsInput from './Components/ContactsInput';
import ContactsView from './Components/ContactsView';


type Props = {

};

type State = {

};

// eslint-disable-next-line react/prefer-stateless-function
class Distributors extends Component<Props, State> {
	render() {
		return null;
	}
}

export default withCrud(Distributors, {
	model: 'distributor',
	columns: [
		{
			title: 'Naziv',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'E-Mail',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Web Sajt',
			dataIndex: 'website',
			key: 'website',
		},
	],
	createButtonText: 'Dodaj Distributera',
	titles: {
		create: 'Dodaj Distributera',
		edit: 'Izmeni Distributera',
		delete: 'Da li ste sigurni da želite da obrišete ovog distributera?',
		view: 'Pregled Distributera',
	},
	view: [
		{
			key: 'info',
			label: 'Informacije',
			column: 8,
			fields: [
				{
					key: 'name',
					label: 'Naziv',
					span: 8,
				},
				{
					key: 'address',
					label: 'Adresa',
					span: 8,
				},
				{
					key: 'email',
					label: 'E-Mail',
					span: 4,
				},
				{
					key: 'website',
					label: 'Web Sajt',
					span: 4,
				},

			],
		},
		{
			key: 'contacts',
			label: 'Kontakti',
			column: 8,
			fields: [
				{
					key: 'phones',
					span: 8,
					component: <ContactsView />,
				},

			],
		},
	],
	formFields: [
		{
			key: 'row1',
			fields: [
				{
					key: 'name',
					label: 'Naziv',
					rules: [{ required: true, message: 'Naziv je obavezan' }],
					component: <Input />,
				},
			],
		},
		{
			key: 'row2',
			fields: [
				{
					key: 'address',
					label: 'Adresa',
					rules: [],
					component: <Input.TextArea />,
				},
			],
		},
		{
			key: 'row3',
			fields: [
				{
					key: 'email',
					label: 'E-Mail',
					rules: [{ type: 'email', message: 'E-Mail nije ispravan' }],
					component: <Input />,
					span: 12,
				},
				{
					key: 'website',
					label: 'Web Sajt',
					rules: [],
					component: <Input />,
					span: 12,
				},
			],
		},
		{
			key: 'row4',
			fields: [
				{
					key: 'phones',
					label: 'Kontakti',
					rules: [],
					component: <ContactsInput />,
					span: 24,
				},

			],
		},
	],
});
