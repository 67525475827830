import React, { useState, useEffect } from 'react';
import { Select, Row, Col, Input } from 'antd';
import { useEffectOnce } from 'react-use';
import { Distributor } from '../../../../../stores/Distributor';

interface Props {
	value?: {
		model: string,
		number: string,
	};
	onChange?: Function;
}

function ReferenceInput({ value, onChange }: Props) {
	const [model, setModel] = useState('');
	const [number, setNumber] = useState('');

	useEffect(() => {
		setModel(value.model);
		setNumber(value.number);
	}, [value]);

	function triggerChange(changedValue) {
		onChange({
			model,
			number,
			...changedValue,
		});
	}

	return (
		<Row gutter={2}>
			<Col span={4}>
				<Input
					style={{ width: '100%' }}
					value={model}
					onChange={(event) => {
						setModel(event.target.value);
						triggerChange({ model: event.target.value });
					}}
				/>
			</Col>
			<Col span={20}>
				<Input
					style={{ width: '100%' }}
					value={number}
					onChange={(event) => {
						setNumber(event.target.value);
						triggerChange({ number: event.target.value });
					}}
				/>
			</Col>
		</Row>
	);
}
ReferenceInput.defaultProps = {
	value: {
		model: '',
		number: '',
	},
	onChange: () => {},
};

export default ReferenceInput;
