import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import numeral from 'numeral';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Drawer, Input, Table, ConfigProvider } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { LocalSale, LocalSaleItem } from '../../../stores/LocalSale';

type Props = {
	form?: any,
	visible: boolean,
	sale?: typeof LocalSale.Type,
	closeSearch: Function,
	search?: {
		search: Function,
	},
	onRowClick: Function,
};

type State = {
	data: any,
	height: number,
	query: string,
	selectedIndex: number,
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 24 },
	},
};

@inject(({ store }, props) => ({
	search: store.productStore.fuseSearch,
}))
@observer
export default class Search extends Component<Props, State> {
	static defaultProps = {
		visible: false,
	};

	state = {
		data: [],
		query: '',
		height: 0,
		selectedIndex: 0,
	};

	componentDidMount = () => {
		window.addEventListener('resize', this.updateDimensions);
		document.addEventListener('keydown', this.handleKeyDown);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateDimensions);
	};

	componentDidUpdate = (previousProps) => {
		if (!previousProps.visible && this.props.visible) {
			setImmediate(() => {
				this.updateDimensions();
			});
		}
	};

	handleKeyDown = (e: KeyboardEvent) => {
		const { data, selectedIndex } = this.state;
		const { onRowClick, closeSearch, visible } = this.props;

		if (!visible) {
			return;
		}

		if (e.keyCode === 38) {
			// Up arrow
			this.setState((state) => ({
				selectedIndex:
					state.selectedIndex > 0 ? state.selectedIndex - 1 : 0,
			}));
		}

		if (e.keyCode === 40) {
			// Down arrow
			this.setState((state) => ({
				selectedIndex:
					state.selectedIndex < Math.floor(state.height / 39) - 1
						? state.selectedIndex + 1
						: Math.floor(state.height / 39) - 1,
			}));
		}

		if (e.keyCode === 13) {
			onRowClick(data[selectedIndex]);
			closeSearch();
		}
	};

	updateDimensions = () => {
		const element = document.querySelector('.ant-drawer-header');
		if (!element) {
			return false;
		}

		const { clientHeight } = element;
		this.setState({
			height: window.innerHeight - clientHeight - 200,
		});
	};

	render() {
		const { sale, visible, closeSearch, onRowClick } = this.props;

		if (!visible) {
			return null;
		}

		const columns = [
			{
				title: '',
				width: 32,
				render: (text, record, index) =>
					index === this.state.selectedIndex ? (
						<CaretRightOutlined className="active" />
					) : (
						<div />
					),
			},
			{
				title: 'Šifra',
				dataIndex: 'sku',
				width: 80,
			},
			{
				title: 'Naziv',
				dataIndex: 'name',
			},
			{
				title: 'Skraćeni Naziv',
				dataIndex: ['imported', 'shortName'],
			},
			{
				title: 'Cena',
				key: 'price',
				dataIndex: ['imported', 'price'],
				render: (text) => `${numeral(text).format('0,0.00')}`,
			},
			{
				title: 'Bar kod',
				key: 'ean',
				width: 120,
				render: (record) =>
					record.imported && record.imported.barcodes[0]
						? record.imported.barcodes[0].barcode
						: '',
			},
		];

		const { height, data } = this.state;
		return (
			<Drawer
				width={1280}
				title="Pretraga"
				visible={visible}
				onClose={() => {
					closeSearch();
				}}
			>
				<Form {...formItemLayout}>
					<Form.Item>
						<Input.Search
							ref={(input: any) => {
								if (input) {
									input.focus();
								}
							}}
							placeholder="Unesite naziv, skraćeni naziv, šifru ili bar kod"
							onChange={({ target: { value } }) => {
								const { search } = this.props;
								this.setState(() => ({
									data: search.search(value),
									selectedIndex: 0,
								}));
							}}
						/>
					</Form.Item>
				</Form>

				<Table
					onRow={(record) => ({
						onClick: () => {
							onRowClick(record);
							closeSearch();
						}, // click row
					})}
					size="small"
					columns={columns}
					dataSource={data}
					bordered
					pagination={{
						pageSize: Math.floor(height / 39),
					}}
					rowKey="id"
				/>
			</Drawer>
		);
	}
}
