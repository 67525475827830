import { types, flow, applySnapshot } from 'mobx-state-tree';
import { CrudStore } from './Crud';

export const cache = false;

export const DistributorPhone = types
	.model('DistributorPhone', {
		name: types.maybeNull(types.string),
		role: types.maybeNull(types.string),
		phone: types.maybeNull(types.string),
		email: types.maybeNull(types.string),
	});

export const Distributor = types
	.model('Distributor', {
		id: types.identifierNumber,
		name: types.string,
		website: types.maybeNull(types.string),
		email: types.maybeNull(types.string),
		address: types.maybeNull(types.string),
		phones: types.union(types.array(DistributorPhone), types.null, types.undefined),
	});

export const DistributorStore = CrudStore(
	'distributor',
	Distributor,
	undefined,
	'v1'
);
/*
types
	.model('DistributorStore', {
		distributors: types.optional(types.array(Distributor), []),
		fetched: types.optional(types.boolean, false),
		loading: types.optional(types.boolean, false),
		error: types.maybeNull(types.string),
		cache: types.optional(types.boolean, true),
	})
	.actions(self => {
		const actions = {
			afterAuth: () => { },
			fetchDistributors: flow(function* fetchDistributors() {
				self.loading = true;
				try {
					const { data: distributors } : any = yield defaultClient.get('/distributors');
					applySnapshot(self.distributors, distributors);

					self.loading = false;
					self.fetched = true;
				} catch (error) {
					self.error = error.message;
					self.loading = false;
				}
			}),
		};

		return actions;
	});
*/
