// @flow
import React from 'react';
import {
	types,
	flow,
	destroy,
	getParent,
	clone,
	applySnapshot,
	cast,
	getRoot,
} from 'mobx-state-tree';
import numeral from 'numeral';

import { number as MSTNumber } from 'mobx-state-tree/dist/types/primitives';

import { Promise } from 'bluebird';
import moment from 'moment';

import forEach from 'lodash/forEach';
import round from 'lodash/round';

import localforage from 'localforage';
import { notification, Progress, Modal } from 'antd';
import { defaultClient, ecrServiceClient, v2Client } from '../store/client';

import { Product } from './Product';
import { Delivery } from './Delivery';
import { Discount, DiscountStore } from './Discount';

const outOfPaperQueue = [];
let outOfPaper = false;

let retryInterval = null;

export const LocalSaleItem = types
	.model('LocalSaleItem', {
		id: types.identifierNumber,
		product: types.reference(Product),
		amount: types.number,
		price: types.number,
		discount: types.optional(types.number, 0),
	})
	.actions((self) => {
		const updateDiscount = function updateDiscount(discount) {
			self.discount = discount;
		};
		return {
			updateDiscount,
		};
	})
	.views((self) => {
		const views = {
			get finalPrice() {
				return self.discount
					? round(self.price * (1 - self.discount / 100), 2)
					: self.price;
			},
		};

		return views;
	});

export const Payment = types.model('Payment', {
	cash: types.optional(types.number, 0),
	check: types.optional(types.number, 0),
	card: types.optional(types.number, 0),
	change: types.optional(types.number, 0),
	waitForCard: types.optional(types.boolean, false),
	delivery: types.maybeNull(types.reference(Delivery, {
		get(identifier, store) {
			const {deliveryStore} = getRoot(store) as any;
			return deliveryStore.mappedById[identifier];
		},
		set(value) {
			return value.id;
		},
	})),
});

export const OutOfPaperItem = types.model('OutOfPaperItem', {
	url: types.string,
	data: types.maybeNull(types.frozen()),
});
export const SyncFailItem = types.model('SyncFailItem', {
	url: types.string,
	data: types.maybeNull(types.frozen()),
});

export const LocalSale = types
	.model('LocalSale', {
		items: types.map(LocalSaleItem),
		activeProductId: types.maybeNull(types.number),
		id: types.identifierNumber,
		payment: types.optional(Payment, {}),
		printing: types.optional(types.boolean, false),
		completed: types.optional(types.boolean, false),
		fiscalReceiptNumber: types.maybeNull(types.number),
		// outOfPaperQueue: types.array(OutOfPaperItem),
		// outOfPaper: types.optional(types.boolean, false),
	})
	.views((self) => {
		const views = {
			get itemsAsArray() {
				return Array.from(self.items.values());
			},
			get activeSaleItem() {
				return self.items.get(`${self.activeProductId}`);
			},
			get total() {
				return round(
					views.itemsAsArray.reduce(
						(prev, curr) =>
							prev + round((curr.amount || 0) * curr.finalPrice, 2),
						0
					),
					2
				);
			},
		};
		return views;
	})
	.actions((self) => {
		const addItem = function addItem(product, amount, overridePrice) {
			if (self.printing) {
				return false;
			}
			if (self.items.size >= 120) {
				// We reached the limit
				throw new Error('Dozvoljen je unos od najviše 120 artikala po računu.');
			}
			
			const {discounts} = ((getRoot(self) as any).discountStore as any);
			const discount = discounts.reduce((prev, curr) => {
				if (curr.shouldApply(product)) {
					prev += curr.percentage;
				}
				return prev;
			}, 0);

			// add product to ecr if it doesn't exist
			if (self.items.has(product.id)) {
				const current = self.items.get(product.id);
				self.items.set(product.id, {
					...current,
					amount: current.amount + amount,
					discount,
				});
			} else {
				const productPrice = product.imported ? product.imported.price : product.price || 0;
				self.items.put({
					id: product.id,
					price: overridePrice || productPrice,
					product,
					amount,
					discount,
				});
			}

			self.activeProductId = product.id;
			return true;
		};

		const removeItem = function removeItem(productId) {
			const items = Array.from(self.items).map((item) => parseInt(item[0], 10));
			const currentIndex = items.findIndex(
				(value) => value === self.activeProductId
			);

			if (currentIndex === items.length - 1) {
				selectPreviousItem();
			} else {
				selectNextItem();
			}

			if (self.items.has(productId)) {
				self.items.delete(productId);
			}
		};

		const selectPreviousItem = function selectPreviousItem() {
			const items = Array.from(self.items).map((item) => parseInt(item[0], 10));
			const currentIndex = items.findIndex(
				(value) => value === self.activeProductId
			);

			if (currentIndex === 0) {
				self.activeProductId = items[items.length - 1];
			} else {
				self.activeProductId = items[currentIndex - 1];
			}
		};

		const selectNextItem = function selectNextItem() {
			const items = Array.from(self.items).map((item) => parseInt(item[0], 10));
			const currentIndex = items.findIndex(
				(value) => value === self.activeProductId
			);

			if (currentIndex === items.length - 1) {
				self.activeProductId = items[0];
			} else {
				self.activeProductId = items[currentIndex + 1];
			}
		};

		const updateQuantity = function updateQuantity(
			productId: number,
			amount: number
		) {
			if (self.items.has(`${productId}`)) {
				const product = self.items.get(`${productId}`);
				self.items.set(`${productId}`, {
					...product,
					amount: amount || 0,
				});
			}
			self.activeProductId = productId;
		};

		const updatePayment = function updatePayment(
			cash: number,
			check: number,
			card: number,
		): void {
			if (self.printing) {
				console.log('Štamapa računa je u toku, ne može se izmeniti plaćanje');
				return;
			}
			const newCash = cash !== null ? cash || 0 : self.payment.cash;
			const newCheck = check !== null ? check || 0 : self.payment.check;
			const newCard = card !== null ? card || 0 : self.payment.card;
			debugger;
			applySnapshot(self.payment, {
				cash: newCash,
				check: newCheck,
				card: newCard,
				change: Math.max(
					0,
					round(
						Number(newCash) + Number(newCheck) + Number(newCard) - self.total,
						2
					)
				),
				delivery: self.payment.delivery ? self.payment.delivery.id : null,
			});

			
		};

		const updateDelivery = function updateDelivery(
			delivery: number,
		): void {
			applySnapshot(self.payment, { ...self.payment, delivery });
		};

		const recordSale = function recordSale(fiscalReceiptNumber) {
			notification.success({
				key: 'paymentSuccess',
				message: 'Račun je odštampan',
				description: Math.round(self.payment.change) ? (
					<span>
						Izvršite povraćaj u iznosu od{' '}
						<strong>{numeral(self.payment.change).format('0,0.00')} RSD</strong>
						.
					</span>
				) : undefined,
				duration: 10,
			});

			(self as any).saveWithFailHandler('/stores/2/sales', {
				timestamp: moment().toISOString(),
				fiscalReceiptNumber,
				items: self.itemsAsArray
					.map(item => ({
						productId: item.product.id,
						quantity: item.amount,
						grossPrice: item.finalPrice * item.amount,
					})),
				payment: {
					cash: round(self.payment.cash - self.payment.change, 2),
					check: self.payment.check,
					card: self.payment.card,
				},
				deliveryId: self.payment.delivery ? self.payment.delivery.id : null,
			});

			self.printing = false;
			self.completed = true;

			return (getParent(getParent(self)) as any).removeSale(self);
		};

		const saveWithFailHandler = async function saveWithFailHandler(url, data = null) {
			const parent = (getParent(getParent(self)) as any);
			try {
				const saleResponse = await v2Client.post(url, data);
			} catch (e) {
				parent.addToFailedQueue(url, data);
			}
		};

		const ecrWithPaperHandler = flow(function* ecrWithPaperHandler(
			url,
			data = null
		) {
			if (outOfPaper) {
				return outOfPaperQueue.push({ url, data });
			}

			const status = yield ecrServiceClient.get('/status');
			if (status.data.outOfPaper) {
				outOfPaperQueue.push({ url, data });
				outOfPaper = true;
				(self as any).showOutOfPaperModal();
				return Promise.resolve();
			}

			const response = yield ecrServiceClient.post(url, data);
			if (url === '/receipt/close') {
				self.fiscalReceiptNumber = parseInt(response.data.fiscalReceipt, 10);
			}
			if (response.data.outOfPaper) {
				outOfPaper = true;

				(self as any).showOutOfPaperModal();
			}

			return response;
		});

		function setFiscalReceiptNumber(receiptNumber) {
			self.fiscalReceiptNumber = receiptNumber;
		}

		function showOutOfPaperModal() {
			Modal.warning({
				title: 'Nema papira u fiskalnoj kasi',
				content: 'Ubacite papir u fiskalnu kasu i pritisnite "U redu"',
				closable: false,
				centered: true,
				maskClosable: false,
				onOk: async () => {
					const status = await ecrServiceClient.get('/status');
					if (status.data.outOfPaper) {
						return showOutOfPaperModal();
					}

					const tempQueue = [...outOfPaperQueue];

					outOfPaperQueue.splice(0);
					outOfPaper = false;
					await tempQueue.reduce(async (prev, curr) => {
						await prev;
						const lastResult = await (self as any).ecrWithPaperHandler(
							curr.url,
							curr.data
						);
						if (curr.url === '/receipt/close') {
							(self as any).setFiscalReceiptNumber(
								parseInt(lastResult.data.fiscalReceipt, 10)
							);
						}
						return lastResult;
					}, Promise.resolve());

					if (!outOfPaper) {
						return (self as any).recordSale(self.fiscalReceiptNumber);
					}

					return false;
				},
			});
		}

		const cancelPos = flow(function *cancelPos() {
			const response = yield ecrServiceClient.post('/pos/cancel');
			self.payment.waitForCard = false;
			return response;
		});

		const completeSale = flow(function* completeSale() {
			let response;
			try {
				if (self.payment.card > 0) {
					try {
						self.payment.waitForCard = true;
						response = yield ecrServiceClient.post('/pos/pay', {
							amount: self.payment.card,
						});
						self.payment.waitForCard = false;
					} catch (e) {
						self.payment.waitForCard = false;
						return notification.error({
							key: 'saleError',
							message: 'Greška',
							description:
					'Transakcija putem POS terminala nije uspela. Molimo pokušajte opet.',
							duration: 10,
						});
					}
				}
				self.printing = true;
				// Check if the product exists in the ECR
				yield Promise.each(self.itemsAsArray, async (item) =>
					ecrServiceClient.post('/product/add', {
						sku: item.product.sku,
						price: item.price,
						tax: item.product.imported.tax,
						ean: item.product.imported.barcodes[0]
							? item.product.imported.barcodes[0].barcode
							: 0,
						name: item.product.imported.shortName,
					})
				);

				yield (self as any).ecrWithPaperHandler('/receipt/open', undefined);
				yield Promise.each(self.itemsAsArray, (item) =>
					(self as any).ecrWithPaperHandler('/receipt/add', {
						sku: item.product.sku,
						quantity: item.amount,
						price: item.finalPrice,
					})
				);

				if (self.payment.card > 0) {
					yield ecrWithPaperHandler('/receipt/total', {
						paidMode: 'D',
						amount: self.payment.card,
					});
				}
				if (self.payment.check > 0 && self.payment.card < self.total) {
					yield ecrWithPaperHandler('/receipt/total', {
						paidMode: 'C',
						amount: self.payment.check,
					});
				} else {
					self.payment.check = 0;
				}

				if (
					self.payment.cash > 0 &&
          self.payment.card + self.payment.check < self.total
				) {
					yield ecrWithPaperHandler('/receipt/total', {
						paidMode: 'P',
						amount: self.payment.cash,
					});
				} else {
					self.payment.cash = 0;
				}

				response = yield ecrWithPaperHandler('/receipt/close', undefined);
				if (response && response.data) {
					self.fiscalReceiptNumber = parseInt(response.data.fiscalReceipt, 10);
				}

				// TODO: Store fiscal receipt data
			} catch (error) {
				self.printing = false;
				return notification.error({
					key: 'saleError',
					message: 'Greška',
					description:
            'Dogodila se greška u komunikaciji sa fiskalnom kasom. Proverite da li se kasa nalazi u režimu "PC VEZA"',
					duration: 10,
				});
			}
			if (!outOfPaper) {
				return recordSale(self.fiscalReceiptNumber);
			}

			return true;
		});

		return {
			setFiscalReceiptNumber,
			recordSale,
			showOutOfPaperModal,
			ecrWithPaperHandler,
			completeSale,
			removeItem,
			addItem,
			updateQuantity,
			updatePayment,
			selectPreviousItem,
			selectNextItem,
			saveWithFailHandler,
			updateDelivery,
			cancelPos,
		};
	});

export const LocalSaleStore = types
	.model('LocalSalesStore', {
		active: types.number,
		sales: types.array(LocalSale),
		saleCount: types.number,
		fetched: types.boolean,
		loading: types.boolean,
		failedSales: types.array(SyncFailItem),
		cache: types.optional(types.boolean, true),
	})
	.actions((self) => {
		const removeFailed = function removeFailed(sale) {
			destroy(sale);
		};

		return {
			removeFailed,
		};
	})
	.actions((self) => {
		const createSale = function createSale() {
			self.saleCount += 1;
			const sale = LocalSale.create({
				items: {},
				id: self.saleCount,
				activeProductId: null,
			});

			self.sales.push(sale);
			self.active = self.saleCount;

			return sale;
		};

		const setActive = function setActive(id) {
			self.active = id;
		};

		const removeSale = function removeSale(sale) {
			const activeSaleIndex = self.sales.findIndex((s) => s.id === self.active);
			let previousSale;
			if (activeSaleIndex > 0) {
				previousSale = self.sales[activeSaleIndex - 1];
			} else if (activeSaleIndex < self.sales.length - 1) {
				previousSale = self.sales[activeSaleIndex + 1];
			}

			destroy(sale);

			if (!previousSale || previousSale.items.size > 0) {
				return createSale();
			}

			self.active = previousSale.id;
		};

		const retryFailed = flow(function* retryFailed() {
			clearInterval(retryInterval);
			retryInterval = null;
			const count = self.failedSales.length;
			let current = 0;
			let errorCount = 0;
			notification.info({
				key: 'saleSync',
				message: 'Sinhronizacija prodaja',
				description: (
					<span>
						<Progress percent={0} status="active" />
					</span>
				),
				duration: 0,
			});
			yield Promise.each(self.failedSales, async (sale) => {
				try {
					const { url, data } = sale;
					const saleResponse = await v2Client.post(url, data);

					self.removeFailed(sale);
				} catch (apiError) {
					console.log(apiError);
					errorCount += 1;
				}
				current += 1;
				notification.info({
					key: 'saleSync',
					message: 'Sinhronizacija prodaja',
					description: (
						<span>
							<Progress percent={(current / count) * 100} status="active" />
						</span>
					),
					duration: 0,
				});
			});

			if (self.failedSales.length === 0) {
				notification.success({
					key: 'saleSync',
					message: 'Sinhronizacija uspešna',
					description: 'Prodaje su uspešno sinhronozovane sa serverom.',
					duration: 10,
				});
			} else {
				console.log(123);
				retryInterval = setInterval(() => {
					retryFailed();
				}, 60000);

				if (errorCount > 0) {
					notification.warning({
						key: 'saleSync',
						message: 'Sinhronizacija neuspešna',
						description: `Sinhronizacija nije potpuno završena. Broj grešaka: ${errorCount} `,
						duration: 0,
					});
				}
			}
		});
		const ecrUnblock = flow(function* ecrUnblock() {
			try {
				yield ecrServiceClient.get('/unblock');
				notification.success({
					key: 'unblockError',
					message: 'Kasa je odblokirana',
					description: 'Kasa je uspešno odblokirana',
					duration: 10,
				});
			} catch (error) {
				notification.error({
					key: 'unblockError',
					message: 'Greška',
					description: error.description,
					duration: 10,
				});
			}
		});

		return {
			ecrUnblock,
			createSale,
			setActive,
			removeSale,
			retryFailed,
			afterAuth: () => {},
		};
	})
	.actions((self) => {
		const afterHydrate = function afterHydrate() {
			if (!self.sales.length) {
				self.createSale();
			}

			if (self.failedSales.length > 0) {
				if (!retryInterval) {
					retryInterval = setInterval(() => {
						self.retryFailed();
					}, 60000);
				}
			}
		};

		const addToFailedQueue = function addToFailedQueue(url, data) {
			if (!retryInterval) {
				retryInterval = setInterval(() => {
					self.retryFailed();
				}, 60000);
			}
			self.failedSales.push({ url, data });
		};

		return {
			afterHydrate,
			addToFailedQueue,
		};
	});
