import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

type Props = {
	isAuthenticated: boolean,
	location: String,
}

function withAuth(WrappedComponent, protectedRoute) {
	function Wrapper(props: Props) {
		const { isAuthenticated } = props;
		if (isAuthenticated && !protectedRoute) {
			return (
				<Redirect
					to={{
						pathname: '/app',
						state: { from: props.location },
					}}
				/>
			);
		}

		if (!isAuthenticated && protectedRoute) {
			return (
				<Redirect
					to={{
						pathname: '/',
						state: { from: props.location },
					}}
				/>
			);
		}

		return <WrappedComponent {...props} />;
	}

	return withRouter(inject(({ store: { userStore: { token } } }) => ({
		isAuthenticated: !!token,
	}))(
		observer(
			Wrapper
		)

	));
}


export default withAuth;
