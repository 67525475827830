// @flow
import {
	types, flow,
} from 'mobx-state-tree';

import moment from 'moment';

import keyBy from 'lodash/keyBy';

import { v2Client } from '../store/client';

import { Product } from './Product';

export const SaleItem = types
	.model('SaleItem', {
		id: types.identifierNumber,
		product: types.safeReference(Product),
		productId: types.safeReference(Product),
		quantity: types.number,
		grossPrice: types.number,
	})
	.preProcessSnapshot(
		snapshot => {
			snapshot.product = snapshot.productId;
			return snapshot;
		}
	)
	.actions(self => {
		const actions = {
		};

		return actions;
	})
	.views(self => ({

	}));

// export default SaleItem;
