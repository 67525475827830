import _ from 'lodash';
import pluralize from 'pluralize';

export default function (property, baseUrl) {
	const propertyPlural = pluralize(property);
	const propertyUppercase = _.upperCase(property);
	const propertyPluralUppercase = _.upperCase(propertyPlural);
	const propertyPluralCapitalized = _.upperFirst(propertyPlural);
	const propertyCapitalized = _.upperFirst(property);

	const url = baseUrl || `/${propertyPlural}`;
	return {
		[`fetch${propertyPluralCapitalized}`]() {
			return {
				type: `FETCH_${propertyPluralUppercase}`,
				payload: {
					request: {
						url,
					},
				},
			};
		},
		[`fetch${propertyCapitalized}`](data) {
			return {
				type: `FETCH_${propertyUppercase}`,
				payload: {
					request: {
						url: `${url}/${data.id}`,
					},
				},
			};
		},
		[`create${propertyCapitalized}`](data) {
			return {
				type: `CREATE_${propertyUppercase}`,
				payload: {
					request: {
						url,
						method: 'post',
						data,
					},
				},
			};
		},
		[`update${propertyCapitalized}`](data) {
			return {
				type: `UPDATE_${propertyUppercase}`,
				payload: {
					request: {
						url: `${url}/${data.id}`,
						method: 'put',
						data,
					},
				},
			};
		},
		[`delete${propertyCapitalized}`](id) {
			return {
				type: `DELETE_${propertyUppercase}`,
				payload: {
					request: {
						url: `${url}/${id}`,
						method: 'delete',
						id,
					},
				},
			};
		},
		[`fetch${propertyCapitalized}Items`](id) {
			return {
				type: `FETCH_${propertyUppercase}_ITEMS`,
				payload: {
					request: {
						url: `${url}/${id}/items`,
					},
				},
			};
		},
		[`fetch${propertyCapitalized}Item`](id, data) {
			return {
				type: `FETCH_${propertyUppercase}_ITEM`,
				payload: {
					request: {
						url: `${url}/${id}/items/${data.id}`,
					},
				},
			};
		},
		[`create${propertyCapitalized}Item`](id, data) {
			return {
				type: `CREATE_${propertyUppercase}_ITEM`,
				payload: {
					request: {
						url: `${url}/${id}/items`,
						method: 'post',
						data,
					},
				},
			};
		},
		[`update${propertyCapitalized}Item`](id, data) {
			return {
				type: `UPDATE_${propertyUppercase}_ITEM`,
				payload: {
					request: {
						url: `${url}/${id}/items/${data.id}`,
						method: 'put',
						data,
					},
				},
			};
		},
		[`delete${propertyCapitalized}Item`](parentId, id) {
			return {
				type: `DELETE_${propertyUppercase}_ITEM`,
				payload: {
					request: {
						url: `${url}/${parentId}/items/${id}`,
						method: 'delete',
						id,
					},
				},
			};
		},
	};
}
