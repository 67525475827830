import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useWindowSize } from 'react-use';
import numeral from 'numeral';
import moment from 'moment';

import { Table } from 'antd';

import { SaleDay } from '../../../../../stores/SaleDay';
import { SaleItem } from '../../../../../stores/SaleItem';

import styles from './SalesDrawer.module.less';

type Props = {
	saleDay: typeof SaleDay.Type,
	saleItems?: typeof SaleItem.Type[],
	visible: boolean,
};

const columns = [
	{
		title: 'Šifra', dataIndex: ['product', 'sku'], key: 'product.sku', width: 70,
	},
	{
		title: 'Naziv', dataIndex: ['product', 'name'], key: 'product.name',
	},
	{
		title: 'Količina', dataIndex: 'quantity', key: 'quantity', width: 80,
	},
	{
		title: 'Iznos', dataIndex: 'grossPrice', key: 'grossPrice', width: 120, render: text => numeral(text).format('0,0.00'),
	},
];

function AllSalesTable(props: Props) {
	const {
		saleDay,
		visible,
		saleItems,
	} = props;

	const { height } = useWindowSize();

	useEffect(() => {
		if (visible) {
			saleDay.fetchSaleItems();
		}
	}, [visible]);

	return (
		<Table
			columns={columns}
			dataSource={saleItems}
			rowKey="id"
			pagination={false}
			bordered
			size="middle"
			scroll={{ y: height - 148 }}
		/>
	);
}

export default inject(
	(_, { saleDay: { summedSaleItems } }: Props) => ({
		saleItems: summedSaleItems,
		length: summedSaleItems.length,
	})
)(
	observer(AllSalesTable)
);
