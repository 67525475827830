import React, {
	Component, Fragment, ReactNode, ReactElement,
} from 'react';
import { inject, observer } from 'mobx-react';
import { PlusOutlined } from '@ant-design/icons';
import {
	Button,
} from 'antd';

import { DescriptionsProps } from 'antd/es/descriptions/';


import { User } from '../stores/User';

import CrudPage from '../components/CrudPage/mobx';

type Props = {
	user: typeof User.Type
};

type State = {
	createVisible: boolean,
	viewVisible: boolean,
};

type Options = {
	model: string,
	columns: any,
	acl?: {
		superadmin?: {
			create?: boolean,
			read?: boolean,
			update?: boolean,
			delete?: boolean,
		},
		admin?: {
			create?: boolean,
			read?: boolean,
			update?: boolean,
			delete?: boolean,
		},
		employee?: {
			create?: boolean,
			read?: boolean,
			update?: boolean,
			delete?: boolean,
		},
		member?: {
			create?: boolean,
			read?: boolean,
			update?: boolean,
			delete?: boolean,
		},
	},
	createButtonText: string,
	titles: {
		create: string,
		edit: string,
		delete: string,
		view: string,
	},
	formFields: Array<{
		key: string,
		fields: Array<{
			label?: String,
			key?: string,
			rules?: any,
			component: ReactNode,
			span?: number,
			initialValue?: any,
		}>
	}>,
	view: Array<{
		key: string,
		label: string,
		column: DescriptionsProps['column'],
		fields: Array<{
			label?: String,
			key?: string,
			component?: ReactElement,
			span?: number,
		}>
	}>,
};

const defaultAcl = {
	superadmin: {
		create: true,
		read: true,
		update: true,
		delete: true,
	},
	admin: {
		create: true,
		read: true,
		update: true,
		delete: true,
	},
	employee: {
		create: true,
		read: true,
		update: false,
		delete: false,
	},
	member: {
		create: false,
		read: false,
		update: false,
		delete: false,
	},
};

function withCrud(WrappedComponent, {
	model,
	columns,
	acl,
	createButtonText,
	titles,
	formFields,
	view,
}: Options) {
	@inject((stores: any) => ({
		user: stores.store.userStore.authenticatedUser,
	}))
	@observer
	class Wrapper extends Component<Props, State> {
		acl: Options['acl'] = {
			...defaultAcl,
			...acl,
		};

		state = {
			createVisible: false,
			viewVisible: false,
		};

		canCreate() {
			const { user: { level } } = this.props;
			return this.acl[level].create;
		}

		canUpdate() {
			const { user: { level } } = this.props;
			return this.acl[level].update;
		}

		canDelete() {
			const { user: { level } } = this.props;
			return this.acl[level].delete;
		}

		canRead() {
			const { user: { level } } = this.props;
			return this.acl[level].read;
		}

		renderHeader() {
			return (
				<Fragment>
					{this.canCreate() && (
						<Button
							onClick={() => {
								this.setState({
									createVisible: true,
								});
							}}
							icon={<PlusOutlined />}
						>{createButtonText}
						</Button>
					)}
				</Fragment>
			);
		}

		render() {
			const { createVisible, viewVisible } = this.state;
			return (
				<CrudPage
					model={model}
					columns={columns}
					titles={titles}
					createVisible={createVisible}
					viewVisible={viewVisible}
					formFields={formFields}
					canCreate={() => this.canCreate()}
					canUpdate={() => this.canUpdate()}
					canDelete={() => this.canDelete()}
					canRead={() => this.canRead()}
					view={view}
					setCreateVisible={visible => {
						this.setState({
							createVisible: visible,
						});
					}}
					setViewVisible={visible => {
						this.setState({
							viewVisible: visible,

						});
					}}
				>
					<WrappedComponent />
				</CrudPage>
			);
		}
	}

	return Wrapper;
}


export default withCrud;
