export function fetchSoldItems() {
	return {
		type: 'ECR_FETCH_SOLD_ITEMS',
		payload: {
			client: 'ecr',
			request: {
				url: '/soldItems',
			},
		},
	};
}

export function fetchTotals() {
	return {
		type: 'ECR_FETCH_TOTALS',
		payload: {
			client: 'ecr',
			request: {
				url: '/totals',
			},
		},
	};
}
