import { types, flow, applySnapshot } from 'mobx-state-tree';
import moment from 'moment';
import { CrudStore } from './Crud';
import type {Product} from './Product';

export const cache = false;

const DiscountRules = types
	.model('DiscountRule', {
		products: types.maybeNull(types.array(types.number)),
		excludeDiscounts: types.boolean,
	})

export const Discount = types
	.model('Discount', {
		id: types.identifierNumber,
		name: types.string,
		percentage: types.maybeNull(types.number),
		fixed: types.maybeNull(types.number),
		rules: DiscountRules,
		beginAt: types.maybeNull(types.string),
		endAt: types.maybeNull(types.string)
	})
	.actions(self => ({
		shouldApply(product) {
			if (moment().isAfter(self.endAt)) {
				return false;
			}
			if (product.imported && product.imported.discount && product.imported.discount !== '' && self.rules.excludeDiscounts) {
				return false;
			}

			if (!self.rules.products) {
				return true;
			}

			return false;

			// TODO: check for products
		}
	}))

export const DiscountStore = CrudStore(
	'discount',
	Discount,
	undefined,
	'v2'
)
	.actions(self => ({
		afterAuth() {
			setImmediate(() => {
				self.fetchAll();
				setInterval(() => {
					self.fetchAll()
				}, 60*60*1000)
			});
		}
	}))