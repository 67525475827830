import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useWindowSize } from 'react-use';
import moment from 'moment';

import {
	Drawer, Alert, Spin, Tabs,
} from 'antd';

import { SaleDay } from '../../../../../stores/SaleDay';

import SalesTable from './SalesTable';
import AllSalesTable from './AllSalesTable';

import styles from './SalesDrawer.module.less';

const { TabPane } = Tabs;

type Props = {
	saleDay: typeof SaleDay.Type,
	visible?: boolean,
	onClose: () => void,
};

function SalesDrawer(props: Props) {
	const {
		saleDay,
		visible = false,
		onClose,
	} = props;

	const { height, width } = useWindowSize();

	const title = saleDay
		? `Prodaje na dan ${moment(saleDay.timestamp).format('LL')}`
		: '';

	return (
		saleDay && (
			<Drawer
				visible={visible}
				width={width < 800 ? width : 800}
				title={title}
				onClose={onClose}
				className={styles.drawer}
			>
				<Spin
					spinning={saleDay.loading}
				>
					{saleDay.error && <Alert message={saleDay.error} type="error" />}
					<Tabs
						defaultActiveKey="1"
						tabBarGutter={16}
					>
						<TabPane
							style={{ height: height - 100 }}
							tab="Svi prodati"
							key="1"
						>
							<AllSalesTable
								saleDay={saleDay}
								visible={visible}
							/>
						</TabPane>
						<TabPane
							style={{ height: height - 100 }}
							tab="Po prodaji"
							key="2"
						>
							<SalesTable
								saleDay={saleDay}
								visible={visible}
							/>
						</TabPane>
					</Tabs>

				</Spin>
			</Drawer>
		)
	);
}

export default inject(
	(_, { saleDay }: Props) => ({
		saleDay,
	})
)(
	observer(SalesDrawer)
);
