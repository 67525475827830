import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import numeral from 'numeral';
import { useWindowSize } from 'react-use';

import {
	Table, Row, Col, Statistic, Card, Descriptions,
} from 'antd';

import { SaleDay } from '../../../../../stores/SaleDay';
import { Sale } from '../../../../../stores/Sale';

import styles from './SalesDrawer.module.less';

type Props = {
	saleDay: typeof SaleDay.Type,
	sales?: typeof Sale.Type[],
	visible: boolean,
};

const columns = [
	{
		title: '#', dataIndex: 'id', key: 'id', render: (text, record) => record.fiscalReceiptNumber || record.id, width: 80,
	},
	{
		title: 'Vreme', dataIndex: 'timestamp', key: 'timestamp', render: (text: string) => moment(text).format('LT'),
	},
	{
		title: 'Iznos', dataIndex: 'paidTotal', key: 'paidTotal', render: text => numeral(text).format('0,0.00'), width: 120,
	},
];
const expandedColumns = [
	{
		title: 'Šifra', dataIndex: ['product', 'sku'], key: 'product.sku', width: 70,
	},
	{
		title: 'Naziv', dataIndex: ['product', 'name'], key: 'product.name',
	},
	{
		title: 'Količina', dataIndex: 'quantity', key: 'quantity', width: 80,
	},
	{
		title: 'Iznos', dataIndex: 'grossPrice', key: 'grossPrice', width: 120, render: text => numeral(text).format('0,0.00'),
	},
];

function SalesTable(props: Props) {
	const {
		saleDay,
		visible,
		sales,
	} = props;

	const { height } = useWindowSize();

	useEffect(() => {
		if (visible) {
			saleDay.fetchSales();
		}
	}, [visible]);

	function expandedRowRender(record) {
		return (
			<Table
				columns={expandedColumns}
				dataSource={record.saleItems}
				size="middle"
				pagination={false}
				rowKey="id"
				className={styles.nestedTable}
				footer={data => (
					<Card>
						<Descriptions bordered column={1} size="small">
							{record.paidCash && <Descriptions.Item label="Gotovina">{numeral(record.paidCash).format('0,0.00')}</Descriptions.Item>}
							{record.paidCheck && <Descriptions.Item label="Čekovi">{numeral(record.paidCheck).format('0,0.00')}</Descriptions.Item>}
							{record.paidCard && <Descriptions.Item label="Kartica">{numeral(record.paidCard).format('0,0.00')}</Descriptions.Item>}
							<Descriptions.Item label="PDV">{numeral(record.tax).format('0,0.00')}</Descriptions.Item>
							<Descriptions.Item label="Ukupno">{numeral(record.paidCash + record.paidCheck + record.paidCard).format('0,0.00')}</Descriptions.Item>
							<Descriptions.Item label="Dostava">{record.delivery ? record.delivery.name : 'Bez dostave'}</Descriptions.Item>
						</Descriptions>

					</Card>
				)

				}
			/>
		);
	}

	return (
		<Table
			columns={columns}
			dataSource={sales}
			rowKey="id"
			pagination={false}
			bordered
			size="middle"
			expandedRowRender={expandedRowRender}
			scroll={{ y: height - 148 }}
		/>
	);
}

export default inject(
	(_, { saleDay: { sales } }: Props) => ({
		sales: sales.toJS(),
		length: sales.length,
	})
)(
	observer(SalesTable)
);
