import {
	types, flow, applySnapshot, destroy, getParent,
} from 'mobx-state-tree';

import upperFirst from 'lodash/upperFirst';
import keyBy from 'lodash/keyBy';
import pluralize from 'pluralize';

import { v2Client, defaultClient } from '../store/client';

const clients = {
	v1: defaultClient,
	v2: v2Client,
};

export const Pagination = types
	.model('Pagination', {
		count: types.optional(types.number, 0),
		offset: types.optional(types.number, 0),
		limit: types.optional(types.number, 10),
		supported: types.optional(types.boolean, true),
	})
	.views(self => {
		const views = {
			get page() {
				return Math.floor(self.offset / self.limit) + 1;
			},
			get pages() {
				return Math.ceil(self.count / self.limit);
			},
		};

		return views;
	});

export const CrudStore = (
	name: string,
	model: any,
	additionalProperties: {} = {},
	clientVersion: string = 'v1'
) => {
	const singularNameLower = name;
	const singularNameUpper: string = upperFirst(singularNameLower);
	const pluralNameLower: string = pluralize(singularNameLower);
	const pluralNameUpper: string = upperFirst(pluralNameLower);

	const storeName = `${singularNameUpper}Store`;
	const client = clients[clientVersion];

	const CrudModel = types
		.model(singularNameUpper, {
			id: types.identifierNumber,
			isUpdating: types.optional(types.boolean, false),
			isDestroying: types.optional(types.boolean, false),
			error: types.maybeNull(types.string),
		})
		.actions(self => {
			const update = flow(function* update(updateData) {
				self.isUpdating = true;
				try {
					const { data }: any = yield client({
						method: 'PUT',
						data: updateData,
						url: `${pluralNameLower}/${self.id}`,
					});

					applySnapshot(self, data);
				} catch (error) {
					self.error = error.message;
				} finally {
					self.isUpdating = false;
				}
			});
			const destroySelf = flow(function* destroySelf() {
				self.isDestroying = true;
				try {
					yield client({
						method: 'DELETE',
						url: `${pluralNameLower}/${self.id}`,
					});
					(getParent(self, 2) as any).destroy(self);
				} catch (error) {
					self.error = error.message;
					self.isDestroying = false;
				}
			});

			return {
				update,
				destroy: destroySelf,
			};
		});

	const composedModel = types
		.compose(
			CrudModel,
			model,
		);

	return types
		.model(storeName, {
			cache: types.optional(types.boolean, true),
			pagination: types.optional(Pagination, {}),
			error: types.maybeNull(types.string),
			isFetching: types.optional(types.boolean, false),
			isCreating: types.optional(types.boolean, false),
			isFetchingSingle: types.optional(types.boolean, false),
			[pluralNameLower]: types.optional(types.array(composedModel), []),
			[singularNameLower]: types.maybeNull(composedModel),
			...additionalProperties,

		})
		.actions(self => {
			const afterCreate = () => {
				self.pagination.supported = clientVersion === 'v2';
			};
			const fetchAll = flow(function* fetchAll(limit: number = 10, offset: number = 0) {
				self.isFetching = true;
				try {
					const { data, headers } : any = yield client({
						method: 'GET',
						url: `/${pluralNameLower}/`,
						headers: {
							'pagination-limit': limit,
							'pagination-offset': offset,
						},
					});

					self.pagination.limit = limit;
					self.pagination.offset = offset;
					self.pagination.count = headers['pagination-count'];

					// self[pluralNameLower] = data;
					applySnapshot(self[pluralNameLower], data);
				} catch (error) {
					self.error = error.message;
				} finally {
					self.isFetching = false;
				}
			});

			const fetchSingle = flow(function* fetchSingle(id: number) {
				self.isFetchingSingle = true;
				try {
					const { data } : any = yield client({
						method: 'GET',
						url: `${pluralNameLower}/${id}`,
					});

					applySnapshot(self[singularNameLower], data);
				} catch (error) {
					self.error = error.message;
				} finally {
					self.isFetchingSingle = false;
				}
			});

			const create = flow(function* create(postData: any) {
				self.isCreating = true;
				try {
					const { data }: any = yield client({
						method: 'POST',
						url: pluralNameLower,
						data: postData,
					});

					if (clientVersion === 'v1') {
						self[pluralNameLower].push(data);
					} else {
						// TODO push to the list if it's last page, and there is room
						// TODO update pagination data
					}
				} catch (error) {
					self.error = error.message;
				} finally {
					self.isCreating = false;
				}
			});

			const destroyItem = function destroyItem(item: any) {
				destroy(item);
			};

			return {
				afterCreate,
				create,
				fetchAll,
				fetchSingle,
				destroy: destroyItem,
			};
		})
		.views(self => ({
			get mappedById() {
				return keyBy(self[pluralNameLower], 'id')
			}
		})
	)
};
