import crudActions from './crudActions';


export const { fetchInventoryItems, createInventoryItem, updateInventoryItem, deleteInventoryItem } = crudActions('inventoryItem');

export function importInventoryItems(data) {
	return {
		type: 'INVENTORY_ITEMS_IMPORT',
		payload: {
			request: {
				url: '/import/inventory',
				method: 'post',
				data,
			},
		},
	};
}
