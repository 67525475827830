// @flow
import React from 'react';

import { types, flow, getRoot } from 'mobx-state-tree';
import Fuse from 'fuse.js';
import keyBy from 'lodash/keyBy';
import { notification, Button} from 'antd';
import numeral from 'numeral';


import { defaultClient } from '../store/client';

export const Ean = types
	.model('Barcode', {
		id: types.identifierNumber,
		barcode: types.maybeNull(types.string),
	});

export const Product = types
	.model('Product', {
		id: types.identifierNumber,
		sku: types.number,
		name: types.string,
		price: types.maybeNull(types.number),
		barcodes: types.optional(types.array(Ean), []),
		imported: types.maybeNull(types.model({
			name: types.maybeNull(types.string),
			shortName: types.string,
			barcodes: types.frozen(),
			price: types.maybeNull(types.number),
			oldPrice: types.maybeNull(types.number),
			discount: types.maybeNull(types.string),
			tax: types.number,
		})),
	});

export const ProductStore = types
	.model('ProductStore', {
		products: types.array(Product),
		fetched: types.boolean,
		loading: types.optional(types.boolean, false),
		cache: types.optional(types.boolean, true),
	})
	.views(self => ({
		get productsByEan() {
			return self.products
				.reduce((prev, curr) => {
					// curr.barcodes.forEach((barcode) => {
					// 	prev[barcode.barcode] = prev[barcode.barcode] || [];
					// 	prev[barcode.barcode].push(curr);
					// });
					if (curr.imported && curr.imported.barcodes) {
						curr.imported.barcodes.forEach(barcode => {
							prev[barcode.barcode] = prev[barcode.barcode] || [];
							prev[barcode.barcode].push(curr);
						});
					}
					return prev;
				}, {});
		},
		get productsBySku() {
			return keyBy(self.products, 'sku');
		},

		get fuseSearch() {
			return new Fuse(self.products.filter(product => product.sku <= 10000), {
				shouldSort: true,
				threshold: 0.2,
				ignoreLocation: true,
				maxPatternLength: 32,
				minMatchCharLength: 1,
				findAllMatches: true,
				keys: [
					'sku',
					'name',
					'imported.shortName',
					// 'barcodes.barcodes.barcode',
					'imported.barcodes.barcode',
				],
			});
		},
	}))
	.actions(self => {
		const actions = {
			fetchProduct: flow(function* fetchProduct(id) {

			}),
			fetchProducts: flow(function* fetchProducts() {
				if (!self.fetched) {
					self.loading = true;
				}

				const updates = [];
				const {productsBySku} = self;

				try {
					const response = yield defaultClient.get('/products/');
					self.fetched = true;

					const { data } = response as any;

					if (data) {
						const mappedProducts = keyBy(data, 'sku');
						const importResponse = yield defaultClient.get('/import/google-doc/');

						const { data: importData } = importResponse as any;

						if (importData && importData.length > 0) {

							for (let i = 0; i < importData.length; i += 1) {
								const imported = importData[i];
								if (mappedProducts[imported.sku]) {
									// console.log(imported.sku, imported, mappedProducts[imported.sku]);
									mappedProducts[imported.sku].imported = imported;
									if (imported.sku <= 9999 && self.products.length > 0 && !productsBySku[imported.sku]) {
										updates.push({
											type: 'new',
											name: imported.name,
											sku: imported.sku,
										});
									}
									
									if (imported.sku <= 9999 && productsBySku[imported.sku] && productsBySku[imported.sku].imported && productsBySku[imported.sku].imported.price !== imported.price) {
										console.log(productsBySku[imported.sku].imported.price, imported.price);	
										updates.push({
											type: 'price',
											name: imported.name,
											sku: imported.sku,
											oldPrice: productsBySku[imported.sku].price,
											newPrice: imported.price,
										});
									}
								} else {
									// TODO: remove this once socket update is in place
									const { data: createdProduct }: any = yield defaultClient.post('/products/', {
										sku: imported.sku,
										name: imported.name,
										ean: imported.barcodes[0],
										shortName: imported.shortName,
										tax: imported.tax,
									});
									if (createdProduct) {
										createdProduct.imported = imported;
										mappedProducts[imported.sku] = createdProduct;
									}

									if (imported.sku <= 9999){
										updates.push({
											type: 'new',
											name: imported.name,
											sku: imported.sku,
										});
									}
								}
							}
						}
						self.products.replace(Object.values(mappedProducts));
					}

					if (updates.length > 0) {
						notification.info({
							key: 'productsImport',
							message: 'Došlo je do sledećih izmena proizvoda:',
							description: (
								<ul>
									{updates.map(update => (
										<li key={update.sku}>
											<strong>{update.sku}</strong> - {update.name} (
											{update.type === 'new'
												? 'novi proizvod'
												: `promena cene sa ${numeral(update.oldPrice).format('0,0.00')} na ${numeral(update.newPrice).format('0,0.00')}`
											}
											)
										</li>
									))}
								</ul>
							),
							btn: <Button onClick={() => {
								updates.forEach(update => {
									(getRoot(self) as any).printPricesStore.addProduct(self.productsBySku[update.sku], 1);
								});
								notification.close('productsImport');

								notification.info({
									key: 'productsImport',
									message: 'Štampa cena',
									description: `Cene su uspešno dodate za štampanje`,
									duration: 10,
								});
							}}
							>Dodaj za štampanje cena
							</Button>,
							duration: 0,
						});
					}
				} catch (error) {
					console.log('network error', error);
				} finally {
					self.loading = false;
				}
			}),
			afterAuth: (authenticated: boolean) => {
				if (authenticated) {
					setImmediate(() => {
						actions.fetchProducts();
						// TODO: remove this once socket update is in place
						setInterval(() => {
							actions.fetchProducts();
						}, 60 * 60 * 1000);
					});
				}
			},
		};

		return actions;
	});
	

export type ProductStoreType = {
	productsByEan: Map<string, Object>,
	productsBySku: Map<string, Object>
};
