import React, { Component, Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import floor from 'lodash/floor';
import { BookOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Input,
	Button,
	Table,
	Select,
	DatePicker,
	Spin,
	Popconfirm,
	Row,
	Col,
	notification,
	Progress,
	Tag,
} from 'antd';
import print from 'print-html-element';
import nl2br from 'react-newline-to-break';

import moment from 'moment';

import {
	fetchSales, createSale, deleteSale, updateSale, processSale, createSaleItem,
} from '../../../../actions/sales';
import { updateSaleItem, deleteSaleItem } from '../../../../actions/saleItems';
import { fetchStores } from '../../../../actions/stores';
import { fetchDistributors } from '../../../../actions/distributors';
import { fetchInvoices } from '../../../../actions/invoices';
import { fetchSoldItems, fetchTotals } from '../../../../actions/ecr';
import CrudPage from '../../../../components/CrudPage/mobx';
import CreateSaleItemsModal from './CreateSaleItems';
import ImportStockCheckupsModal from './ImportStockCheckupsModal';
import ProcessSale from './ProcessSale';

import numberFormat from '../../../../lib/numberFormat';

class StockCheckups extends Component {
	static propTypes = {
		fetchInvoices: PropTypes.func.isRequired,
		updateSaleItem: PropTypes.func.isRequired,
		createSaleItem: PropTypes.func.isRequired,
		deleteSaleItem: PropTypes.func.isRequired,
		fetchStores: PropTypes.func.isRequired,
		processSale: PropTypes.func.isRequired,
		isLoading: PropTypes.bool.isRequired,
		isLoadingDistributors: PropTypes.bool.isRequired,
		isLoadingStores: PropTypes.bool.isRequired,
		isLoadingInvoices: PropTypes.bool.isRequired,
		stores: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		invoices: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
		distributors: PropTypes.arrayOf(
			PropTypes.shape({})
		).isRequired,
	};

	state = {
		createVisible: false,
		importVisible: false,
		processVisible: false,
		saleItem: null,
	};

	calcRefs = {};

	componentDidMount() {
		this.props.fetchInvoices();
		this.props.fetchStores();
		this.props.fetchDistributors();
	}

	editRender(property, editComponent, render) {
		return (text, record) => (render ? render(text, record) : text);
	}

	expandedRowRender(data) {
		return (
			<div
				className="bo-sale"
				ref={el => { this.calcRefs[data.id] = el; }}
			>
				<Table

					footer={() => [
						// TODO: totals
						<Button
							onClick={() => this.setState({ importVisible: true, stockCheckup: data })}
							icon={<PlusOutlined />}
							style={{ marginRight: 8 }}
							key="addButton"
						>
								Uvezi
						</Button>,
					]
					}
					pagination={false}
					size="middle"
					bordered
					rowKey={record => record.id}
					dataSource={data.stockCheckupItems}
					columns={[
						{
							title: '#',
							key: 'index',
							width: '80px',
							render(text, record, index) {
								return index + 1;
							},
						},
						{
							title: 'Šifra',
							key: 'product.sku',
							dataIndex: ['product', 'sku'],
							width: '70px',

						},
						{
							title: 'Naziv',
							key: 'product.name',
							dataIndex: ['product', 'name'],
						},
						{
							title: 'Cena bez PDV-a',
							key: 'netPrice',
							dataIndex: 'netPrice',
							width: '140px',
							render: (text, record) => numberFormat(record.netPrice),
						},
						{
							title: 'PDV',
							key: 'tax',
							dataIndex: 'tax',
							width: '100px',
							render: (text, record) => numberFormat(record.tax),
						},
						{
							title: 'Cena',
							key: 'grossPrice',
							dataIndex: 'grossPrice',
							width: '100px',
							render: (text, record) => numberFormat(record.grossPrice),
						},
						{
							title: 'Količina',
							key: 'quantity',
							dataIndex: 'quantity',
							width: '70px',
						},
						{
							title: 'Vrednost',
							key: 'value',
							width: '140px',
							render: (text, record) => numberFormat(record.grossPrice * record.quantity),
						},
						{
							title: 'Akcije',
							key: 'actions',
							className: 'bo-column-actions',
							width: '140px',
							render: (text, record) => (
								<Button.Group>
									<Button
										onClick={() => {
											this.setState({
												stockCheckupItem: record,
												createItemVisible: true,
												stockCheckup: data,
											});
										}}
									>
									Izmeni
									</Button>
									<Popconfirm
										placement="topRight"
										title="Da li ste sigurni da želite da obrišete ovu stavku?"
										onConfirm={() => {
											// this.props.deleteSaleItem(record);
										}}
										okText="Da"
										cancelText="Ne"
									>
										<Button icon={<DeleteOutlined />} />
									</Popconfirm>
								</Button.Group>
							),
						},

					]}
				/>
			</div>
		);
	}

	render() {
		return (
			<CrudPage
				actionsWidth={200}
				tableProps={{
					expandedRowRender: data => this.expandedRowRender(data),
					className: 'bo-nested-table',
				}}
				createAction={createSale}
				deleteAction={deleteSale}
				updateAction={updateSale}
				useMap
				title="Popisi"
				createTitle="Dodaj Popis"
				editTitle="Izmeni Popis"
				addButtonText="Dodaj Popis"
				deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovaj popis?"
				store="stockCheckupStore"
				columns={[
					{
						title: '#',
						key: 'index',
						width: '80px',
						render(text, record, index) {
							return index + 1;
						},
					},
					{
						title: 'Broj',
						dataIndex: 'number',
						key: 'number',
					},
					{
						title: 'Datum popisa',
						dataIndex: 'timestamp',
						key: 'timestamp',
						render: text => moment(text).format('LL'),
					},
					{
						title: 'Proknjižen',
						dataIndex: 'processed',
						width: 100,
						render: value => (value
							? <Tag color="green">Proknjižen</Tag>
							: <Tag color="volcano">Nije proknjižen</Tag>),
					},
				]}
				convert={{
					timestamp: moment,
					storeId: storeId => `${storeId}`,
				}}
				extraButtons={(text, record) => [
					!record.processed && (
						<Button
							key="process"
							onClick={() => {
								record.process();
							}}
							icon={<BookOutlined />}
						>
						Proknjiži
						</Button>
					),
				]}
				properties={[
					{
						label: 'Broj popisa',
						property: 'number',
						rules: [],
						component: <Input />,
					},
					{
						label: 'Datum popisa',
						property: 'timestamp',
						rules: [{
							required: true, message: 'Datum popisa je obavezan',
						}],
						component: <DatePicker
							format="YYYY-MM-DD"
							placeholder="Izaberi Datum"
						/>,
					},
				]}
			>
				<CreateSaleItemsModal
					visible={this.state.createVisible}
					sale={this.state.sale}
					entity={this.state.saleItem}
					handleSave={() => {
						this.setState({ createVisible: false, saleItem: null });
					}}
					handleCancel={() => {
						this.setState({ createVisible: false, saleItem: null });
					}}
					convert={{}}
				/>
				<ImportStockCheckupsModal
					visible={this.state.importVisible}
					stockCheckup={this.state.stockCheckup}
					handleSave={() => {
						this.setState({ importVisible: false, stockCheckup: null });
					}}
					handleCancel={() => {
						this.setState({ importVisible: false, stockCheckup: null });
					}}
					convert={{}}
				/>
				<ProcessSale
					visible={this.state.processVisible}
					entity={this.state.sale}
				/>
			</CrudPage>
		);
	}
}

function mapStateToProps(state) {
	return {
		isLoading: state.stores.isFetching || state.invoices.isFetching || state.distributors.isFetching,
		isLoadingStores: state.stores.isFetching,
		isLoadingInvoices: state.invoices.isFetching,
		isLoadingDistributors: state.distributors.isFetching,
		invoices: state.invoices.invoices,
		stores: state.stores.stores,
		distributors: state.distributors.distributors,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchStores,
		fetchDistributors,
		fetchInvoices,
		updateSaleItem,
		createSaleItem,
		deleteSaleItem,
		processSale,
		fetchSoldItems,
		fetchTotals,
		createSale,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StockCheckups);
