import React, { useState, forwardRef } from 'react';
import {
	Typography,
} from 'antd';

import styles from './ContactsView.module.less';

type Contact = {
	name?: string,
	role?: string,
	phone?: string,
	email?: string,
	id: string,
};

type Props = {
	value?: Array<Contact>
	onChange?: Function,
};


function ContactsView(props: Props, ref) {
	const {
		value = [],
	} = props;

	if (!value || value.length === 0) {
		return <Typography.Text disabled>Nema kontakata</Typography.Text>;
	}
	return (
		<div ref={ref}>
			{value && value.map((contact, index) => JSON.stringify(contact))}
		</div>
	);
}

export default forwardRef(ContactsView);
