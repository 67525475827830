import React, { Component, useState } from 'react';
import flattenDeep from 'lodash/flattenDeep';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { Layout, Menu, Avatar } from 'antd';
import numeral from 'numeral';
import '../../lib/numeral/locales/rs';

import ProLayout from '@ant-design/pro-layout';

import ERPIcon from '../../components/ERPIcon';
import NewERPIcon from '../../components/ERPIcon/new';
import Header from '../../components/Header';

import ApplicationDistributors from './Distributors';
import ApplicationInvoices from './Invoices';
import ApplicationUsers from './Users';
import ApplicationStores from './Stores';
import ApplicationLedger from './Ledger';

// import ApplicationUsers from './Users';
import ApplicationProductBrands from './Products/Brands';
import ApplicationProductSales from './Products/Sales';
import ApplicationProductInvoices from './Products/Invoices';
import ApplicationProductCalculations from './Products/Calculations';
import ApplicationProductCalculationsV2 from './Products/CalculationsV2';
import ApplicationProductProducts from './Products/Products';
import ApplicationProductPricechanges from './Products/Pricechanges';
import ApplicationProductInventory from './Products/Inventory';
import StockCheckups from './Products/StockCheckups';
import PrintPrices from './Print/Prices';
import PrintDeclarations from './Print/Declarations';
import ApplicationPOS from './POS';

numeral.locale('rs');

const { Footer, Sider } = Layout;
const { SubMenu } = Menu;

const routes = [
	{
		path: '/app/pos',
		exact: true,
		name: 'Prodaja Artikala',
		icon: <NewERPIcon type="erpa-001-cash-register" />,
		component: ApplicationPOS,
	},
	{
		path: '/app/distributors',
		exact: true,
		name: 'Distributeri',
		icon: <NewERPIcon type="erpa-004-distribution" />,
		component: ApplicationDistributors,
	},
	{
		path: '/app/sales',
		exact: true,
		name: 'Prodaje',
		icon: <NewERPIcon type="erpa-005-receipt" />,
		component: ApplicationProductSales,
	},
	// {
	// 	path: '/app/invoices',
	// 	exact: true,
	// 	name: 'Fakture',
	// 	icon: <NewERPIcon type="erp005-file" />,
	// 	component: ApplicationInvoices,
	// },
	{
		path: '/app/print',
		exact: true,
		name: 'Štampa',
		icon: <NewERPIcon type="erpa-007-printer" />,
		children: [
			{
				path: '/app/print/prices',
				exact: true,
				name: 'Cene',
				icon: <NewERPIcon type="erpa-007-printer" />,
				component: PrintPrices,
			},
			{
				path: '/app/print/declarations',
				exact: true,
				name: 'Deklaracije',
				icon: <NewERPIcon type="erpa-007-printer" />,
				component: PrintDeclarations,
			},
		],
	},
	// {
	// 	path: '/app/settings',
	// 	exact: true,
	// 	name: 'Podešavanja',
	// 	icon: <NewERPIcon type="erp024-settings" />,
	// 	children: [
	// 		{
	// 			path: '/app/settings/users',
	// 			exact: true,
	// 			name: 'Korisnici',
	// 			icon: <NewERPIcon type="erp046-worker" />,
	// 			component: ApplicationUsers,
	// 		},
	// 	],
	// },

	// {
	// 	path: '/app/products/calculations',
	// 	exact: true,
	// 	name: 'Kalkulacije',
	// 	icon: <ERPIcon type="iconCalculation" />,
	// 	component: ApplicationProductCalculations,
	// },
	// {
	// 	path: '/app/users',
	// 	exact: true,
	// 	name: 'Korisnici',
	// 	icon: <ERPIcon type="iconFeedback" />,
	// 	component: ApplicationUsers,
	// },
];

const reducedRoutes = routes.reduce((prev, curr) => {
	prev.push(curr);
	if (curr.children) {
		curr.children.forEach((item) => {
			prev.push(item);
		});
	}
	return prev;
}, []);

export default function Application(props) {
	const [currentComponent, setCurrentComponent] = useState(null);
	const [collapsed, setCollapsed] = useState(true);
	return (
		<ProLayout
			title="Pet Shop Moja Mačkica"
			logo="/images/logo.png"
			siderWidth={320}
			menuItemRender={(item, defaultDom) => (
				<Link to={item.path}>{defaultDom}</Link>
			)}
			menuDataRender={() => routes}
			footerRender={() => {}}
			collapsed={collapsed}
			onCollapse={(c) => setCollapsed(c)}
			breakpoint={false}
			fixedHeader
			fixSiderbar
			rightContentRender={(p) => (
				<Header
					{...p}
					extraContent={
						currentComponent && currentComponent.renderHeader
							? currentComponent.renderHeader()
							: null
					}
				/>
			)}
		>
			<Switch>
				{reducedRoutes.map((route) => (
					<Route
						path={route.path}
						key={route.path}
						exact={route.exact}
						render={(routeProps) => (
							<route.component
								{...routeProps}
								routes={route.routes}
								ref={(component) =>
									setCurrentComponent(component)
								}
							/>
						)}
					/>
				))}
			</Switch>
		</ProLayout>
	);
}
// class Application extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			collapsed: false,
// 		};
// 		this.onCollapse = this.onCollapse.bind(this);
// 	}

// 	onCollapse(collapsed) {
// 		this.setState({ collapsed });
// 	}

// 	render() {
// 		return (<ProLayout />);
// 		return (
// 			<Layout style={{ minHeight: '100vh' }}>
// 				<Sider
// 					collapsible
// 					collapsed={this.state.collapsed}
// 					onCollapse={this.onCollapse}
// 				>
// 					<div className="logo" />
// 					<Menu theme="dark" defaultSelectedKeys={['pos']} mode="inline">

// 						<Menu.Item key="pos">
// 							<Link
// 								to="/app/pos"
// 							>
// 								<Icon type="calculator" />
// 								<span>POS</span>
// 							</Link>
// 						</Menu.Item>
// 						<Menu.Item key="1">
// 							<Icon type="desktop" />
// 							<span>Kontrolna Tabla</span>
// 						</Menu.Item>
// 						<Menu.Item key="2">
// 							<Link
// 								to="/app/distributors"
// 							>
// 								<Icon type="car" />
// 								<span>Distributeri</span>
// 							</Link>
// 						</Menu.Item>
// 						<Menu.Item key="3">
// 							<Link
// 								to="/app/stores"
// 							>
// 								<Icon type="shop" />
// 								<span>Prodavnice</span>
// 							</Link>
// 						</Menu.Item>
// 						<Menu.Item key="ledger">
// 							<Link
// 								to="/app/ledger"
// 							>
// 								<Icon type="file-text" />
// 								<span>KEP Knjiga</span>
// 							</Link>
// 						</Menu.Item>
// 						<SubMenu
// 							key="sub1"
// 							title={<span><Icon type="tag-o" /><span>Roba</span></span>}
// 						>
// 							<Menu.Item key="4">
// 								<Link
// 									to="/app/products/brands"
// 								>
// 									Brendovi
// 								</Link>
// 							</Menu.Item>
// 							<Menu.Item key="5">
// 								<Link
// 									to="/app/products/invoices"
// 								>
// 									Fakture
// 								</Link>
// 							</Menu.Item>
// 							<Menu.Item key="6">
// 								<Link
// 									to="/app/products/calculations"
// 								>
// 									Kalkulacije
// 								</Link>
// 							</Menu.Item>
// 							<Menu.Item key="calculationsv2">
// 								<Link
// 									to="/app/products/calculationsV2"
// 								>
// 									Kalkulacije V2
// 								</Link>
// 							</Menu.Item>
// 							<Menu.Item key="pricechanges">
// 								<Link
// 									to="/app/products/pricechanges"
// 								>
// 									Nivelacije
// 								</Link>
// 							</Menu.Item>
// 							<Menu.Item key="7">
// 								<Link
// 									to="/app/products/products"
// 								>
// 									Proizvodi
// 								</Link>
// 							</Menu.Item>
// 							<Menu.Item key="8">
// 								<Link
// 									to="/app/products/inventory"
// 								>
// 									Inventar
// 								</Link>
// 							</Menu.Item>
// 							<Menu.Item key="9">
// 								<Link
// 									to="/app/products/sales"
// 								>
// 									Prodaje
// 								</Link>
// 							</Menu.Item>
// 							<Menu.Item key="10">
// 								<Link
// 									to="/app/products/stockCheckups"
// 								>
// 									Popisi
// 								</Link>
// 							</Menu.Item>
// 						</SubMenu>
// 						<Menu.Item key="users">
// 							<Link
// 								to="/app/users"
// 							>
// 								<Icon type="user" />
// 								<span>Korisnici</span>
// 							</Link>
// 						</Menu.Item>
// 					</Menu>
// 				</Sider>
// 				<Layout>
// 					<Switch>
// 						<Route path="/app/pos" component={ApplicationPOS} />
// 						<Route path="/app/distributors" component={ApplicationDistributors} />
// 						<Route path="/app/stores" component={ApplicationStores} />
// 						<Route path="/app/users" component={ApplicationUsers} />
// 						<Route path="/app/ledger" component={ApplicationLedger} />
// 						<Route path="/app/products/brands" component={ApplicationProductBrands} />
// 						<Route path="/app/products/invoices" component={ApplicationProductInvoices} />
// 						<Route path="/app/products/calculations" component={ApplicationProductCalculations} />
// 						<Route path="/app/products/calculationsV2" component={ApplicationProductCalculationsV2} />
// 						<Route path="/app/products/products" component={ApplicationProductProducts} />
// 						<Route path="/app/products/pricechanges" component={ApplicationProductPricechanges} />
// 						<Route path="/app/products/inventory" component={ApplicationProductInventory} />
// 						<Route path="/app/products/sales" component={ApplicationProductSales} />
// 						<Route path="/app/products/stockCheckups" component={StockCheckups} />
// 					</Switch>
// 					{/* <Footer style={{ textAlign: 'center' }}>
// 						Copyright &copy; 2019 Pet Shop Moja Mačkica | <a href="https://icons8.com/android-L">Free icons by Icons8</a>
// 					</Footer> */}
// 				</Layout>
// 			</Layout>
// 		);
// 	}
// }
// export default withRouter(Application);

// <Header style={{ background: '#fff', padding: 0 }} />
// 	<Content style={{ margin: '0 16px' }}>
// 		<Breadcrumb style={{ margin: '12px 0' }}>
// 			<Breadcrumb.Item>User</Breadcrumb.Item>
// 			<Breadcrumb.Item>Bill</Breadcrumb.Item>
// 		</Breadcrumb>
// 		<div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
// 			Bill is a cat.
// 						</div>
// 	</Content>
