import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Layout, Button, Table, Popconfirm, PageHeader,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import './styles.css';

import Create from './Create';

const { Header, Content } = Layout;

class CrudPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			createVisible: false,
			editEntity: null,
			filterVisible: false,
			filteredValue: this.props.columns.reduce((prev, curr) => {
				if (curr.defaultFilteredValue) {
					prev[curr.key] = curr.defaultFilteredValue;
				}
				return prev;
			}, {}),
		};
	}

	componentDidMount() {
		if (!this.props.isFetched) {
			this.props.fetchAction();
		}
	}

	filterData = data => data.filter((row) => {
		let condition = true;

		this.columns.forEach((column) => {
			if ((this.state.filteredValue[column.dataIndex] || (this.state.filteredValue[column.dataIndex] || []).length > 0) && column.filterFunction) {
				condition = condition && this.state.filteredValue[column.dataIndex].reduce((prev, curr) => prev || column.filterFunction(curr, row), false);
			}
		});
		return condition;
	})

	render() {
		this.columns = [
			...this.props.columns.map(column => ({
				...column,
				...(column.defaultFilteredValue ? { filteredValue: this.state.filteredValue[column.key] } : {}),
				filterFunction: column.onFilter,
				onFilter: () => true,
			})),
			{
				title: 'Akcije',
				key: 'actions',
				className: 'bo-column-actions',
				render: (text, record) => (
					<Button.Group>
						{this.props.extraButtons(text, record)}

						<Button
							onClick={() => {
								if (this.props.editButtonAction) {
									return this.props.editButtonAction(record);
								}

								return this.setState({
									editEntity: record,
									createVisible: true,
								});
							}}
							icon={<EditOutlined />}
						/>
						<Popconfirm
							placement="topRight"
							title={this.props.deleteButtonPrompt}
							onConfirm={() => {
								this.props.deleteAction(record.id);
							}}
							okText="Da"
							cancelText="Ne"
						>
							<Button icon={<DeleteOutlined />} />
						</Popconfirm>
					</Button.Group>
				),
			},
		];

		const data = this.props.processData(this.filterData(this.props.useMap
			? _.values(this.props[`${this.props.stateProperty}ById`])
			: this.props[this.props.stateProperty]));
		return (
            <div>
				{
					!this.props.hideDefaultButtons ? (
						<Create
							properties={this.props.properties}
							stateProperty={this.props.stateProperty}
							createTitle={this.props.createTitle}
							editTitle={this.props.editTitle}
							entity={this.state.editEntity}
							convert={this.props.convert}
							visible={this.state.createVisible}
							handleCancel={() => this.setState({ createVisible: false, editEntity: null })}
							handleSave={(data) => {
								if (data.id) {
									return this.props.updateAction(data)
										.then(() => this.setState({ createVisible: false, editEntity: null }));
								}

								return this.props.createAction(data)
									.then(() => this.setState({ createVisible: false, editEntity: null }));
							}
							}
						/>
					) : null
				}
				<PageHeader
					title={this.props.title}
					subTitle={this.props.subTitle}
					extra={(
						<Fragment>
							{
								!this.props.hideDefaultButtons
									? (
										<Button
											type="primary"
											icon={<PlusOutlined />}
											onClick={() => this.setState({ createVisible: true })}
										>
											{this.props.addButtonText}
										</Button>
									) : null
							}
							{this.props.headerContents}
						</Fragment>
					)}
				/>
				<Content>
					<Table
						onChange={(pagination, filters, sorter) => {
							console.log(filters);
							this.setState(() => ({
								filteredValue: filters,
							}));
						}}
						size="middle"
						bordered
						loading={this.props.isLoading}
						rowKey="id"
						columns={this.columns}
						dataSource={data}
						pagination={{
							showSizeChanger: true,
						}}
						footer={() => this.props.tableFooter(data)}
						{...this.props.tableProps}
					/>
					{this.props.children}
				</Content>
			</div>
        );
	}
}

CrudPage.defaultProps = {
	addButtonText: 'Dodaj Entitet',
	deleteButtonPrompt: 'Da li ste sigurni da želite da obrišete ovaj entitet?',
	createTitle: 'Dodaj Entitet',
	editTitle: 'Izmeni Entitet',
	convert: {},
	extraButtons: () => {},
	tableFooter: () => {},
	tableProps: {},
	useMap: false,
	children: [],
	hideDefaultButtons: false,
	headerContents: null,
	editButtonAction: null,
	processData: data => data,
};

CrudPage.propTypes = {
	processData: PropTypes.func,
	children: PropTypes.node,
	useMap: PropTypes.bool,
	tableProps: PropTypes.shape({}),

	hideDefaultButtons: PropTypes.bool,
	headerContents: PropTypes.node,

	isLoading: PropTypes.bool.isRequired,

	addButtonText: PropTypes.string,
	deleteButtonPrompt: PropTypes.string,

	editButtonAction: PropTypes.func,

	// Actions
	fetchAction: PropTypes.func.isRequired,
	createAction: PropTypes.func.isRequired,
	deleteAction: PropTypes.func.isRequired,
	updateAction: PropTypes.func.isRequired,

	// State
	stateProperty: PropTypes.string.isRequired,
	convert: PropTypes.shape({}),
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			dataIndex: PropTypes.string,
			key: PropTypes.string,
			render: PropTypes.func,
		})
	).isRequired,

	createTitle: PropTypes.string,
	editTitle: PropTypes.string,

	properties: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			property: PropTypes.string,
			rules: PropTypes.array,
			component: PropTypes.node,

		})
	).isRequired,
	extraButtons: PropTypes.func,
	tableFooter: PropTypes.func,

};

function mapStateToProps(state, ownProps) {
	return {
		isFetched: state[ownProps.stateProperty].isFetched,
		isLoading: state[ownProps.stateProperty].isLoading || state[ownProps.stateProperty].isDeleting,
		[ownProps.useMap ? `${ownProps.stateProperty}ById` : ownProps.stateProperty]:
			state[ownProps.stateProperty][ownProps.useMap ? `${ownProps.stateProperty}ById` : ownProps.stateProperty],
	};
}

function mapDispatchToProps(dispatch, ownProps) {
	return bindActionCreators({
		fetchAction: ownProps.fetchAction,
		createAction: ownProps.createAction,
		deleteAction: ownProps.deleteAction,
		updateAction: ownProps.updateAction,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CrudPage);
