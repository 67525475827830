// @flow
import {
	types, flow, destroy, getParent, clone,
} from 'mobx-state-tree';

import localforage from 'localforage';
import { defaultClient } from '../store/client';

import { Product } from './Product';

export const PriceChangeItem = types
	.model('PriceChangeItem', {
		id: types.identifierNumber,
		product: types.reference(Product),
		quantity: types.number,
		price: types.number,
		oldPrice: types.number,
	});

export const PriceChange = types
	.model('PriceChange', {
		pricechangeItems: types.map(PriceChangeItem),
		processed: types.boolean,
		date: types.Date,
		id: types.identifierNumber,
	})
	.actions(self => ({

	}))
	.views(self => ({
	}));

export const PriceChangeStore = types
	.model('PriceChangeStore', {
		priceChanges: types.array(PriceChange),
		fetched: types.boolean,
		loading: types.boolean,
	})
	.actions(self => ({
		fetch: flow(function* fetch() {
			if (!self.fetched) {
				self.loading = true;
			}

			try {
				const response = yield defaultClient.get('/pricechanges');
				self.fetched = true;
				const { data } = response as any;
				if (data) {
					self.priceChanges.replace(data);
				}
			} catch (error) {
				console.log('network error');
			} finally {
				self.loading = false;
			}
		}),
		afterAuth: () => { },
	}));
