import crudActions from './crudActions';

export const { fetchProducts, createProduct, updateProduct, deleteProduct } = crudActions('product');

export function productsImport() {
	return {
		type: 'PRODUCTS_IMPORT',
		payload: {
			request: {
				url: '/import/google-doc',
				method: 'get',
			},
		},
	};
}
