import { types, flow, applySnapshot } from 'mobx-state-tree';
import { CrudStore } from './Crud';

export const cache = false;

export const Delivery = types
	.model('Delivery', {
		id: types.identifierNumber,
		name: types.string,
	});

export const DeliveryStore = CrudStore(
	'delivery',
	Delivery,
	undefined,
	'v2'
)
	.actions(self => ({
		afterAuth() {
			setImmediate(() => {
				self.fetchAll();
			});
		}
	}))