import React, { ReactNode, FunctionComponent } from 'react';
import '@ant-design/compatible/assets/index.css';
import {
	Drawer,
} from 'antd';

import Form from './Components/Form/index';


interface Props {
	width?: number
	visible: boolean,
	entity?: any,
	isCreating?: boolean,
	titles: {
		edit: String,
		create: String,
	}
	convert?: {
		[key: string]: Function,
	}
	formLayout?: 'inline' | 'horizontal' | 'vertical',
	formFields: Array<{
		key: string,
		fields: Array<{
			label?: String,
			key?: string,
			rules?: any,
			component: ReactNode,
			span?: number,
			initialValue?: any,
		}>
	}>,
	onCancel?: Function,
	onSave?: Function,
}

const Create: FunctionComponent<Props> = props => {
	const {
		width,
		visible,
		entity,
		titles,
		formLayout,
		formFields: rows,
		isCreating,
		onCancel,
		onSave,
	} = props;


	function handleCancel() {
		onCancel();
	}

	return (
		<Drawer
			width={width}
			visible={visible}
			title={entity ? titles.edit : titles.create}
			onClose={handleCancel}
			bodyStyle={{ paddingBottom: 80 }}
			destroyOnClose
		>
			<Form
				{...props}
				onCancel={handleCancel}
			/>
		</Drawer>
	);
};

Create.defaultProps = {
	convert: {},
	width: 700,
	formLayout: 'vertical',
	isCreating: false,
	onCancel: () => { },
	onSave: () => { },
};

export default Create;
