import React from 'react';

import * as styles from './Key.module.less';

type Props = {
    children: string,
    text: string,
}

export default function Key(props: Props) {
	const { children, text } = props;
	return (
		<div className={styles.wrapper}>
			<div className={styles.key}><span>{children}</span></div>
			<div className={styles.text}>{text}</div>
		</div>
	);
}
