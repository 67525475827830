import { types, flow, applySnapshot } from 'mobx-state-tree';
import { CrudStore } from './Crud';
import { Distributor } from './Distributor';

export const cache = false;
export const InvoiceReference = types.model('InvoiceReference', {
	model: types.maybeNull(types.string),
	number: types.maybeNull(types.string),
});
export const Invoice = types.model('Invoice', {
	id: types.identifierNumber,
	number: types.string,
	date: types.string,
	dueDate: types.string,
	paid: types.boolean,
	distributor: Distributor,
	reference: types.maybeNull(InvoiceReference),
});

export const InvoiceStore = CrudStore('invoice', Invoice, undefined, 'v1');
