import React, { Component, SyntheticEvent, FocusEvent } from 'react';
import { inject, observer } from 'mobx-react';
import Price from './Price';

type Props = any;

@inject((stores, props: Props) => ({
	newQuantity: Array.from(Array(props.record.quantity < 0 ? 0 : props.record.quantity)),
}))
@observer
export default class Prices extends Component<Props> {
	render() {
		const { record, newQuantity } = this.props;
		console.log(record);
		return (
			<>
				{newQuantity.map(() => (
					<Price
						record={record}
						full
					/>
				))}
			</>
		);
	}
}
