import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import 'ant-design-pro/dist/ant-design-pro.css';
import 'ant-design-pro/lib/PageHeader/index';

import './index.css';

import { notification } from 'antd';

import App from './App';
import * as serviceWorker from './serviceWorker';


// Add this import:

function deepMap(obj, iterator, context) {
	return _.transform(obj, (result, val, key) => {
		result[key] = _.isObject(val)
			? /* && !_.isDate(val) */ deepMap(val, iterator, context)
			: iterator.call(context, val, key, obj);
	});
}

_.mixin({
	deepMap,
});

// Wrap the rendering in a function:
ReactDOM.render(
	<App />,
	document.getElementById('root')
);
serviceWorker.register({
	onUpdate: registration => {
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', event => {
				if (event.target.state === 'activated') {
					notification.info({
						message: 'Nova verzija je dostupna',
						description: 'Kliknite ovde da ažurirate aplikaciju',
						key: 'update',
						duration: 0,
						onClick: () => {
							window.location.reload();
						},
					});
				}
			});
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
		}
	},
});
