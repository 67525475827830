import { createAction } from 'redux-actions';
import axios from 'axios';

import {
	FETCH_STORES_ATTEMPT,
	FETCH_STORES_FAILED,
	FETCH_STORES_SUCCESS,
	CREATE_STORES_ATTEMPT,
	CREATE_STORES_FAILED,
	CREATE_STORES_SUCCESS,
	UPDATE_STORES_ATTEMPT,
	UPDATE_STORES_FAILED,
	UPDATE_STORES_SUCCESS,
	DELETE_STORES_ATTEMPT,
	DELETE_STORES_FAILED,
	DELETE_STORES_SUCCESS,
} from './types';

const fetchStoresAttempt = createAction(FETCH_STORES_ATTEMPT);
const fetchStoresFailed = createAction(FETCH_STORES_FAILED);
const fetchStoresSuccess = createAction(FETCH_STORES_SUCCESS);

const createStoreAttempt = createAction(CREATE_STORES_ATTEMPT);
const createStoreFailed = createAction(CREATE_STORES_FAILED);
const createStoreSuccess = createAction(CREATE_STORES_SUCCESS);

const updateStoreAttempt = createAction(UPDATE_STORES_ATTEMPT);
const updateStoreFailed = createAction(UPDATE_STORES_FAILED);
const updateStoreSuccess = createAction(UPDATE_STORES_SUCCESS);

const deleteStoreAttempt = createAction(DELETE_STORES_ATTEMPT);
const deleteStoreFailed = createAction(DELETE_STORES_FAILED);
const deleteStoreSuccess = createAction(DELETE_STORES_SUCCESS);

export function fetchStores() {
	return async (dispatch) => {
		dispatch(fetchStoresAttempt());
		try {
			const stores = await axios.get('/stores');
			dispatch(fetchStoresSuccess(stores.data));
		} catch (error) {
			dispatch(fetchStoresFailed(error.response.data));
		}
	};
}

export function createStore(data) {
	return async (dispatch) => {
		dispatch(createStoreAttempt());
		try {
			const stores = await axios.post('/stores', data);
			dispatch(createStoreSuccess(stores.data));
		} catch (error) {
			dispatch(createStoreFailed(error.response.data));
		}
	};
}

export function updateStore(data) {
	return async (dispatch) => {
		dispatch(updateStoreAttempt());
		try {
			const stores = await axios.put(`/stores/${data.id}`, data);
			dispatch(updateStoreSuccess(stores.data));
		} catch (error) {
			dispatch(updateStoreFailed(error.response.data));
		}
	};
}


export function deleteStore(id) {
	return async (dispatch) => {
		dispatch(deleteStoreAttempt());
		try {
			await axios.delete(`/stores/${id}`);
			dispatch(deleteStoreSuccess(id));
		} catch (error) {
			dispatch(deleteStoreFailed(error.response.data));
		}
	};
}
