

import React from 'react';
import { Input } from 'antd';

import { fetchStores, createStore, deleteStore, updateStore } from '../../../actions/stores';
import CrudPage from '../../../components/CrudPage/';

function Stores() {
	return (<CrudPage
		fetchAction={fetchStores}
		createAction={createStore}
		deleteAction={deleteStore}
		updateAction={updateStore}
		createTitle="Dodaj Prodavnicu"
		editTitle="Izmeni Prodavnicu"
		addButtonText="Dodaj Prodavnicu"
		deleteButtonPrompt="Da li ste sigurni da želite da obrišete ovu prodavnicu?"
		stateProperty="stores"
		columns={[
			{
				title: 'Naziv',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Adresa',
				dataIndex: 'address',
				key: 'address',
			},
			{
				title: 'Telefon',
				dataIndex: 'phone',
				key: 'phone',
			},
		]}
		properties={[
			{
				label: 'Naziv',
				property: 'name',
				rules: [{
					required: true, message: 'Naziv prodavnice je obavezan!',
				}],
				component: <Input />,
			},
			{
				label: 'Adresa',
				property: 'address',
				rules: [],
				component: <Input />,
			},
			{
				label: 'Telefon',
				property: 'phone',
				rules: [],
				component: <Input />,
			},
		]}
	/>);
}

export default Stores;
